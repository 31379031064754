import {
  Typography,
  Button,
  Divider,
  createTheme,
  alpha,
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  ThemeProvider,
  TextField,
  Grow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BodyLayout from "../../components/shared/BodyLayout";
import { visuallyHidden } from "@mui/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import NumberFormat from "react-number-format";
import { useValidateInput } from "../../hooks/useValidateInput";
import { useDataBankParams } from "../../hooks/useDataBankParams";
import {
  IGetDataDataBankParams,
  IResGetDataBankParams,
} from "../../interfaces/IDataBankParams";
import { ConfirmDialogContext } from "../../contexts/confirmDialog/ConfirmDialogContext";
import { ConfirmPopUpContext } from "../../contexts/confirmPopUp/ConfirmPopUpContext";
import { ToastContext } from "../../contexts/toast/ToastContext";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { TransitionGroup } from "react-transition-group";
import { BKG_TABLE_HEADER, COLOR_LOADING, UNHANDLED_ERROR } from "../../helpers/consants";
import { RotateSpinner } from "react-spinners-kit";

const label = { inputProps: { "aria-label": "Switch demo" } };

const themeBtnDft = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--color-dft-btn)",
          color: "ButtonText",
          ":hover": {
            background: "var(--color-dft-hover)",
          },
        },
      },
    },
  },
});

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          transform: "scale(1)",
          transition: "transform 0.05s",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
            transition: "transform 0.05s",
          },
        },
      },
    },
  },
});

const themeLblHeadTbl = createTheme({
  components: {
    // Name of the component
    MuiTableSortLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // textAlign: 'center',
        },
        icon: {
          // display: "none",
          // ":hover": {
          //   display: "none",
          // },
        },
      },
    },
  },
});

interface Data {
  no: number;
  name: string;
  actions: any;
  status: string;
}

const initForm = {
  id: 0,
  type: "",
  accountNumber: "",
  nameBank: "",
  identificationId: "",
  nameProprietor: "",
  email: "",
  status: "accepted",
};

const DataBankParams = () => {
  // const { onOpenDialog } = useContext(ConfirmDialogContext);
  const { onOpenPopUp } = useContext(ConfirmPopUpContext);
  const { onOpenToast } = useContext(ToastContext);
  const { onOpenAbortAlert } = useContext(AlertContext);

  const { getDataBanksUsers, postPutDataBankParam, deleteBankParam } =
    useDataBankParams();

  const [isFetching, setIsFetching] = useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("no");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [listData, setListData] = useState<IGetDataDataBankParams[]>([]);
  const [readOnly, setReadOnly] = useState(false);

  const [rows, setRows] = useState<Data[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const getData = async () => {
    setIsFetching(true);
    const resp = await getDataBanksUsers();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      setListData(resp.data);
      setRows(
        resp.data.map((el, index) => {
          return {
            no: el.id,
            name: el.nameBank,
            actions: 0,
            status: el.status,
          };
        })
      );
    }
    setIsFetching(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number
    ) => void;
  }

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const navigate = useNavigate();

  const {
    accountNumber,
    email,
    id,
    identificationId,
    nameBank,
    nameProprietor,
    type,
    onChange,
    updateForm,
    form,
  } = useForm(initForm);

  const { validateNumber, isValid } = useValidateInput();

  const pressBtnSave = async () => {
    const validateNameBank = () => {
      return new Promise((resolve, reject) => {
        if (form.nameBank.trim().length > 0) resolve(form.nameBank);
        else reject("Debe ingresar el nombre del banco");
      });
    };

    const validateTypeAccount = () => {
      return new Promise((resolve, reject) => {
        if (form.type.trim().length > 0) resolve(form.type);
        else reject("Debe ingresar el tipo de cuenta");
      });
    };

    const validateAccountNumber = () => {
      return new Promise((resolve, reject) => {
        if (form.accountNumber.trim().length > 0) resolve(form.accountNumber);
        else reject("Debe ingresar el número de cuenta");
      });
    };

    const validateOwner = () => {
      return new Promise((resolve, reject) => {
        if (form.nameProprietor.trim().length > 0) resolve(form.nameProprietor);
        else reject("Debe ingresar el nombre del titular");
      });
    };

    const validateCI = () => {
      return new Promise((resolve, reject) => {
        if (form.identificationId.trim().length > 0)
          resolve(form.identificationId);
        else reject("Debe ingresar su número de identificación (CI/RUC)");
      });
    };

    const validateEmail = () => {
      return new Promise((resolve, reject) => {
        if (form.email.trim().length > 0) resolve(form.email);
        else reject("Debe ingresar el E-mail");
      });
    };

    validateNameBank()
      .then(() => validateTypeAccount())
      .then(() => validateAccountNumber())
      .then(() => validateOwner())
      .then(() => validateCI())
      .then(() => validateEmail())
      .then(async () => {
        const resp = await postPutDataBankParam(form);
        if (resp) {
          if (resp.aborted) {
            alert("tiempo de espera agotado");
            return;
          }
          if (id !== 0) {
            onOpenToast("success", "Guardado", "Nuevo banco actualizado");
            setListData(
              listData.map((el) => {
                if (el.id === id) {
                  return {
                    id: resp.data.id,
                    type: resp.data.type,
                    accountNumber: resp.data.accountNumber,
                    nameBank: resp.data.nameBank,
                    identificationId: resp.data.identificationId,
                    nameProprietor: resp.data.nameProprietor,
                    email: resp.data.email,
                    status: resp.data.status,
                    idUser: resp.data.idUser,
                  };
                } else {
                  return el;
                }
              })
            );
            setRows(
              rows.map((el) => {
                if (el.no === id) {
                  return {
                    no: resp.data.id,
                    name: resp.data.nameBank,
                    actions: 0,
                    status: resp.data.status,
                  };
                } else {
                  return el;
                }
              })
            );
            clean();
            return;
          }
          onOpenToast("success", "Guardado", "Nuevo banco guardado");
          setListData([
            ...listData,
            {
              id: resp.data.id,
              type: resp.data.type,
              accountNumber: resp.data.accountNumber,
              nameBank: resp.data.nameBank,
              identificationId: resp.data.identificationId,
              nameProprietor: resp.data.nameProprietor,
              email: resp.data.email,
              status: resp.data.status,
              idUser: resp.data.idUser,
            },
          ]);
          setRows([
            ...rows,
            {
              no: resp.data.id,
              name: resp.data.nameBank,
              actions: 0,
              status: resp.data.status,
            },
          ]);
          clean();
        } else {
          onOpenToast("error", "Error", UNHANDLED_ERROR);
        }
      })
      .catch((err) => {
        onOpenToast("warn", "", err);
      });
  };

  const clean = () => {
    updateForm(initForm);
    setReadOnly(false);
  };

  const updateDataRow = async (id: number) => {
    const section = document.querySelector("#navbar-pivot");
    section!.scrollIntoView();
    setReadOnly(false);
    const selectedItem = listData.filter((el) => el.id === id);
    if (selectedItem.length > 0) {
      const item = selectedItem[0];
      updateForm({
        id: item.id,
        type: item.type,
        accountNumber: item.accountNumber,
        nameBank: item.nameBank,
        identificationId: item.identificationId,
        nameProprietor: item.nameProprietor,
        email: item.email,
        status: item.status,
      });
    }
  };

  const updateStatusRow = async (id: number, state: string) => {
    const resp = await postPutDataBankParam({
      id,
      status: state === "accepted" ? "rejected" : "accepted",
    });
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      setListData(
        listData.map((el) => {
          if (el.id === id) {
            return {
              ...el,
              status: resp.data.status,
            };
          } else {
            return el;
          }
        })
      );
      setRows(
        rows.map((el) => {
          if (el.no === id) {
            return {
              ...el,
              status: resp.data.status,
            };
          } else {
            return el;
          }
        })
      );
      return;
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
  };

  const deleteRow = async (id: number) => {
    onOpenPopUp(
      async () => {
        const resp = await deleteBankParam(id);
        if (resp) {
          if (resp.aborted) {
            alert("tiempo de espera agotado");
            return;
          }
          setListData(listData.filter((el) => el.id !== id));
          setRows(rows.filter((el) => el.no !== id));
          onOpenToast(
            "info",
            "Eliminado",
            "Haz eliminado el banco correctamente."
          );
        }else {
          onOpenToast("error", "", UNHANDLED_ERROR);
        }
      },
      "¿Está seguro que desea proceder?",
      `btn-delete-${id}`
    );
  };

  const watchRow = (id: number) => {
    // navigate('#navbar-pivot')
    const section = document.querySelector("#navbar-pivot");
    section!.scrollIntoView();
    updateDataRow(id);
    setReadOnly(true);
  };

  return (
    <BodyLayout>
      <div className="d-flex flex-wrap gap-4 flex-column">
        <div className="d-flex align-items-center ">
          <Typography variant="h5" className="flex-grow-1 fw-bold">
            Parámetros Bancarios
          </Typography>
        </div>
        <div className="flex-grow-1 d-flex align-items-center gap-5 flex-column w-75 mx-auto">
          <div className="d-flex gap-4 flex-wrap w-100">
            <div className="flex-grow-1 d-flex gap-3 flex-column ">
              <div>
                <Typography>Nombre del Banco</Typography>
                <TextField
                  disabled={readOnly ? true : false}
                  hiddenLabel
                  defaultValue={nameBank}
                  value={nameBank}
                  name="nameBank"
                  variant="filled"
                  fullWidth
                  onChange={onChange}
                  placeholder="Ex: Banco del Pacífico"
                />
              </div>
              <div>
                <Typography>Número de cuenta</Typography>
                <TextField
                  disabled={readOnly ? true : false}
                  hiddenLabel
                  defaultValue={accountNumber}
                  value={accountNumber}
                  name="accountNumber"
                  variant="filled"
                  fullWidth
                  onChange={onChange}
                  placeholder="Escribe el número de cuenta"
                />
              </div>
              <div>
                <Typography>Cédula</Typography>
                <TextField
                  disabled={readOnly ? true : false}
                  hiddenLabel
                  defaultValue={identificationId}
                  value={identificationId}
                  name="identificationId"
                  variant="filled"
                  fullWidth
                  onChange={onChange}
                  placeholder="RUC o Cédula"
                />
              </div>
            </div>
            <div className="flex-grow-1 d-flex gap-3 flex-column ">
              <div>
                <Typography>Tipo de cuenta</Typography>
                <TextField
                  disabled={readOnly ? true : false}
                  hiddenLabel
                  defaultValue={type}
                  value={type}
                  name="type"
                  variant="filled"
                  fullWidth
                  onChange={onChange}
                  placeholder="Corriente, Ahorros"
                />
              </div>
              <div>
                <Typography>Nombre del Titular</Typography>
                <TextField
                  disabled={readOnly ? true : false}
                  hiddenLabel
                  defaultValue={nameProprietor}
                  value={nameProprietor}
                  name="nameProprietor"
                  variant="filled"
                  fullWidth
                  onChange={onChange}
                  placeholder="Escribe el nombre del titular de la cuenta"
                />
              </div>
              <div>
                <Typography>Correo Electrónico</Typography>
                <TextField
                  disabled={readOnly ? true : false}
                  hiddenLabel
                  defaultValue={email}
                  value={email}
                  name="email"
                  variant="filled"
                  fullWidth
                  onChange={onChange}
                  placeholder="example@ecuadorian.com"
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between w-100">
            <ThemeProvider theme={themeBtnDft}>
              <Button variant="contained" onClick={() => clean()}>
                <Typography>Limpiar</Typography>
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={themeBtnDftActive}>
              <Button variant="contained" onClick={pressBtnSave}>
                <Typography>Guardar</Typography>
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </div>
      {/* <Divider sx={{ margin: "2rem", borderColor: "var(--primary-color)" }} /> */}
      <Divider sx={{ margin: "2rem", borderColor: "transparent" }} />
      <Box sx={{ width: "100%", maxHeight: "75vh" }}>
        <Paper sx={{ width: "80%", mb: 2 }} className="mx-auto">
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer sx={{ maxHeight: "65vh", maxWidth: "100%" }}>
            <Table
              sx={{
                minWidth: 750,
              }}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                <TransitionGroup component={null}>
                  {stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <Grow key={index} in={true}>
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.name)}
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="center">{row.name}</TableCell>
                            <TableCell align="center">
                              <div className="d-flex gap-1 justify-content-center">
                                <IconButton
                                  onClick={() => deleteRow(row.no)}
                                  id={`btn-delete-${row.no}`}
                                >
                                  <DeleteIcon sx={{ width: 20, height: 20 }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => updateDataRow(row.no)}
                                >
                                  <BorderColorIcon
                                    sx={{ width: 20, height: 20 }}
                                  />
                                </IconButton>
                                <IconButton onClick={() => watchRow(row.no)}>
                                  <RemoveRedEyeIcon
                                    sx={{ width: 20, height: 20 }}
                                  />
                                </IconButton>
                                <Switch
                                  {...label}
                                  checked={
                                    row.status === "accepted" ? true : false
                                  }
                                  onClick={() =>
                                    updateStatusRow(row.no, row.status)
                                  }
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        </Grow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 60 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TransitionGroup>
              </TableBody>
            </Table>
            {isFetching && (
              <div className="w-100 d-flex justify-content-center mt-5 mb-2">
                <RotateSpinner
                  size={70}
                  color={COLOR_LOADING}
                  loading={isFetching}
                />
              </div>
            )}
          </TableContainer>
          <div className="d-flex justify-content-center w-100 p-4">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Todo", value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Mostrar"
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              className=""
            />
          </div>
        </Paper>
      </Box>
    </BodyLayout>
  );
};

export default DataBankParams;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "no",
    numeric: true,
    disablePadding: false,
    label: "No",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow
        sx={{
          "& th": {
            backgroundColor: BKG_TABLE_HEADER,
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.id === "actions" || headCell.id === "name"
                ? "center"
                : "justify"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <ThemeProvider theme={themeLblHeadTbl}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === "actions" ? null : (
                  <>
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </>
                )}
              </TableSortLabel>
            </ThemeProvider>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
