import axios from "axios"
import fileDownload from 'js-file-download';

export default function SaveFile() {
	
	interface IResponse {
		error: boolean;
	}
	const handleDownload = async (uri: string, fileName?: string): Promise<IResponse> => {
		if (!fileName) {
			fileName = uri.split('/').pop();
		}
		const resp = await axios.get(uri, { responseType: 'blob' });
		if (resp.status === 200) {
			fileDownload(resp.data, fileName!)
			return {error: false}
		} else {
			return { error: true}
		}
	}

	return {
		handleDownload
	}
}