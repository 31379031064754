import { createContext } from 'react';
import { Socket } from 'socket.io-client';
import { domainLocal } from '../../api/ecuadorianApi';
import { useSocket } from '../../hooks/useSocket';

type SocketContextProps = {
  socket: Socket;
  online: boolean;
};

export const SocketContext = createContext({} as SocketContextProps);


export const SocketProvider = ({ children }: any) => {
  // const { socket, online } = useSocket('https://equatorian.nousproyect.com'); 
 const { socket, online } = useSocket(domainLocal); 

  return <SocketContext.Provider value={{ socket, online }}>{children}</SocketContext.Provider>;
};
