import { ToastContext } from "../../contexts/toast/ToastContext";
import { Toast } from "primereact/toast";
import { useContext } from "react";

const CustomToast = () => {
  const { refToast, positionToast } = useContext(ToastContext);

  return (
    <div>
      <Toast ref={refToast} position={positionToast}></Toast>
    </div>
  );
};

export default CustomToast;
