import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth/AuthContext";
import ViewPackage from "../pages/admin/ViewPackage";
import SignIn from "../pages/auth/Signin";

const PublicRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="signIn" element={<SignIn />} />
       
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's no match public URL!</p>
            </main>
          }
        />
      </Routes>
    </div>
  );
};

export default PublicRoutes;
