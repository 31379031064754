import React, { createContext, useContext, useEffect, useReducer } from "react";
import jwt_decode from "jwt-decode";
import { authReducer, AuthState } from "./authReducer";
import { useLocation, useNavigate } from "react-router-dom";

import ecuadorianApi from "../../api/ecuadorianApi";
import { useAuth } from "../../hooks/useAuth";
import { IUserAuth } from "../../interfaces/ISignIn";
import { helpHttp } from "../../helpers/helpHttp";
import { createBrowserHistory } from "history";
import { ToastContext } from "../toast/ToastContext";
interface ILoginData {
  email: string;
  password: string;
}

type AuthContextProps = {
  errorMessage: string;
  token: string | null;
  user: IUserAuth | null;
  status: "checking" | "authenticated" | "not-authenticated";
  // signUp: (registerUser: UserRegister) => Promise<void>;
  signIn: (loginData: ILoginData) => Promise<void>;
  // updateUser: (user: IUserAuth) => void;
  logOut: () => void;
  removeError: () => void;
  isLoading: boolean;
};

const authInitialState: AuthState = {
  status: "checking",
  token: null,
  user: null,
  errorMessage: "",
  isLoading: false,
};
export const AuthContext = createContext({} as AuthContextProps);

export const AuthProvider = ({ children }: any) => {
  const navigate = useNavigate();
  const { onOpenToast } = useContext(ToastContext);

  // let location = useLocation();
  // const history = createBrowserHistory();
  // const from = location.pathname || "/";
  // console.log("🚀 ~ file: AuthContext.tsx ~ line 42 ~ AuthProvider ~ from", from)

  const [state, dispatch] = useReducer(authReducer, authInitialState);

  useEffect(() => {
    checkToken();
  }, []);

  // useEffect(() => {
  //   console.log('history ==>', history)
  // }, [history]);

  const checkToken = async () => {
    dispatch({ type: "activeLoading" });
    const token = localStorage.getItem("token");

    /* Checking if there is a token in localStorage. If there is no token, it will dispatch the action
    "notAuthenticated" and return. */
    if (!token) {
      dispatch({ type: "removeLoading" });
      return dispatch({ type: "notAuthenticated" });
    }

    /* Checking if the user is authenticated. */
    const resp = await helpHttp().get("auth/");
    if (resp.status !== 200) {
      dispatch({ type: "removeLoading" });
      return dispatch({ type: "notAuthenticated" });
    }

    // navigate(from, { replace: true });
    // history.replace('/admin/requests');
    const decodedToken: any = jwt_decode(resp.data.token);

    // await getAllInfoUser(decodedToken.object.id);
    localStorage.setItem("token", resp.data.token);
    localStorage.setItem("user", decodedToken.object);
    dispatch({ type: "removeLoading" });
    dispatch({
      type: "signUp",
      payload: {
        token: resp.data.token,
        user: decodedToken.object,
      },
    });
    // getPermissionsNotifications()
  };

  const signIn = async ({ email, password }: ILoginData) => {
    try {
      // dispatch({ type: 'activeLoading' });

      await helpHttp()
        .post("auth/loginAdmin", {
          data: { email, password },
        })
        .then((data) => {
          const decodedToken: any = jwt_decode(data.data);

          dispatch({
            type: "signUp",
            payload: {
              token: data.data,
              user: decodedToken.object,
            },
          });

          navigate("/admin/requests", { replace: true });
          // history.replace('/admin/requests');
          localStorage.setItem("token", data.data);
          localStorage.setItem("user", decodedToken.object);
        })
        .catch((e: any) => {
          // console.log("🚀 ~ file: AuthContext.tsx ~ line 165 ~ resp ~ e", e);
          if (e.status === 400) {
            onOpenToast(
              "error",
              "Error",
              "El e-mail o la contraseña son incorrectos."
            );
          } else {
            onOpenToast("error", "Error", "Unknown Error");
          }
        });

      // // dispatch({ type: 'removeLoading' });
    } catch (error: any) {
      // dispatch({ type: 'removeLoading' });
      dispatch({
        type: "addError",
        payload:
          error.response.data.msg ||
          "El correo electrónico o la contraseña son incorrectos",
      });
    }
  };

  // const updateUser = async (user: User) => {
  //   dispatch({ type: "updateUser", payload: { user } });
  // };

  const logOut = async () => {
    dispatch({ type: "logout" });
    localStorage.clear();
    // OneSignal.removeExternalUserId()
    //   .then((resp) => {
    //     console.log("external user id removido");
    //   })
    //   .catch((e) => {
    //     console.log("error al remover el external user id");
    //   });
  };

  const removeError = () => {
    dispatch({ type: "removeError" });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        // signUp,
        signIn,
        logOut,
        removeError,
        // updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
