import React from "react";
import BodyLayout from '../../components/shared/BodyLayout'

const Settings = () => {
  return (
    <BodyLayout>
      <div>Settings</div>
    </BodyLayout>
  );
};

export default Settings;
