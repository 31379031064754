import { ToastPositionType } from "primereact/toast";
import React, { createContext, useRef, useState } from "react";

type ToastContextProps = {
  onOpenToast: (
    severity: "success" | "info" | "warn" | "error",
    title: string,
    detail: string,
    life?: number,
    position?: ToastPositionType | undefined
  ) => void;
  refToast: any;
  positionToast: ToastPositionType | undefined;
};

export const ToastContext = createContext({} as ToastContextProps);

export const ToastProvider = ({ children }: any) => {
  const refToast = useRef<any>(null);
  const [positionToast, setPositionToast] = useState<
    ToastPositionType | undefined
  >("top-right");

  const onOpenToast = (
    severity: "success" | "info" | "warn" | "error",
    title: string,
    detail: string,
    life: number = 3000,
    position: ToastPositionType | undefined
  ) => {
    refToast!.current!.show({ severity, summary: title, detail, life });
    setPositionToast(position);
  };

  return (
    <ToastContext.Provider value={{ onOpenToast, refToast, positionToast }}>
      {children}
    </ToastContext.Provider>
  );
};
