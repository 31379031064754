import React, { useContext, useState } from "react";
import { useForm } from "../../hooks/useForm";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import logo from "../../assets/logo.png";
import main from "../../stylesheets/main.module.css";
import "../../App.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../contexts/auth/AuthContext";
import { Password } from "primereact/password";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import {
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Link as Linker,
} from "@mui/material";

const initForm = {
  email: "",
  password: "",
};

const SignIn = () => {
  const { signIn } = useContext(AuthContext);

  const { email, password, onChange, form } = useForm(initForm);

  const [showPassword, setShowPassword] = useState(false);

  const clickSignIn = () => {
    console.table(form);
    signIn({ ...form });
    // navigate("/admin/requests");
  };

  return (
    <div
      className="container d-flex align-items-center"
      style={{ height: "100vh" }}
    >
      <div className="row mx-auto col-md-5">
        <div className="card text-center p-4 border-0">
          <div className="card-header p-4 border-bottom-0">
            <div className="h-50">
              <div className="col-md-5 mx-auto">
                <img alt="logo.png" src={logo} style={{ width: "100%" }} />
              </div>
              {/* <h1>Iniciar Sesión</h1> */}
            </div>
          </div>
          <div className="card-body">
            <div className="mb-4  w-100">
              <FormControl
                sx={{ width: "100%" }}
                className=""
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Correo electrónico
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  sx={{ borderRadius: "50px", background: "var(--color-gray)" }}
                  id="outlined-adornment-email"
                  value={email}
                  onChange={onChange}
                  name="email"
                  label="Correo electrónico"
                />
              </FormControl>
            </div>
            <div className="w-100 mb-3">
              <FormControl
                sx={{ width: "100%" }}
                className=""
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Contraseña
                </InputLabel>
                <OutlinedInput
                  fullWidth
                  sx={{ borderRadius: "50px", background: "var(--color-gray)" }}
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={onChange}
                  name="password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Contraseña"
                />
              </FormControl>
              <div className='px-3 d-flex justify-content-start'>
                <Linker
                  href="/account/admin/reset/pass/"
                  underline="hover"
                  sx={{}}
                >
                  ¿Restablecer contraseña?
                </Linker>
              </div>
            </div>
          </div>
          <div className="card-footer w-100 p-4 border-top-0">
            {/* <Link to="/admin/requests">
            
            </Link> */}
            <Button
              label="Iniciar Sesión"
              aria-label="Submit"
              className={main.button}
              onClick={clickSignIn}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
