import { CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { useAuth } from "../../hooks/useAuth";
import logo from "../../assets/logo.png";

const VerifiedUser = () => {
  const params = useParams();
  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { confirmAccount: confirmAccountUser } = useAuth();
  const { onOpenAbortAlert } = useContext(AlertContext);

  useEffect(() => {
    if (params.token) {
      checkToken(params.token);
    }
  }, []);

  const checkToken = async (token: string) => {
		try {
			setIsLoading(true);
      const resp = await confirmAccountUser(token);
      if (resp) {
        if (resp.aborted) {
          onOpenAbortAlert({});
          setConfirm(false);
          setIsLoading(false);
          return;
        }
        if (resp.ok) {
          setConfirm(true);
          setIsLoading(false);
          return;
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      setConfirm(false);
      setIsLoading(false);
      // console.log(error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return isLoading ? (
    <div>
      <CircularProgress />
    </div>
  ) : (
    <>
      {confirm ? (
        <div
          className="border"
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="container">
            <div className="row col-md-5 mx-auto">
              <div className="card">
                <div className="card-body">
                  <div className="col-md-5 mx-auto">
                    <img alt="logo.png" src={logo} style={{ width: "100%" }} />
                  </div>
                </div>
                <div className="card-fotter">
                  <Typography
                    variant="h5"
                    color="initial"
                    style={{ textAlign: "center" }}
                  >
                    Confirmaste tu cuenta
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Typography variant="h5" color="initial">
            La cuenta no se puede confirmar
          </Typography>
        </div>
      )}
    </>
  );
};

export default VerifiedUser;
