import { ABORTED_REQUEST } from "../helpers/consants";
import { helpHttp } from "../helpers/helpHttp";
import {
  IPutRequest,
  IResDataRequests,
  IResGetRequests,
  IResPutRequest,
  IResPutRequestTransport,
} from "../interfaces/IRequests";

interface paginationReq {
  from: number;
  to: number;
  typeReq: "all" | "delivery" | "transport" | "package";
}

interface bodyReq {
  idPayment: number,
  status: string,
  month: number | string ,
  year: number | string, 
}

export const useRequests = () => {
  const getRequests = async (
    { from, to, typeReq }: paginationReq,
    data: bodyReq
  ): Promise<IResDataRequests | null> => {
    try {
      const resp = (
        await helpHttp().post(`request/getRequests/${typeReq}/${from}/${to}`, {
          data: {...data, status: data.status === '0' ? '' : data.status},
        })
      ).data;
      return resp;
    } catch (error) {
      // console.log(error);
      return null;
    }
  };

  const putRequest = async (
    data: IPutRequest
  ): Promise<IResPutRequest | null> => {
    try {
      const resp = await helpHttp().put(`request/putAcceptRequestTour`, {
        data,
      });
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };
  
  const putRequestTransport = async (
    data: IPutRequest
  ): Promise<IResPutRequestTransport | null> => {
    try {
      const resp = await helpHttp().put(`request/putAcceptRequestTransport`, {
        data,
      });
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };

  return {
    getRequests,
    putRequest,
    putRequestTransport
  };
};

export default useRequests;
