import {
  Typography,
  Button,
  Divider,
  createTheme,
  alpha,
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  ThemeProvider,
  TextField,
  Grow,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BodyLayout from "../../components/shared/BodyLayout";
import { visuallyHidden } from "@mui/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import NumberFormat from "react-number-format";
import { useValidateInput } from "../../hooks/useValidateInput";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { ToastContext } from "../../contexts/toast/ToastContext";
import useCars, { TStatusCar } from "../../hooks/useCars";
import { BKG_TABLE_HEADER, COLOR_LOADING, UNHANDLED_ERROR } from "../../helpers/consants";
import {
  IDataVehicle,
  IPostPutVehicle,
  IResPostPutVehicle,
} from "../../interfaces/ICars";
import { TransitionGroup } from "react-transition-group";
import { ConfirmPopUpContext } from "../../contexts/confirmPopUp/ConfirmPopUpContext";
import { RotateSpinner } from "react-spinners-kit";

const label = { inputProps: { "aria-label": "Switch demo" } };

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          transform: "scale(1)",
          transition: "transform 0.05s",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
            // transform: "scale(1.03)",
            transition: "transform 0.05s",
          },
        },
      },
    },
  },
});

const themeLblHeadTbl = createTheme({
  components: {
    // Name of the component
    MuiTableSortLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // textAlign: 'center',
        },
        icon: {
          // display: "none",
          // ":hover": {
          //   display: "none",
          // },
        },
      },
    },
  },
});

const themeBtnDft = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--color-dft-btn)",
          color: "ButtonText",
          ":hover": {
            background: "var(--color-dft-hover)",
          },
        },
      },
    },
  },
});

interface Data {
  no: number;
  name: string;
  minPassenger: number;
  maxPassenger: number;
  actions: any;
  status: string;
}

const initForm: IPostPutVehicle = {
  id: 0,
  brand: "",
  maxPassenger: 0,
  minPassenger: 0,
  status: "accepted",
};

const Cars = () => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("no");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { onOpenAbortAlert } = useContext(AlertContext);
  const { onOpenToast } = useContext(ToastContext);
  const { onOpenPopUp } = useContext(ConfirmPopUpContext);

  const { getAllVehicles, putStatusCar, postPutVehicle, deleteVehicleById } =
    useCars();

  const [isFetching, setIsFetching] = useState(false);
  const [carsList, setCarsList] = useState<Data[]>([]);
  const [isNew, setIsNew] = useState(true);
  const [rowToEdit, setRowToEdit] = useState(0);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [updateStatusProm, setUpdateStatusProm] = useState<
    Promise<IResPostPutVehicle | null>[]
  >([]);

  const { form, onChange, updateForm } = useForm(initForm);
  const { form: formPivot, updateForm: updateFormPivot } = useForm({
    ...initForm,
    id: -1,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = carsList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number
    ) => void;
  }

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - carsList.length) : 0;

  const handleStatusCar = async (id: number, status: TStatusCar) => {
    setUpdateStatusProm([
      ...updateStatusProm,
      putStatusCar({ id: id, status }),
    ]);
  };

  const handleWatchCar = (id: number) => {
    const section = document.querySelector("#navbar-pivot");
    section!.scrollIntoView();
    handleUpdateCar(id);
    setIsReadOnly(true);
  };

  const handleUpdateCar = (id: number) => {
    const section = document.querySelector("#navbar-pivot");
    section!.scrollIntoView();
    setIsReadOnly(false);
    const row: Data | undefined = carsList.find((el) => el.no === id);
    if (row) {
      const rowFound: IPostPutVehicle = {
        maxPassenger: row.maxPassenger,
        minPassenger: row.minPassenger,
        brand: row.name,
        id: row.no,
        status: row.status,
      };
      setIsNew(false);
      setRowToEdit(id);
      updateForm(rowFound);
      updateFormPivot(rowFound);
    }
  };

  const clean = () => {
    updateForm(initForm);
    setIsNew(true);
    setRowToEdit(0);
    setIsReadOnly(false);
    updateFormPivot({ ...initForm, id: -1 });
  };

  const handleSaveCar = async () => {
    if (JSON.stringify(form) === JSON.stringify(formPivot)) {
      clean();
      return;
    }

    const validateBrand = () => {
      return new Promise((resolve, reject) => {
        if (form.brand.trim().length > 0) resolve(form.brand);
        else reject("Ingrese la marca o modelo del vehículo");
      });
    };

    const validateMaxPassenger = () => {
      return new Promise((resolve, reject) => {
        if (
          form.maxPassenger.toString().trim().length > 0 &&
          parseInt(form.maxPassenger.toString()) > 0
        ) {
          resolve(form.maxPassenger);
        } else {
          reject("Ingrese el número máximo de pasajeros de este vehículo");
        }
      });
    };

    const validateMinPassenger = () => {
      return new Promise((resolve, reject) => {
        if (
          form.minPassenger.toString().trim().length > 0 &&
          parseInt(form.minPassenger.toString()) > 0
        ) {
          resolve(form.minPassenger);
        } else {
          reject("Ingrese el número mínimo de pasajeros de este vehículo");
        }
      });
    };

    validateBrand()
      .then(() => validateMaxPassenger())
      .then(() => validateMinPassenger())
      .then(async () => {
        const resp = await postPutVehicle(form);
        if (resp) {
          if (resp.aborted) {
            onOpenAbortAlert({});
            return;
          }
          if (resp.data) {
            const aux: Data = {
              actions: 0,
              maxPassenger: resp.data.maxPassenger,
              minPassenger: resp.data.minPassenger,
              name: resp.data.brand,
              no: resp.data.id,
              status: form.status,
            };

            if (isNew && form.id === 0) {
              setCarsList([...carsList, aux]);
              onOpenToast("success", "Guardado", "Nuevo vehículo creado.");
            } else {
              setCarsList(
                carsList.map((el) => (el.no === rowToEdit ? aux : el))
              );
              onOpenToast("success", "Guardado", "Vehículo actualizado.");
            }
            clean();
          }
        } else {
          onOpenToast("error", "", UNHANDLED_ERROR);
        }
      })
      .catch((err) => {
        onOpenToast("warn", "", err);
      });
  };

  const handleDeleteCar = async (id: number) => {
    onOpenPopUp(
      async () => {
        const resp = await deleteVehicleById(id);
        if (resp) {
          if (resp.aborted) {
            onOpenAbortAlert({});
            return;
          }
          const filteredCars = carsList.filter((el) => el.no !== id);
          setCarsList(filteredCars);
        } else {
          onOpenToast("error", "", UNHANDLED_ERROR);
        }
      },
      "¿Está seguro que desea proceder?",
      `btn-delete-${id}`
    );
  };

  const getData = async () => {
    setIsFetching(true);
    const resp = await getAllVehicles();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.data) {
        const rowsCars: Data[] = resp.data.map((el, index) => {
          return {
            no: el.id,
            actions: 0,
            maxPassenger: el.maxPassenger,
            minPassenger: el.minPassenger,
            name: el.brand,
            status: el.status,
          };
        });
        setCarsList(rowsCars);
      } else {
        onOpenToast("error", "Error", UNHANDLED_ERROR);
      }
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (JSON.stringify(form) !== JSON.stringify(formPivot)) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [form]);

  useEffect(() => {
    if (updateStatusProm.length > 0) {
      Promise.all(updateStatusProm).then((resp) => {
        resp.forEach((res, index) => {
          if (res) {
            if (res.aborted) {
              onOpenAbortAlert({});
              return;
            }
            // console.log(resp);
            const newRows: Data[] = carsList.map((el) => {
              if (res.data) {
                if (el.no === res.data.id) {
                  return {
                    ...el,
                    status: res.data.status,
                  };
                }
              }
              return el;
            });
            setCarsList(newRows);
          } else {
            onOpenToast("error", "", UNHANDLED_ERROR);
            return;
          }
          const aux = updateStatusProm.filter((value, pos) => pos !== index);
          setUpdateStatusProm(aux);
        });
      });
    }
  }, [updateStatusProm]);

  return (
    <BodyLayout>
      <div className="d-flex flex-wrap gap-4 flex-column">
        <div className="d-flex align-items-center ">
          <Typography variant="h5" className="flex-grow-1 fw-bold">
            Autos
          </Typography>
        </div>
        <div className="flex-grow-1 d-flex align-items-center gap-5">
          <div className="flex-grow-1 d-flex gap-3">
            <TextField
              disabled={isReadOnly}
              hiddenLabel
              value={form.brand}
              variant="filled"
              name="brand"
              fullWidth
              onChange={onChange}
              placeholder="Escriba un modelo de auto"
            />
            <div className="d-flex gap-3">
              <TextField
                disabled={isReadOnly}
                hiddenLabel
                value={form.minPassenger === 0 ? "" : form.minPassenger}
                name="minPassenger"
                variant="filled"
                fullWidth
                onChange={onChange}
                placeholder="Min"
                // onKeyDown={(e) => validateNumber(e)}
                type="number"
              />
              <TextField
                disabled={isReadOnly}
                hiddenLabel
                value={form.maxPassenger === 0 ? "" : form.maxPassenger}
                name="maxPassenger"
                variant="filled"
                fullWidth
                onChange={onChange}
                // onKeyDown={(e) => validateNumber(e)}
                placeholder="Max"
                type="number"
              />
            </div>
          </div>

          <div>
            <ThemeProvider theme={isNew ? themeBtnDftActive : themeBtnDft}>
              <Button variant="contained" onClick={handleSaveCar}>
                {form.id === 0 && isNew
                  ? "Agregar"
                  : form.id !== 0 && isNew
                  ? "Actualizar"
                  : "Limpiar"}
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </div>
      <Divider sx={{ margin: "2rem", borderColor: "var(--primary-color)" }} />
      <Box sx={{ width: "100%", maxHeight: "75vh" }}>
        <Paper sx={{ width: "80%", mb: 2 }} className="mx-auto">
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer sx={{ maxHeight: "65vh", maxWidth: "100%" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={carsList.length}
              />
              <TableBody>
                <TransitionGroup component={null}>
                  {stableSort(carsList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <Grow key={index}>
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.name)}
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="center">
                              {row.minPassenger}
                            </TableCell>
                            <TableCell align="center">
                              {row.maxPassenger}
                            </TableCell>
                            <TableCell align="center">
                              <div className="d-flex gap-1 justify-content-center">
                                <IconButton
                                  onClick={() => handleDeleteCar(row.no)}
                                  id={`btn-delete-${row.no}`}
                                >
                                  <DeleteIcon sx={{ width: 20, height: 20 }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleUpdateCar(row.no)}
                                >
                                  <BorderColorIcon
                                    sx={{ width: 20, height: 20 }}
                                  />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleWatchCar(row.no)}
                                >
                                  <RemoveRedEyeIcon
                                    sx={{ width: 20, height: 20 }}
                                  />
                                </IconButton>
                                <Switch
                                  {...label}
                                  checked={
                                    row.status === "accepted" ? true : false
                                  }
                                  onClick={() =>
                                    handleStatusCar(
                                      row.no,
                                      row.status === "accepted"
                                        ? "rejected"
                                        : "accepted"
                                    )
                                  }
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        </Grow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 60 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TransitionGroup>
              </TableBody>
            </Table>
            {isFetching && (
              <div className="w-100 d-flex justify-content-center mt-5 mb-2">
                <RotateSpinner
                  size={70}
                  color={COLOR_LOADING}
                  loading={isFetching}
                />
              </div>
            )}
          </TableContainer>
          <div className="d-flex justify-content-center w-100 p-4">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Todo", value: -1 }]}
              colSpan={3}
              count={carsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Mostrar"
              SelectProps={{
                inputProps: {
                  "aria-label": "carsList per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              className=""
            />
          </div>
        </Paper>
      </Box>
    </BodyLayout>
  );
};

export default Cars;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "no",
    numeric: true,
    disablePadding: false,
    label: "No",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "minPassenger",
    numeric: true,
    disablePadding: false,
    label: "Min Pasajeros",
  },
  {
    id: "maxPassenger",
    numeric: true,
    disablePadding: false,
    label: "Max Pasajeros",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow
        sx={{
          "& th": {
            backgroundColor: BKG_TABLE_HEADER,
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.id === "actions" ||
              headCell.id === "minPassenger" ||
              headCell.id === "maxPassenger"
                ? "center"
                : "justify"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <ThemeProvider theme={themeLblHeadTbl}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === "actions" ? null : (
                  <>
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </>
                )}
              </TableSortLabel>
            </ThemeProvider>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
