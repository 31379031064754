import { ABORTED_REQUEST } from "../helpers/consants";
import { helpHttp } from "../helpers/helpHttp";
import { IResGetTerms, IResPostTerms } from "../interfaces/ITerms";

export default function useTerms() {
  interface IPostPutTerms {
    id: number;
    descriptionTerms: string;
  }
  const postPutTerms = async (
    data: IPostPutTerms
  ): Promise<IResPostTerms | null> => {
    try {
      const resp = await helpHttp().post(`terms/postPutTerms`, {data});
      return resp;
    } catch (error: any) {
      // console.log(error);
      return error.code === ABORTED_REQUEST ? { aborted: true } : null;
    }
  };

  const getTerms = async ():Promise<IResGetTerms | null> => {
    try {
      const resp = await helpHttp().get(`terms/getTerms`);
      return resp;
    } catch (error:any) {
      // console.log(error);
      return error.code === ABORTED_REQUEST ? {aborted: true} : null
    }
  }

  return {
    postPutTerms,
    getTerms,
  };
}
