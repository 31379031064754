import {
  AppBar,
  Avatar,
  Box,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import main from "../../stylesheets/main.module.css";
import {
  AccountCircle,
  Logout,
  PersonAdd,
  Settings,
} from "@mui/icons-material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonIcon from "@mui/icons-material/Person";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SettingsIcon from "@mui/icons-material/Settings";
import { IListItemsMenu, ISubItems } from "./SideBar";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { AuthContext } from "../../contexts/auth/AuthContext";
import logo from "../../assets/logo.png";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import Badge from "@mui/material/Badge";
import Fade from "@mui/material/Fade";
import { SocketContext } from "../../contexts/sockets/SocketContext";
import { ToastContext } from "../../contexts/toast/ToastContext";
import { IRowNotification } from "../../interfaces/INotifications";
import { useNotifications } from "../../hooks/useNotifications";
import { COLOR_LOADING, UNHANDLED_ERROR } from "../../helpers/consants";
import { AlertContext } from "../../contexts/alert/AlertContext";
import Checkbox from "@mui/material/Checkbox";
import { RotateSpinner } from "react-spinners-kit";
import Fab from "@mui/material/Fab";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import Slide from "@mui/material/Slide";
import moment from "moment";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const items: IListItemsMenu[] = [
  {
    name: "Solicitudes",
    icon: <PersonAddIcon />,
    redirectTo: "/admin/requests",
    id: 1,
    subItems: [],
  },
  {
    name: "Tours",
    icon: <CheckCircleIcon />,
    redirectTo: "/admin/savedTours",
    id: 2,
    subItems: [],
  },
  {
    name: "Usuarios",
    icon: <PersonIcon />,
    redirectTo: "/admin/users",
    id: 3,
    subItems: [],
  },
  {
    name: "Términos y C.",
    icon: <PlaylistAddCheckIcon />,
    redirectTo: "/admin/terms",
    id: 4,
    subItems: [],
  },
  {
    name: "Configuración",
    icon: <SettingsIcon />,
    redirectTo: "/admin/settings",
    id: 5,
    subItems: [
      {
        name: "Provincias",
        icon: null,
        redirectTo: "/admin/provinces",
        id: 1,
      },
      {
        name: "Tarifas",
        icon: null,
        redirectTo: "/admin/tariff",
        id: 2,
      },
      // {
      //   name: "Coordinadores",
      //   icon: null,
      //   redirectTo: "/admin/coordinators",
      //   id: 3,
      // },
      // {
      //   name: "Reseñas",
      //   icon: null,
      //   redirectTo: "/admin/reviews",
      //   id: 4,
      // },
      {
        name: "Autos",
        icon: null,
        redirectTo: "/admin/cars",
        id: 5,
      },
      {
        name: "Parámetros Bancarios",
        icon: null,
        redirectTo: "/admin/dataBankParams",
        id: 6,
      },
    ],
  },
];

const NavBar = ({ children }: any) => {
  const { user, logOut } = useContext(AuthContext);
  const { socket } = useContext(SocketContext);
  const { onOpenToast } = useContext(ToastContext);
  const { onOpenAbortAlert } = useContext(AlertContext);

  const navigate = useNavigate();
  const { getNotifications, putNotificationToViewed, deleteAllNotifications, deleteNotificationById } =
    useNotifications();

  const [activeItem, setActiveItem] = useState({ id: 1, active: true });
  const [isOpenSettings, setIsOpenSettings] = useState(false);
  const [isOpenNotifications, setIsOpenNotifications] = useState(false);
  const [countNotification, setCountNotification] = useState(0);
  const [notificationsList, setNotificationsList] = useState<
    IRowNotification[]
  >([]);
  const [loadingNotifications, setLoadingNotifications] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const body = document.getElementById("body");
  // body?.addEventListener("click", () => {
  //   setIsOpenNotifications(false);
  // });

  const handleOpenNotifications = () => {
    setIsOpenNotifications((prev) => !prev);
    // setCountNotification(countNotification + 1);
  };

  const handleLogOut = () => {
    logOut();
    window.location.reload();
  };

  const handleClickClearAll = async () => {
    const resp = await deleteAllNotifications();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      setNotificationsList([]);
      setCountNotification(0);
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }

    // while (notificationsList.length > 0) {
    //   setNotificationsList(notificationsList.splice(-1, 1));
    //   if (notificationsList.length === 1) {
    //     setNotificationsList([])
    //   }
    // }
  };

  const handleClickItem = (
    id: number,
    redirectTo: string,
    subItems: ISubItems[]
  ) => {
    if (subItems.length > 0) {
      setIsOpenSettings(!isOpenSettings);
    }
    setActiveItem({ id, active: true });
    if (id !== 5) navigate(redirectTo);
  };

  const getNotificationsList = async () => {
    setLoadingNotifications(true);
    const resp = await getNotifications();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        setLoadingNotifications(false);
        return;
      }
      if (resp.data) {
        setNotificationsList(resp.data.filter(el => el.viewed !== true));
      } else {
        setNotificationsList([]);
      }
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
    setLoadingNotifications(false);
  };

  const handleClickNotification = async (row: IRowNotification) => {
    setIsOpenNotifications(false);
    const resp = await deleteNotificationById(row.id)
    // const resp = await putNotificationToViewed(row.id);
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      // setCountNotification((prev) => prev - 1)
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
    switch (row.type) {
      case 1:
        navigate("/admin/requests");
        break;
      case 2:
        navigate("/admin/users");
        break;
      case 3:
        // navigate("/admin/cars");
        navigate("/admin/users");
        break;
      default:
        console.log(
          "El tipo de notificacion no coincide con ninguna ruta declara. ",
          row.type
        );
        break;
    }
  };

  useEffect((): any => {
    listenNotification();
    return () => socket.off("listen-notication-for-admin");
  }, [socket]);

  useEffect(() => {
    if (user && user.notifications) {
      setCountNotification(user.notifications);
    }
  }, [user]);

  useEffect(() => {
    if (isOpenNotifications) {
      setCountNotification(0)
      getNotificationsList();
    }
  }, [isOpenNotifications]);

  const listenNotification = async () => {
    await socket.on("listen-notication-for-admin", (resp) => {
      onOpenToast("info", resp.title, resp.body);
      setCountNotification((prev) => prev + 1);
    });
  };

  //! ADD LOGOUT OPTION IN SIDEBAR

  const renderItem = (item: IListItemsMenu, index: number) => {
    return (
      <div key={index}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              handleClickItem(item.id, item.redirectTo, item.subItems)
            }
          >
            <ListItemIcon color="white" sx={{ color: "white" }}>
              {item.icon}
            </ListItemIcon>
            <ListItemText primary={item.name} sx={{ color: "white" }} />
            {item.subItems.length > 0 ? (
              <>
                {isOpenSettings ? (
                  <ExpandLess sx={{ color: "white" }} />
                ) : (
                  <ExpandMore sx={{ color: "white" }} />
                )}
              </>
            ) : null}
          </ListItemButton>
        </ListItem>
        <Collapse in={isOpenSettings} timeout="auto" unmountOnExit>
          {item.subItems.map((el, pos) => (
            <List component="div" disablePadding key={pos}>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleClickItem(el.id, el.redirectTo, [])}
              >
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={el.name} sx={{ color: "white" }} />
              </ListItemButton>
            </List>
          ))}
        </Collapse>
        <Divider />
      </div>
    );
  };

  return (
    <nav className="navbar navbar-expand-lg bg-light" id="navbar-pivot">
      <div className="container-fluid d-flex flex-row-reverse nav-cont-responsive">
        {/* <a className="navbar-brand" href="/">Navbar</a> */}
        <div
          className="d-flex flex-row w-100 justify-content-between nav-content"
          style={{ paddingLeft: "5rem" }}
        >
          <div className=" nav-cont-logo" style={{ height: "50px" }}>
            <img
              src={logo}
              alt="logo"
              style={{
                height: "100%",
                // width: "100%",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="d-flex flex-row-reverse ">
            <IconButton
              onClick={handleOpenUserMenu}
              sx={{ p: 0 }}
              className="avatar-item"
            >
              <Avatar alt="Remy Sharp" src={user?.profileImg} />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {/* <MenuItem>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem> */}
              <MenuItem onClick={handleLogOut}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Cerrar sesión
              </MenuItem>
            </Menu>
            <div className="w-100 px-3" style={{ direction: "rtl" }}>
              <Typography variant="caption" color="whitesmoke">
                Administrador Ecuadorian
              </Typography>
              <Typography variant="body1" color="white">
                {user?.fullName}
              </Typography>
            </div>
            <div
              className="d-flex justify-content-center align-items-center mx-3"
              style={{ cursor: "pointer" }}
              onClick={handleOpenNotifications}
            >
              {countNotification > 0 ? (
                <Badge badgeContent={countNotification} color="primary">
                  <NotificationsActiveIcon
                    sx={{ color: "#fff" }}
                    fontSize="large"
                  />
                </Badge>
              ) : (
                <NotificationsIcon sx={{ color: "#fff" }} fontSize="large" />
              )}
            </div>
            <Fade in={isOpenNotifications}>
              <div
                className="container-notifications"
                style={{
                  zIndex: 5000,
                  position: "absolute",
                  width: "30rem",
                  height: loadingNotifications
                    ? "100px" || !isOpenNotifications
                    : notificationsList.length >= 4
                    ? `${100 * 4 - 50}px`
                    : notificationsList.length === 0
                    ? `${100}px`
                    : `${notificationsList.length * 100}px`,
                  top: "4rem",
                  background: "var(--primary-color)",
                  overflow: "auto",
                }}
              >
                {loadingNotifications ? (
                  <div className="w-100 d-flex justify-content-center align-items-center p-4">
                    <RotateSpinner size={50} color="white" loading={true} />
                  </div>
                ) : !loadingNotifications && notificationsList.length === 0 ? (
                  <div className="d-flex w-100 justify-content-center align-items-center h-100">
                    <Typography
                      variant="body1"
                      sx={{ color: "white" }}
                      className="bolder"
                    >
                      No hay notificaciones
                    </Typography>
                  </div>
                ) : (
                  notificationsList.map((el, index) => {
                    return (
                      <div key={index} className="">
                        <div
                          key={index}
                          className={`d-flex gap-3 notification-item ${
                            !el.viewed ? "notification-item-viewed" : ""
                          }`}
                          onClick={() => handleClickNotification(el)}
                        >
                          <div>
                            <Avatar
                              alt={el.sender?.fullname || "profile-img"}
                              src={el.sender?.profileImg}
                              sx={{ width: 56, height: 56 }}
                            />
                          </div>
                          <div
                            className="d-flex flex-column"
                            style={{ flexGrow: 1, position:'relative' }}
                          >
                            <Typography
                              variant="body1"
                              sx={{ color: "white" }}
                              className="bolder"
                            >
                              {el.title}
                            </Typography>
                            <Typography sx={{ color: "white" }} variant="body2">
                              {el.body}
                            </Typography>
                            <Typography sx={{ color: "white", position:'absolute', right: 0, top: 0 }} variant="caption">
                              {moment(el.createdAt).format('DD/MM/yyyy')}
                            </Typography>
                          </div>
                          <div>
                            {/* <Checkbox {...label} checked={!el.viewed} /> */}
                            {!el.viewed ? (
                              <div
                                style={{
                                  width: "10px",
                                  height: "10px",
                                  background: "blue",
                                  borderRadius: "50px",
                                }}
                              ></div>
                            ) : null}
                          </div>
                        </div>
                        <Divider />
                      </div>
                    );
                  })
                  // null
                )}
                {/* <div className="border border-danger" style={ { position: "sticky", bottom: 15, marginLeft: "24rem", height: '50px'}}>
               
              </div> */}
                <Fab
                  color="primary"
                  aria-label="clear-all"
                  sx={{ position: "sticky", bottom: 15, marginLeft: "24rem" }}
                  className=""
                  onClick={handleClickClearAll}
                >
                  <ClearAllIcon />
                </Fab>
              </div>
            </Fade>
          </div>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <MenuIcon
            className="navbar-toggler-icon"
            sx={{ color: "white" }}
            fontSize="large"
          />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <List className="list-menu-res">
            {items.map((el, index) => renderItem(el, index))}
          </List>

          {/* <form className="d-flex" role="search">
            <input
              className="form-control me-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
            />
            <button className="btn btn-outline-success" type="submit">
              Search
            </button>
          </form> */}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
