import React from "react";
import { Route, Routes } from "react-router-dom";
import Requests from "../pages/admin/Requests";
import SavedTours from "../pages/admin/SavedTours";
import Cars from "../pages/admin/Cars";
import Cities from "../pages/admin/Cities";
import Coordinators from "../pages/admin/Coordinators";
import DataBankParams from "../pages/admin/DataBankParams";
import NewPackage from "../pages/admin/NewPackage";
import Reviews from "../pages/admin/Reviews";
import Settings from "../pages/admin/Settings";
import Tariff from "../pages/admin/Tariff";
import Terms from "../pages/admin/Terms";
import Users from "../pages/admin/Users";
import MainLayout from "../components/shared/MainLayout";
import ViewPackage from "../pages/admin/ViewPackage";

const AdminRouter = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route path='requests' element={<Requests />} />
          <Route path="savedTours" element={<SavedTours />} />
          <Route path="savedTours/newPackage" element={<NewPackage />} />
          <Route path="users" element={<Users />} />
          <Route path="terms" element={<Terms />} />
          <Route path="settings" element={<Settings />} />
          <Route path="provinces" element={<Cities />} />
          <Route path="tariff" element={<Tariff />} />
          <Route path="coordinators" element={<Coordinators />} />
          <Route path="cars" element={<Cars />} />
          <Route path="dataBankParams" element={<DataBankParams />} />
          <Route path="reviews" element={<Reviews />} />
          
        </Route>
      </Routes>
    </div>
  );
};

export default AdminRouter;
