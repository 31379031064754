import React from "react";
import { helpHttp } from "../helpers/helpHttp";
import {
  IResDeleteDataBank,
  IResGetDataBankParams,
  IResPostPutDataBank,
} from "../interfaces/IDataBankParams";

export const useDataBankParams = () => {
  const getDataBanksUsers = async (): Promise<IResGetDataBankParams | null> => {
    try {
      const resp = await helpHttp().get("dataBank/getDataBanksAdmin");
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === "ERR_CANCELED") {
        return { ...error, aborted: true };
      } else {
        return null;
      }
    }
  };

  interface IPostPutData {
    id: number;
    type?: string;
    accountNumber?: string;
    nameBank?: string;
    identificationId?: string;
    nameProprietor?: string;
    email?: string;
    status?: string;
  }
  const postPutDataBankParam = async (
    data: IPostPutData
  ): Promise<IResPostPutDataBank | null> => {
    try {
      const resp = await helpHttp().post("dataBank/postPutDatabank", { data });
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === "ERR_CANCELED") {
        return { ...error, aborted: true };
      } else {
        return null;
      }
    }
  };

  const deleteBankParam = async (
    id: number
  ): Promise<IResDeleteDataBank | null> => {
    try {
      const resp = await helpHttp().del(`dataBank/deleteDatabank/${id}`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === "ERR_CANCELED") {
        return { ...error, aborted: true };
      } else {
        return null;
      }
    }
  };

  return { getDataBanksUsers, postPutDataBankParam, deleteBankParam };
};
