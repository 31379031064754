import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  createTheme,
  ThemeProvider,
  Button,
  IconButton,
  TextField,
  Collapse,
  Typography,
} from "@mui/material";
import React, {
  ChangeEvent,
  CSSProperties,
  EventHandler,
  StyleHTMLAttributes,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { PlacesContext } from "../../contexts";
import { ToastContext } from "../../contexts/toast/ToastContext";
import { getUserLocation } from "../../helpers/getUserLocation";
import MapView from "../common/MapView";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { MapContext } from "../../contexts/map/MapContext";
import { useForm } from "../../hooks/useForm";
import AutoCompleteMap from "../common/AutoCompleteMap";
import { ISavePackage, Route } from "../../interfaces/IPackages";
import { Map } from "../maps/Map";

interface Props {
  form: ISavePackage;
  route: { route: Route; pos: number } | null | undefined;
  onChange: (evt: any) => void;
  updateForm: (newData: ISavePackage) => void;
  open?: boolean;
  onClose?: any;
  onAccept?: any;
}

const themeBtnDft = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--color-dft-btn)",
          color: "ButtonText",
          ":hover": {
            background: "var(--color-dft-hover)",
          },
        },
      },
    },
  },
});

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          transform: "scale(1)",
          transition: "transform 0.05s",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
            transition: "transform 0.05s",
          },
        },
      },
    },
  },
});

const themeTxtFilled = createTheme({
  components: {
    // Name of the component
    MuiFilledInput: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          background: "#f6f6f6",
          color: "black",
          ":hover": {
            background: "#f6f6f6",
            color: "HighlightText",
            transition: "transform 0.05s",
          },
        },
        input: {
          background: "#f6f6f6",
          "::placeholder": {
            color: "#555",
          },
          color: "black",
        },
      },
    },
  },
});

const MapModal = ({
  form,
  route,
  onChange,
  updateForm,
  open,
  onAccept,
  onClose,
}: Props) => {
  const { setUserLocation, isLoading, userLocation, searchPlacesByTerm } =
    useContext(PlacesContext);
  const { map, isMapReady, placeSelected, setPlaceSelected } =
    useContext(MapContext);
  const { onOpenToast } = useContext(ToastContext);

  const [isOpen, setIsOpen] = useState(false);
  const {
    description,
    searchMap,
    descriptionEn,
    onChange: onChangeMap,
    updateForm: updateFormMap,
    form: formMap,
  } = useForm({
    searchMap: "",
    description: "",
    descriptionEn: "",
  });
  const [isWithFocus, setIsWithFocus] = useState(false);

  const debounceRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    getUserLocation()
      .then((resp) => setUserLocation(resp[0], resp[1]))
      .catch((err) => {
        // console.log(err);
        onOpenToast(
          "error",
          "Error de Ubicación",
          "No es posible obtener la ubicación "
        );
      });
  }, []);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
    if (route) {
      updateFormMap({
        ...formMap,
        description: route.route.description,
        descriptionEn: route.route.descriptionEn || "",
      });

      let routeDesc = route.route.address.split("-");
      let addressPlace = "";
      let namePlace = "";

      if (routeDesc && routeDesc.length > 0) {
        addressPlace = routeDesc[0].trim();
        namePlace = routeDesc[1].trim();
      } else {
        routeDesc = route.route.address.split(" ");
        if (routeDesc && routeDesc.length > 0)
          addressPlace = routeDesc[0].trim();
        namePlace = routeDesc[1].trim();
      }
      setPlaceSelected({
        latLng: [route.route.lng, route.route.lat],
        addressPlace,
        namePlace,
      });
    } else {
      updateFormMap({ description: "", descriptionEn: "", searchMap: "" });
    }
  }, [open]);

  const handleClose = async () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
    cleanData();
  };

  const cleanData = () => {
    updateFormMap({ description: "", searchMap: "", descriptionEn: "" });
    setPlaceSelected({ addressPlace: "", latLng: [], namePlace: "" });
  };

  const handleSaveData = () => {
    const validateDescription = () => {
      return new Promise((resolve, reject) => {
        if (description.trim().length > 0) {
          resolve(description);
        } else {
          reject("Debe ingresar una descripción de este punto en la ruta.");
        }
      });
    };

    const validateDescriptionEn = () => {
      return new Promise((resolve, reject) => {
        if (descriptionEn.trim().length > 0) {
          resolve(description);
        } else {
          reject(
            "Debe ingresar la traducción de la descripción de este punto en la ruta."
          );
        }
      });
    };

    const validateLocation = () => {
      return new Promise((resolve, reject) => {
        if (placeSelected.addressPlace.trim().length > 0) {
          if (placeSelected.namePlace.trim().length > 0) {
            if (placeSelected.latLng.length > 0) {
              resolve(placeSelected);
            } else {
              reject("No se ha seleccionado una ubicación.");
            }
          } else {
            reject("El nombre del lugar no puede estar vacío.");
          }
        } else {
          reject("Debe seleccionar una ubicación en el mapa.");
        }
      });
    };

    validateDescription()
      .then(() => validateDescriptionEn())
      .then(() => validateLocation())
      .then(() => {
        // console.log('placeSelected =>', placeSelected)
        const routeDescription: Route = {
          description: description.replace(/(\r\n|\n|\r)/gm, ""),
          address: `${placeSelected.namePlace} - ${placeSelected.addressPlace.replace(placeSelected.namePlace, '').replace(',', '')}`,
          lat: placeSelected.latLng[1],
          lng: placeSelected.latLng[0],
          descriptionEn: descriptionEn.replace(/(\r\n|\n|\r)/gm, ""),
        };
        if (route) {
          console.log('entra => ', route)
          updateForm({
            ...form,
            routes: form.routes.map((el, index) => {
              if (index === route.pos) {
                return routeDescription
              } 
              return el
            }),
          });
        } else {
          updateForm({
            ...form,
            routes: [...form.routes, routeDescription],
          });
        }
        if (onAccept) onAccept();
        handleClose();
      })
      .catch((err: string) => {
        onOpenToast("warn", "", err);
      });
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ zIndex: "4000 !important" }}
      maxWidth="lg"
    >
      <DialogTitle id="alert-dialog-title">
        {"Seleccionar Ubicación"}
      </DialogTitle>
      <DialogContent>
        <div className="flex-grow-1 mb-3" style={{ width: "50vw" }}>
          <div className="d-flex flex-row gap-3">
            <TextField
              value={description}
              name="description"
              variant="filled"
              label="Descripción de la ubicación"
              fullWidth
              multiline
              minRows={3}
              onChange={onChangeMap}
            />
            <TextField
              value={descriptionEn}
              name="descriptionEn"
              variant="filled"
              label="Traducción de la ubicación"
              fullWidth
              multiline
              minRows={3}
              onChange={onChangeMap}
            />
          </div>
        </div>
        <div
          className=" d-flex justify-content-center align-items-center"
          style={{ height: "16rem", width: "100%", position: "relative" }}
        >
          <Map
            location={
              route ? { lat: route.route.lng, lng: route.route.lat } : undefined
            }
            getLocation={ true}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={themeBtnDft}>
          <Button onClick={handleClose}>Cancelar</Button>
        </ThemeProvider>

        <ThemeProvider theme={themeBtnDftActive}>
          <Button onClick={handleSaveData} autoFocus>
            Aceptar
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};

export default MapModal;

const bottomButtons: CSSProperties = {
  zIndex: 1000,
  position: "absolute",
  right: 10,
  bottom: 0,
  margin: "auto",
  backgroundColor: "#f6f6f6",
  padding: 2,
  boxShadow: "",
};

const topElements: CSSProperties = {
  zIndex: 1000,
  position: "absolute",
  top: 10,
  left: 10,
  margin: "auto",
  backgroundColor: "#f6f6f6",
  // padding: 2,
  // boxShadow: "",
};
