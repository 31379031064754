import { helpHttp } from "../helpers/helpHttp";
import {
  IGetProvincesByRegion,
  IPostPutProvince,
  IResDeleteProvince,
  IResGetProvinces,
  IResPostPutProvince,
} from "../interfaces/IProvinces";

const ABORTED = "ERR_CANCELED";
export type TStatus = "rejected" | "accepted";

export default function useProvinces() {
  const getProvinces = async (): Promise<IResGetProvinces | null> => {
    try {
      const resp = await helpHttp().get(`provinces/getAllProvinces`);
      return resp;
    } catch (error: any) {
      // console.log("error ==>", error);
      if (error.code === ABORTED) {
        return { aborted: true };
      } else {
        return null;
      }
    }
  };

  const deleteProvinceById = async (
    id: number
  ): Promise<IResDeleteProvince | null> => {
    try {
      const resp = await helpHttp().del(`provinces/deleteProvince/${id}`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED) return { aborted: true };
      else return null;
    }
  };

  const postPutProvince = async (
    form: IPostPutProvince
  ): Promise<IResPostPutProvince | null> => {
    try {
      const resp = await helpHttp().post(`provinces/postPutProvince`, {
        data: form,
      });
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED) return { aborted: true };
      else return null;
    }
  };
  interface IUpdateStatus {
    idProvince: number;
    status: TStatus;
  }
  const updateStatusProvince = async (
    form: IUpdateStatus
  ): Promise<IResPostPutProvince | null> => {
    try {
      const resp = await helpHttp().post(`provinces/postPutProvince`, {
        data: form,
      });
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED) return { aborted: true };
      else return null;
    }
  };

  const getProvincesByRegion = async (
    id: number
  ): Promise<IGetProvincesByRegion | null> => {
    try {
      const resp = await helpHttp().get(
        `provinces/getProvincesByIdRegion/${id}`
      );
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED) return { aborted: true };
      else return null;
    }
  };

  return {
    getProvinces,
    deleteProvinceById,
    postPutProvince,
    updateStatusProvince,
    getProvincesByRegion,
  };
}
