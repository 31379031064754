import axios from "axios";
import ecuadorianApi, { baseURL } from "../api/ecuadorianApi";

interface IConfig {
  data?: any;
  signal?: any;
  method?: "POST" | "GET" | "PUT" | "DELETE";
  headers?: any;
}

export interface IRejectRequest {
  err?: boolean;
  status?: number | string;
  statusText?: string;
  messages?: string[];
  code?: string;
  endpoint?: string;
  
  aborted?: boolean;
  ok?:   boolean;
	msg?:  string;
  data?: any;
}

export interface IAbortedRequest {
  err?: boolean;
  status?: any;
  statusText?: string;
  messages?: any;
  code?: any;
  endpoint?: string;
  aborted?: boolean;
}

export interface IResolveRequest {
  status?: boolean;
  ok?: number;
  msg?: any;
  data?: any;
  aborted?: boolean;
}
// interface IController {
//   code: 'ERR_CANCELED',
//   message?: string;
//   name?: string;
// }

export const helpHttp = () => {
  const token = localStorage.getItem("token");

  const customAxios = (endpoint: string, options: IConfig) => {
    const defaultHeaders = {
      accept: "application/json",
      Authorization: token,
    };

    /* A way to abort a request. */
    const controller = new AbortController();
    options.signal = controller.signal;

    options.method = options.method || "GET";
    options.headers = options.headers
      ? { ...options.headers, ...defaultHeaders }
      : defaultHeaders;
    options.data = options.data || false;

    if (!options.data) delete options.data;

    /* Aborting the request after 3 seconds. */
    setTimeout(() => {
      controller.abort();
      // console.log("Enter abort controller");
    }, 10000);

    return axios(baseURL.concat(endpoint), options)
      .then((res) => {
        // console.log("🚀 ~ file: helpHttp.tsx ~ line 46 ~ .then ~ res", res)
        return {
          status: res.status,
          ok: res.data.ok,
          msg: res.data.msg,
          // user: res.data.user || null,
          data: res.data.data ? res.data.data : res.data ? res.data : null,
          // token: res.data.token || null,
          aborted: false,
        };
      })
      .catch((e) => {
        // console.log("🚀 ~ file: helpHttp.tsx ~ line 57 ~ customAxios ~ e", e);
        if (e.response) {
          return Promise.reject({
            err: true,
            status: e.response.status || "000",
            statusText: e.response.statusText || "** UNKNOWN ERROR **",
            messages: e.response.data
              ? e.response.data.errors
                ? e.response.data.errors || []
                : [e.response.data.msg]
              : ["empty errors"],
            code: e.code || "",
            endpoint: e.config.url || "Url invalid or malformed",
            aborted: false,
          });
        } else {
          // console.log('entra 4')
          return Promise.reject({
            err: false,
            messages: "Request failed with timeout",
            code: e.code || "",
            name: e.name || "",
            endpoint: baseURL.concat(endpoint),
            aborted: true,
          });
        }
      });
  };

  const get = (url: string, options: IConfig = { method: "GET" }) =>
    customAxios(url, options);

  const post = (url: string, options: IConfig = {}) => {
    options.method = "POST";
    return customAxios(url, options);
  };

  const put = (url: string, options: IConfig = {}) => {
    options.method = "PUT";
    return customAxios(url, options);
  };

  const del = (url: string, options: IConfig = {}) => {
    options.method = "DELETE";
    return customAxios(url, options);
  };

  return {
    get,
    post,
    put,
    del,
  };
};
