import React, { useEffect } from "react";
import "./App.css";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import AppRouter from "./router/AppRouter";
import { AuthProvider } from "./contexts/auth/AuthContext";
import { ToastProvider } from "./contexts/toast/ToastContext";
import CustomToast from "./components/common/CustomToast";
import ConfirmDialogProvider from "./contexts/confirmDialog/ConfirmDialogContext";
import ConfirmPopUpProvider from "./contexts/confirmPopUp/ConfirmPopUpContext";
import AlertProvider from "./contexts/alert/AlertContext";
import { PlacesProvider } from "./contexts";
import { MapProvider } from "./contexts/map/MapProvider";
import OneSignal from "react-onesignal";

function App() {

  return (
    <div id="body">
      <ConfirmDialogProvider>
        <AlertProvider>
          <ToastProvider>
            <CustomToast />
            <PlacesProvider>
              <MapProvider>
                <ConfirmPopUpProvider>
                  <AuthProvider>
                    <AppRouter />
                  </AuthProvider>
                </ConfirmPopUpProvider>
              </MapProvider>
            </PlacesProvider>
          </ToastProvider>
        </AlertProvider>
      </ConfirmDialogProvider>
    </div>
  );
}

export default App;
