import React, { createContext, useState } from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";

interface IAlertProps {
  severity?: "error" | "warning" | "info" | "success";
  message?: string;
  boldMessage?: string;
  icon?: any;
  linkableBoldMessage?: boolean;
  isFuncBoldMessage?: boolean;
  funcBoldMessage?: () => void;
}

type TAlertContextProps = {
  onOpenCustomAlert: ({
    message,
    boldMessage,
    linkableBoldMessage,
    icon,
    severity,
    isFuncBoldMessage,
    funcBoldMessage,
  }: IAlertProps) => void;

  onOpenAbortAlert: ({
    message,
    boldMessage,
    linkableBoldMessage,
    icon,
    severity,
    isFuncBoldMessage,
    funcBoldMessage,
  }: IAlertProps) => void;
};

export const AlertContext = createContext({} as TAlertContextProps);

const getColorText = (severity: "error" | "warning" | "info" | "success") => {
  switch (severity) {
    case "info":
      return "info.main";
    case "error":
      return "error.main";
    case "success":
      return "success.main";
    case "warning":
      return "warning.main";
    default:
      return "text.primary";
  }
};

export default function AlertProvider({ children }: any) {
  const [open, setOpen] = useState(false);
  const [dataAlert, setDataAlert] = useState<IAlertProps>({
    severity: "info",
    message: "",
    boldMessage: "",
    linkableBoldMessage: false,
    icon: <></>,
    funcBoldMessage: () => {},
    isFuncBoldMessage: false,
  });

  const onOpenCustomAlert = ({
    message,
    boldMessage,
    linkableBoldMessage,
    icon,
    severity,
    isFuncBoldMessage = false,
    funcBoldMessage = () => {},
  }: IAlertProps) => {
    setOpen(true);
    setDataAlert({
      severity: severity ? severity : dataAlert.severity,
      message,
      boldMessage: boldMessage ? boldMessage : dataAlert.boldMessage,
      icon: icon ? icon : dataAlert.icon,
      linkableBoldMessage: linkableBoldMessage ? linkableBoldMessage : false,
      funcBoldMessage: funcBoldMessage,
      isFuncBoldMessage: isFuncBoldMessage,
    });
  };

  const onOpenAbortAlert = ({
    message,
    boldMessage,
    linkableBoldMessage,
    icon,
    severity,
  }: IAlertProps) => {
    setOpen(true);
    setDataAlert({
      ...dataAlert,
      message: message ? message : "Tiempo de espera agotado",
      boldMessage: boldMessage ? boldMessage : "Recargue la página",
      icon: icon ? icon : dataAlert.icon,
      linkableBoldMessage: linkableBoldMessage ? linkableBoldMessage : true,
      severity: severity ? severity : "error",
    });
  };

  const onCloseAlert = () => {
    setOpen(false);
    setDataAlert({
      severity: "info",
      message: "",
      boldMessage: "",
      linkableBoldMessage: false,
      icon: <></>,
      funcBoldMessage: () => {},
      isFuncBoldMessage: false,
    });
  };

  return (
    <AlertContext.Provider value={{ onOpenCustomAlert, onOpenAbortAlert }}>
      <Box sx={{ width: "100%" }}>
        <Collapse in={open}>
          <Alert
            severity={dataAlert.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => onCloseAlert()}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            sx={{ mb: 2 }}
          >
            {dataAlert.message} {dataAlert.boldMessage!.length > 0 ? " - " : ""}{" "}
            {dataAlert.linkableBoldMessage ? (
              <Link
                component="button"
                variant="body2"
                color={getColorText(dataAlert.severity!)}
                onClick={() => {
                  window.location.reload();
                }}
              >
                <strong>{dataAlert.boldMessage}</strong>
              </Link>
            ) : (
              <>
                {dataAlert.isFuncBoldMessage ? (
                  <Link
                    component="button"
                    variant="body2"
                    color={getColorText(dataAlert.severity!)}
                    onClick={() => {
                      if (dataAlert.funcBoldMessage) {
                        dataAlert.funcBoldMessage();
                      }
                    }}
                  >
                    <strong>{dataAlert.boldMessage}</strong>
                  </Link>
                ) : (
                  <strong>{dataAlert.boldMessage}</strong>
                )}
              </>
            )}
          </Alert>
        </Collapse>
      </Box>
      {children}
    </AlertContext.Provider>
  );
}
