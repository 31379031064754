import React, { useContext, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { RotateSpinner } from "react-spinners-kit";
import { COLOR_LOADING } from "../../helpers/consants";
// import pageNotFound from "../../assets/page-no-found.png";
import pageNotFound from "../../assets/page-not-found1.webp";
import noImage from "../../assets/no-image.png";
import emptyData from "../../assets/empty-data.png";
import { helpHttp } from "../../helpers/helpHttp";
import axios from "axios";
import SaveFile from "../../helpers/saveFile";
import { ToastContext } from "../../contexts/toast/ToastContext";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  openModal: boolean;
  setFunc: () => void;
  url: string;
  msg?: string;
}

export default function ModalWatchImage({
  openModal,
  setFunc,
  url,
  msg,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const [isValidImg, setIsValidImg] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { handleDownload } = SaveFile();

  const { onOpenToast } = useContext(ToastContext);

  const evaluateUrl = async () => {
    setIsLoading(true);
    axios
      .get(url)
      .then((res) => {
        // console.log("es valido");
        setIsValidImg(true);
        setIsLoading(false);
      })
      .catch((err) => {
        // console.log("es invalido");
        setIsValidImg(true);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (openModal) {
      setOpen(openModal);
      setIsLoading(false);
      setIsValidImg(false);
      evaluateUrl();
      // evaluateURL();
    }
  }, [openModal]);

  // useEffect(() => {
  //   evaluateUrl();
  // }, []);

  const handleClose = () => {
    setOpen(false);
    setFunc();
  };

  const handleDownloadImg = async () => {
    if (isValidImg) {
      await handleDownload(url);
    } else {
      onOpenToast("error", "", "Imagen inválida");
    }
  };

  return (
    <div>
      <Dialog
        // fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        className=""
        maxWidth="lg"
        sx={{
          "&.MuiDialog-root": {zIndex: 5000},
        }}
      >
        <AppBar
          sx={{ position: "relative", background: "var(--primary-color)" }}
        >
          <Toolbar
            className="d-flex justify-content-between"
            // sx={{ background: "var(--primary-color)" }}
          >
            {/* <div className="w-100 border border-danger">
            </div> */}
            <IconButton
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            {/* <Button autoFocus color="inherit" onClick={handleDownloadImg}>
              Guardar
            </Button> */}
          </Toolbar>
        </AppBar>
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center py-5 w-100 h-100">
            <RotateSpinner size={100} color={COLOR_LOADING} loading={true} />
          </div>
        ) : null}
        <img
          hidden={isValidImg ? false : true}
          src={url}
          className="img-fluid"
          alt="img-description..."
          // onLoad={() => {
          //   // setIsLoading(true);
          //   handleLoadImage();
          //   // setIsLoading(false);
          // }}
        />
        {isValidImg ? null : !isValidImg && isLoading ? null : (
          <div
            className="d-flex justify-content-center align-items-center w-100 h-100 flex-column"
            // hidden={isValidImg ? true : false}
          >
            <img
              src={emptyData}
              className="img-fluid"
              alt="page-not-fund..."
              // style={{width: '100%'}}
            />

            {msg ? (
              <Typography className="pb-5 px-5 fw-bolder">{msg}</Typography>
            ) : null}
          </div>
        )}
      </Dialog>
    </div>
  );
}
