import {
  Typography,
  Button,
  Divider,
  createTheme,
  alpha,
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  ThemeProvider,
  TextField,
  Grow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BodyLayout from "../../components/shared/BodyLayout";
import { visuallyHidden } from "@mui/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { ToastContext } from "../../contexts/toast/ToastContext";
import useProvinces, { TStatus } from "../../hooks/useProvinces";
import { TransitionGroup } from "react-transition-group";
import { BKG_TABLE_HEADER, COLOR_LOADING, UNHANDLED_ERROR } from "../../helpers/consants";
import { ConfirmPopUpContext } from "../../contexts/confirmPopUp/ConfirmPopUpContext";
import useRegions from "../../hooks/useRegions";
import { IPostPutProvince, IResPostPutProvince } from "../../interfaces/IProvinces";
import { RotateSpinner } from "react-spinners-kit";

const label = { inputProps: { "aria-label": "Switch demo" } };

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          transform: "scale(1)",
          transition: "transform 0.05s",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
            // transform: "scale(1.03)",
            transition: "transform 0.05s",
          },
        },
      },
    },
  },
});

const themeBtnDft = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--color-dft-btn)",
          color: "ButtonText",
          ":hover": {
            background: "var(--color-dft-hover)",
          },
        },
      },
    },
  },
});

const themeLblHeadTbl = createTheme({
  components: {
    // Name of the component
    MuiTableSortLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // textAlign: 'center',
        },
        icon: {
          // display: "none",
          // ":hover": {
          //   display: "none",
          // },
        },
      },
    },
  },
});

interface Data {
  no: number;
  nameCat: string;
  transCat: string;
  actions: any;
  status: string;
  regionId: number;
}

interface IRegions {
  id: number;
  value: string | number;
  label: string;
}

const initForm: IPostPutProvince = {
  idProvince: 0,
  nameProvince: "",
  nameProvinceEn: "",
  regionId: 0,
  status: "accepted",
};

const Cities = () => {
  const { onOpenAbortAlert } = useContext(AlertContext);
  const { onOpenToast } = useContext(ToastContext);
  const { onOpenPopUp } = useContext(ConfirmPopUpContext);

  const { getProvinces, deleteProvinceById, postPutProvince, updateStatusProvince } = useProvinces();
  const { getAllRegions } = useRegions();

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("no");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const [isFetching, setIsFetching] = useState(false);
  const [isNew, setIsNew] = useState(true);
  const [rowToEdit, setRowToEdit] = useState(0);
  const [regionSelected, setRegionSelected] = useState("");
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [updateStatusProm, setUpdateStatusProm] = useState<Promise<IResPostPutProvince | null>[]>([])
  const [provincesList, setProvincesList] = useState<Data[]>([]);
  const [regionsList, setRegionsList] = useState<IRegions[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = provincesList.map((n) => n.nameCat);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number
    ) => void;
  }

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - provincesList.length) : 0;

  const { form, onChange, updateForm } = useForm<IPostPutProvince>(initForm);
  const { form: formPivot, updateForm: updateFormPivot } =
    useForm<IPostPutProvince>({...initForm, idProvince: -1});

  const handleStatusProvince = async (id: number, status: TStatus) => {
    // console.log(status);

    // const resp = await updateStatusProvince({ idProvince: id, status });
    setUpdateStatusProm([...updateStatusProm, updateStatusProvince({ idProvince: id, status })]);
    // if (resp) {
    //   if (resp.aborted) {
    //     onOpenAbortAlert({});
    //     return;
    //   }

    // } else {
    //   onOpenToast('error', '', TIMEOUT_ERROR);
    // }
    }
  
  const handleWatchProvince = (id: number) => {
    const section = document.querySelector( '#navbar-pivot' );
    section!.scrollIntoView();
    handleUpdateProvince(id);
    setIsReadOnly(true);
}
  
  const handleChange = (event: SelectChangeEvent) => {
    setRegionSelected(event.target.value as string);
    updateForm({ ...form, [event.target.name]: parseInt(event.target.value) });
  };

  const handleUpdateProvince = (id: number) => {
    const section = document.querySelector( '#navbar-pivot' );
    section!.scrollIntoView();
    setIsReadOnly(false);
    const row: Data | undefined = provincesList.find((el) => el.no === id);
    if (row) {
      setRegionSelected(row.regionId.toString());
      setIsNew(false);
      setRowToEdit(id);
      updateForm({
        idProvince: row.no,
        nameProvince: row.nameCat,
        status: row.status,
        nameProvinceEn: row.transCat,
        regionId: row.regionId,
      });
      updateFormPivot({
        idProvince: row.no,
        nameProvince: row.nameCat,
        status: row.status,
        nameProvinceEn: row.transCat,
        regionId: row.regionId,
      });
    }
  };

  const clean = () => {
    updateForm(initForm);
    setIsNew(true);
    setRegionSelected("");
    setRowToEdit(0);
    setIsReadOnly(false);
    updateFormPivot({...initForm, idProvince: -1})
  };

  const handleSaveProvince = async () => {
    if (JSON.stringify(form) === JSON.stringify(formPivot)) {
      clean();
      return;
    }
    if (parseInt(regionSelected) > 0) {
      if (form.regionId > 0) {
        if (form.nameProvince.trim().length > 0) {
          if (form.nameProvinceEn.trim().length > 0) {
            const resp = await postPutProvince(form);
            if (resp) {
              if (resp.aborted) {
                onOpenAbortAlert({});
                return;
              }
              if (resp.data) {
                const aux: Data = {
                  actions: 0,
                  nameCat: resp.data.nameProvince,
                  transCat: resp.data.nameProvinceEn,
                  no: resp.data.id,
                  status: resp.data.status,
                  regionId: resp.data.regionId,
                };

                if (isNew && form.idProvince === 0) {
                  setProvincesList([...provincesList, aux]);
                  onOpenToast("success", "Guardado", 'Nueva provincia creada.' );
                } else {
                  setProvincesList(
                    provincesList.map((el) => (el.no === rowToEdit ? aux : el))
                    );
                    onOpenToast("success", "Guardado", 'Provincia actualizada.' );
                }
                clean();
              }
            } else {
              onOpenToast("error", "", UNHANDLED_ERROR);
            }
          } else {
            onOpenToast("warn", "", "Ingrese la traducción de la categoría");
          }
        } else {
          onOpenToast("warn", "", "Ingrese el nombre de la categorpía");
        }
      } else {
        onOpenToast("warn", "", "Seleccione una región");
      }
    } else {
      onOpenToast("warn", "", "Debe seleccionar una región");
    }
  };

  const handleDeleteProvince = async (id: number) => {
    onOpenPopUp(
      async () => {
        const resp = await deleteProvinceById(id);
        if (resp) {
          if (resp.aborted) {
            onOpenAbortAlert({});
            return;
          }
          const filteredProvinces = provincesList.filter((el) => el.no !== id);
          setProvincesList(filteredProvinces);
        } else {
          onOpenToast("error", "", UNHANDLED_ERROR);
        }
      },
      "¿Está seguro que desea proceder?",
      `btn-delete-${id}`
    );
  };

  const getData = async () => {
    setIsFetching(true);
    const respProvinces = await getProvinces();
    if (respProvinces) {
      if (respProvinces.aborted) {
        onOpenAbortAlert({
          message: "Tiempo de espera agotado al recuperar las provincias",
        });
        return;
      }
      const auxProvinces: Data[] =
        respProvinces.data?.rows
          .map((el) => {
            return {
              no: el.id,
              nameCat: el.nameProvince,
              transCat: el.nameProvinceEn,
              actions: 0,
              status: el.status,
              regionId: el.regionId,
            };
          })
          .filter((el) => el !== undefined) || [];
      setProvincesList(auxProvinces);
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
    setIsFetching(false)
    const resp = await getAllRegions();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({
          message: "Tiempo de espera agotado al recuperar las Regiones.",
        });
        return;
      }
      const aux: IRegions[] =
        resp.data
          ?.map((el) => {
            return {
              id: el.id,
              value: el.id,
              label: el.nameRegion,
            };
          })
          .filter((el) => el !== undefined) || [];
      setRegionsList(aux);
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
   // setIsFetching(false)
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (JSON.stringify(form) !== JSON.stringify(formPivot)) {
      setIsNew(true);
    } else {
      setIsNew(false);
    }
  }, [form]);

  useEffect(() => {
    if (updateStatusProm.length > 0) {
      Promise.all(updateStatusProm).then(resp => {
        resp.forEach((res, index) => {
          if (res) {
            if (res.aborted) {
              onOpenAbortAlert({});
              return;
            }
            // console.log(res?.data || null);
            const newRows:Data[] = provincesList.map(el => {
              if (res.data) {
                if (el.no === res.data.id) {
                  return {
                    ...el,
                    status: res.data.status,
                  }
                }
              }
              return el;
            })
            setProvincesList(newRows);
          } else {
            onOpenToast('error', '', UNHANDLED_ERROR);
            return;
          }
          const aux = updateStatusProm.filter((value, pos) => pos !== index);
          setUpdateStatusProm(aux);
         })
      })
    }
  }, [updateStatusProm])
  

  return (
    <BodyLayout>
      <div className="d-flex flex-wrap gap-4 flex-column">
        <div className="d-flex align-items-center ">
          <Typography variant="h5" className="flex-grow-1 fw-bold">
            Provincias
          </Typography>
        </div>
        <div className="flex-grow-1 d-flex justify-content-center flex-column gap-4">
          <div className="flex-grow-1" style={{ maxWidth: "35%" }}>
            <FormControl sx={{}} className="w-100">
              <InputLabel id="demo-select-small">Región</InputLabel>
              <Select
                disabled={ isReadOnly}
                labelId="demo-select-small"
                id="demo-select-small"
                value={regionSelected}
                defaultValue={regionSelected}
                label="Región"
                name="regionId"
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value="0">
                  <em>Ninguno</em>
                </MenuItem>
                {regionsList.map((el, index) => (
                  <MenuItem value={el.value} key={index}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="flex-grow-1 d-flex align-items-center gap-5">
            <div className="flex-grow-1 d-flex gap-3">
              <TextField
                disabled={isReadOnly}
                hiddenLabel
                value={form.nameProvince}
                // defaultValue={form.nameProvince}
                name="nameProvince"
                variant="filled"
                fullWidth
                onChange={onChange}
                placeholder="Escribe el nombre de la categoría"
              />
              <TextField
                disabled={isReadOnly}
                hiddenLabel
                // defaultValue={form.nameProvinceEn}
                value={form.nameProvinceEn}
                name="nameProvinceEn"
                variant="filled"
                fullWidth
                onChange={onChange}
                placeholder="Nombre de la categoría en inglés"
              />
            </div>

            <div>
              <ThemeProvider theme={isNew ? themeBtnDftActive : themeBtnDft}>
                <Button variant="contained" onClick={handleSaveProvince}>
                {form.idProvince === 0 && isNew
                  ? "Agregar"
                  : form.idProvince !== 0 && isNew
                  ? "Actualizar"
                  : "Limpiar"}
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
      <Divider sx={{ margin: "2rem", borderColor: "var(--primary-color)" }} />
      <Box sx={{ width: "100%", maxHeight: "75vh" }}>
        <Paper sx={{ width: "80%", mb: 2 }} className="mx-auto">
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer sx={{ maxHeight: "65vh", maxWidth: "100%" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={provincesList.length}
              />
              <TableBody>
                <TransitionGroup component={null}>
                  {stableSort(provincesList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <Grow key={index}>
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.name)}
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="left">{row.nameCat}</TableCell>
                            <TableCell align="left">{row.transCat}</TableCell>
                            <TableCell align="center">
                              <div className="d-flex gap-1 justify-content-center">
                                <IconButton
                                  onClick={() => handleDeleteProvince(row.no)}
                                  id={`btn-delete-${row.no}`}
                                >
                                  <DeleteIcon sx={{ width: 20, height: 20 }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => handleUpdateProvince(row.no)}
                                >
                                  <BorderColorIcon
                                    sx={{ width: 20, height: 20 }}
                                  />
                                </IconButton>
                                <IconButton onClick={() => handleWatchProvince(row.no)}>
                                  <RemoveRedEyeIcon
                                    sx={{ width: 20, height: 20 }}
                                  />
                                </IconButton>
                                <Switch
                                  {...label}
                                  checked={
                                    row.status === "accepted" ? true : false
                                  }
                                  onClick={() => handleStatusProvince(row.no, row.status === 'accepted' ? 'rejected' : 'accepted')}
                                  inputProps={{ 'aria-label': row.status === "accepted" ? 'uncontrolled' : 'controlled' }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        </Grow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 60 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TransitionGroup>
              </TableBody>
            </Table>
            {isFetching && (
              <div className="w-100 d-flex justify-content-center mt-5 mb-2">
                <RotateSpinner
                  size={70}
                  color={COLOR_LOADING}
                  loading={isFetching}
                />
              </div>
            )}
          </TableContainer>
          <div className="d-flex justify-content-center w-100 p-4">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Todo", value: -1 }]}
              colSpan={3}
              count={provincesList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Mostrar"
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              className=""
            />
          </div>
        </Paper>
      </Box>
    </BodyLayout>
  );
};

export default Cities;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "no",
    numeric: true,
    disablePadding: false,
    label: "No",
  },
  {
    id: "nameCat",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "transCat",
    numeric: false,
    disablePadding: false,
    label: "Traducción",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: false,
    label: "Acciones",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow
        sx={{
          "& th": {
            backgroundColor: BKG_TABLE_HEADER,
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.id === "actions" ? "center" : "justify"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <ThemeProvider theme={themeLblHeadTbl}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === "actions" ? null : (
                  <>
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </>
                )}
              </TableSortLabel>
            </ThemeProvider>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
