import { ABORTED_REQUEST } from "../helpers/consants";
import { helpHttp } from "../helpers/helpHttp";
import {
  IGetDataUsers,
  IResGetDriverGuides,
  IResGetDriversTransport,
  IResGetUsers,
  IResPutUserStatus,
} from "../interfaces/IUsers";

interface paginationReq {
  from: number;
  to: number;
  typeReq: "all" | "clients" | "companies" | "guides" | "drivers" | "delivery";
}

const useUsers = () => {
  const getUsersAdmin = async ({
    from,
    to,
    typeReq,
  }: paginationReq): Promise<
    IGetDataUsers | { aborted: boolean; count: number; rows: [] } | null
  > => {
    try {
      const resp = (
        await helpHttp().get(`user/getUserForAdmin/${typeReq}/${from}/${to}`)
      ).data;
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === "ERR_CANCELED") {
        return { aborted: true, count: 0, rows: [] };
      } else {
        return null;
      }
    }
  };

  interface IPutUserStatus {
    idUser: number;
    status: string;
  }
  const putUserStatus = async ({
    idUser,
    status,
  }: IPutUserStatus): Promise<IResPutUserStatus | null> => {
    try {
      const resp = await helpHttp().put(`user/putUserStatusByIdUser`, {
        data: { idUser, status },
      });
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === "ERR_CANCELED") {
        return { aborted: true, ok: false, msg: "", data: null };
      } else {
        return null;
      }
    }
  };

  const getDriversGuide = async (): Promise<IResGetDriverGuides | null> => {
    try {
      const resp = await helpHttp().get(`user/getUsersGuides`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };
  
  const getDriversTransport = async (): Promise<IResGetDriversTransport | null> => {
    try {
      const resp = await helpHttp().get(`user/getUsersTransport`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };

  return {
    getUsersAdmin,
    putUserStatus,
    getDriversGuide,
    getDriversTransport
  };
};

export default useUsers;
