import { helpHttp } from "../helpers/helpHttp";
import { IResGetRegions } from "../interfaces/IRegions";

const ABORTED = "ERR_CANCELED";

export default function useRegions() {

  const getAllRegions = async (): Promise<IResGetRegions | null> => {
    try {
      const resp = await helpHttp().get(`regions/getRegions`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED) {
        return { aborted: true };
      } else {
        return null;
      }
    }
	};
	
	return {
		getAllRegions
	}
}
