import React, { useEffect, useContext, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import AdminRouter from "./AdminRouter";
import PublicAccess from "./PublicAccess";
import PublicRoutes from "./PublicRoutes";
import OneSignal from "react-onesignal";
import { AuthContext } from "../contexts/auth/AuthContext";
import SignIn from "../pages/auth/Signin";
import { initializeApp, getApp } from "firebase/app";
import * as firebase from "firebase/compat/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyAG4BqjfSQEgtHznNbCLtVY94Kw7cxB5WE",
//   authDomain: "ecuadorian-web.firebaseapp.com",
//   projectId: "ecuadorian-web",
//   storageBucket: "ecuadorian-web.appspot.com",
//   messagingSenderId: "197952467450",
//   appId: "1:197952467450:web:f35c85fb8956a8abc4f443",
//   measurementId: "G-R74VSJRKXJ",
// };

// const keyPar =
//   "BNt9j_l3y7m3YrxJa4ghJ-7x2Qi4RDm3vLsr_B6RL7iI53mDWWF47Y6chzMTptRvUjPYIs_egJaveyWTcmzzQ2E";

// const app = initializeApp(firebaseConfig);
// const messaging = firebase.default.messaging();
// const messaging = getMessaging(app); //! esto le daña la app
// // const messaging = firebase.messaging();

// getToken(messaging, { vapidKey: keyPar })
// .then((currentToken) => {
//   if (currentToken) {
//     console.log(
//       "Send the token to your server and update the UI if necessary"
//     );
//     console.log("Current token => ", currentToken);
//     setExternalUserId(currentToken);
//     // Send the token to your server and update the UI if necessary
//     // ...
//   } else {
//     // Show permission request UI
//     console.log(
//       "No registration token available. Request permission to generate one."
//     );
//     // ...
//   }
// })
// .catch((err) => {
//   console.log("An error occurred while retrieving token. ", err);
//   // ...
// });

//! ONE SIGNAL
// useEffect(() => {
//   console.log("entra efecto con status => ", status);
//   if (status === "authenticated") {
//     OneSignal.init({
//       // appId: '054a62ca-5132-4a64-b07f-0d90a726cb30',
//       appId: "054a62ca-5132-4a64-b07f-0d90a726cb30",
//       autoResubscribe: true,
//     })
//       .then(() => {
//         console.log("se suscribio");
//         OneSignal.setExternalUserId(`admin`);
//       })
//       .catch(() => {
//         console.log("no se suscribio");
//       });

//     OneSignal.on("subscriptionChange", () => {
//       console.log("The user's subscription state is now ");
//     });
//   }
// }, [status]);

// function requestPermission() {
//   console.log("Requesting permission...");
//   Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {
//       console.log("Notification permission granted.");
//     }
//   });
// }



export default function App() {
  const { status } = useContext(AuthContext);
  const [externalUserId, setExternalUserId] = useState<string>("");

  // useEffect(() => {
  //   console.log("entra efecto con status => ", status);
  //   if (status === "authenticated") {
  //     OneSignal.init({
  //       // appId: '054a62ca-5132-4a64-b07f-0d90a726cb30',
  //       appId: "054a62ca-5132-4a64-b07f-0d90a726cb30",
  //       autoResubscribe: true,
  //     })
  //       .then(() => {
  //         console.log("se suscribio");
  //         OneSignal.setExternalUserId(`admin`);
  //         // await getCurrentExternalUserID()
  //       })
  //       .catch(() => {
  //         console.log("no se suscribio");
  //       });

  //     OneSignal.on("subscriptionChange", () => {
  //       console.log("The user's subscription state is now ");
  //     });
  //   }
  //   // else if (status === "not-authenticated") {
  //   //   OneSignal.removeExternalUserId()
  //   //     .then((resp) => {
  //   //       console.log("external user id removido");
  //   //       // await getCurrentExternalUserID()
  //   //     })
  //   //     .catch((e) => {
  //   //       console.log("error al remover el external user id");
  //   //     });
  //   // }
  //   // OneSignal.getExternalUserId().then(function(externalUserId){
  //   //   console.log('current external user id ==> ', externalUserId)
  //   // });
  // }, [status]);

  const getCurrentExternalUserID = async() => {
    OneSignal.push(function() {
      OneSignal.getExternalUserId().then(function(externalUserId){
        console.log("externalUserId: ", externalUserId);
      });
    });
  }

  return (
    <div>
      {/* <p>{externalUserId}</p> */}
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <SignIn />
            </PublicRoute>
          }
        />
        <Route
          path="signIn/*"
          element={
            <PublicRoute>
              <PublicRoutes />
            </PublicRoute>
          }
        />

        <Route
          path="account/*"
          element={
            <PublicAccessRoute>
              <PublicAccess />
            </PublicAccessRoute>
          }
        />

        <Route
          path="admin/*"
          element={
            <RequireAuth>
              <AdminRouter />
            </RequireAuth>
          }
        ></Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's no match private URL!</p>
            </main>
          }
        />
      </Routes>
    </div>
  );
}

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  // console.log("REQUIRE LOGIN");
  const user = localStorage.getItem("user");
  const location = useLocation();

  if (!user) {
    return <Navigate to="/signIn" state={{ from: location }} replace />;
  }
  return children;
};

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  // console.log("PUBLIC ROUTES");
  const user = localStorage.getItem("user");
  return user ? <Navigate to="/admin/requests" /> : children;
};

const PublicAccessRoute = ({ children }: { children: JSX.Element }) => {
  // console.log("PUBLIC ACCESS ROUTES");
  return children;
};
