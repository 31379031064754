import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from "react";
import searchApi from "../../api/searchApi";
import { getUserLocation } from "../../helpers/getUserLocation";
import { Feature, IPlacesResponse } from "../../interfaces/IMap";
import { ToastContext } from "../toast/ToastContext";
import { PlacesContext } from "./PlacesContext";
import { placesReducer } from "./placesReducer";

export interface PlacesState {
  isLoading: boolean;
  userLocation?: [number, number];
  isLoadingPlaces: boolean;
  places: Feature[];
}

const INITIAL_STATE: PlacesState = {
  isLoading: true,
  userLocation: undefined,
  isLoadingPlaces: false,
  places: [],
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const PlacesProvider = ({ children }: Props) => {
  const { onOpenToast } = useContext(ToastContext);

  const [state, dispatch] = useReducer(placesReducer, INITIAL_STATE);

  useEffect(() => {
    getUserLocation()
      .then((resp) => dispatch({ type: "setUserLocation", payload: resp }))
      .catch((err) => {
        // console.log(err);
        onOpenToast(
          "error",
          "Error de Ubicación",
          "No fue posible obtener la ubicación"
        );
      });
  }, []);

  const setUserLocation = (lng: number, lat: number) => {
    dispatch({ type: "setUserLocation", payload: [lng, lat] });
  };

  const searchPlacesByTerm = async (query: string): Promise<Feature[]> => {
    if (query.length === 0) return state.places.length > 0 ? state.places : [];
    if (!state.userLocation) {
      onOpenToast(
        "warn",
        "Error de Ubicación",
        "No existe la ubicación del usuario."
      );
      return state.places.length > 0 ? state.places : [];
    }

    dispatch({ type: "setLoadingPlaces" });

    // const resp = await searchApi.get<IPlacesResponse>(`/${query}.json`, {
    //   params: {
    //     proximity: state.userLocation.join(","),
    //   },
    // });

    // dispatch({ type: "setPlaces", payload: resp.data.features });

    return [];
  };

  return (
    <PlacesContext.Provider
      value={{
        ...state,
        setUserLocation,
        searchPlacesByTerm,
      }}
    >
      {children}
    </PlacesContext.Provider>
  );
};
