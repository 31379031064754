import { IUserAuth } from "../../interfaces/ISignIn";

export interface AuthState {
  status: "checking" | "authenticated" | "not-authenticated";
  token: string | null;
  errorMessage: string;
  user: IUserAuth | null;
  isLoading: boolean;
}
type AuthAction =
  | { type: "signUp"; payload: { token: string; user: IUserAuth } }
  | { type: "addError"; payload: string }
  | { type: "updateUser"; payload: { user: IUserAuth } }
  | { type: "removeError" }
  | { type: "notAuthenticated" }
  | { type: "logout" }
  | { type: "activeLoading" }
  | { type: "removeLoading" }


export const authReducer = (
  state: AuthState,
  action: AuthAction
): AuthState => {
  switch (action.type) {
    case "addError":
      return {
        ...state,
        user: null,
        status: "not-authenticated",
        token: null,
        errorMessage: action.payload,
      };
    case "updateUser":
      return {
        ...state,
        user: action.payload.user,
      };
    case "removeError":
      return {
        ...state,
        errorMessage: "",
      };
    case "signUp":
      return {
        ...state,
        errorMessage: "",
        status: "authenticated",
        token: action.payload.token,
        user: action.payload.user,
      };

    case "logout":
      return {
        ...state,
        token: null,
        user: null,
        status: 'not-authenticated',
      };
    
    case "notAuthenticated":
      return {
        ...state,
        status: "not-authenticated",
        token: null,
        user: null,
      };

    case "activeLoading":
      return {
        ...state,
        isLoading: true,
      };
    case "removeLoading":
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};
