import { ABORTED_REQUEST } from "../helpers/consants";
import { helpHttp } from "../helpers/helpHttp";
import { IResPostReport } from "../interfaces/IReports";
import { TRequests } from "../pages/admin/Requests";

export default function useReports() {
  interface IReport {
    idPayment: number;
    month: number;
    year: number;
  }

  const getReport = async (
    data: IReport,
    type: TRequests
	): Promise<IResPostReport | null> => {
		
		// console.log('data ==>', data)
    try {
      const resp = await helpHttp().post(`reports/report/${type}`, { data });
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };

  return {
    getReport,
  };
}
