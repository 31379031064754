import {
  Alert,
  Button,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { useAuth } from "../../hooks/useAuth";
import logo from "../../assets/logo.png";
import { useForm } from "../../hooks/useForm";
import { ToastContext } from "../../contexts/toast/ToastContext";

const themeBtnActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          background: "var(--primary-color)",
          color: "HighlightText",
        },
      },
    },
  },
});

const ResetPasswordAdmin = () => {
  const { onOpenToast } = useContext(ToastContext);
  const { onOpenAbortAlert, onOpenCustomAlert } = useContext(AlertContext);

  const { sendEmailResetPass } = useAuth();
  const navigate = useNavigate();

  const { email, onChange, updateForm } = useForm({
    email: "",
  });

  const changePassword = async () => {
    if (email.length > 0) {
      const resp = await sendEmailResetPass({
        email,
      });
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.ok) {
        // onOpenToast(
        //   "success",
        //   "Confirmación",
        //   "La contraseña ha sido restablecida"
        // );
        updateForm({ email: "" });
        onOpenCustomAlert({
          message:
            "Revise el enlace que enviamos a su correo para restrablecer su contraseña. Puedes cerrar esta página",
          // boldMessage: "Cerrar página",
          // isFuncBoldMessage: true,
          // funcBoldMessage: () => {
          //   window.self.close();
          //   // window.close();
          // },
          severity: "success",
        });
      } else {
        onOpenToast(
          "error",
          "Error",
          resp.msg || "Ocurrió un error, inténtalo de nuevo o mas tarde"
        );
      }
    } else {
      onOpenToast("error", "Error", "Ingrese su correo electrónico.");
    }
  };

  const handleClickBack = () => {
    navigate("/");
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="container">
        <div className="row col-md-5 mx-auto">
          <div className="card border border-0">
            <div className="card-header border-0">
              <div className="col-md-5 mx-auto">
                <img alt="logo.png" src={logo} style={{ width: "100%" }} />
              </div>
              <Typography
                variant="h5"
                color="initial"
                sx={{ textAlign: "center" }}
              >
                Cambiar contraseña
              </Typography>
            </div>
            <div className="card-body">
              <div>
                <TextField
                  // type="password"
                  label="Ingresa tu correo electrónico"
                  name="email"
                  value={email}
                  onChange={onChange}
                  sx={{ width: "100%" }}
                />
              </div>
            </div>
            <div className="card-footer text-center border-0">
              <div className="d-flex justify-content-between">
                <Button variant="contained" onClick={() => handleClickBack()}>
                  Atrás
                </Button>
                <ThemeProvider theme={themeBtnActive}>
                  <Button variant="contained" onClick={() => changePassword()}>
                    Cambiar
                  </Button>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordAdmin;
