import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { useAuth } from "../../hooks/useAuth";
import logo from "../../assets/logo.png";
import { useForm } from "../../hooks/useForm";
import { ToastContext } from "../../contexts/toast/ToastContext";

const ResetPassword = () => {
  const params = useParams();
  const { onOpenToast } = useContext(ToastContext);
  const { onOpenAbortAlert, onOpenCustomAlert } = useContext(AlertContext);

  const {
    confirmAccount: confirmAccountUser,
    resetPassword: resetPasswordUser,
  } = useAuth();

  const [confirm, setConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { password, confirmPassword, onChange, updateForm } = useForm({
    password: "",
    confirmPassword: "",
    token: params?.token || "",
  });

  useEffect(() => {
    if (params.token) {
      checkToken(params.token);
    }
  }, []);

  const checkToken = async (token: string) => {
    try {
      setIsLoading(true);
      const resp = await confirmAccountUser(token);
      if (resp) {
        if (resp.aborted) {
          onOpenAbortAlert({});
          setConfirm(false);
          setIsLoading(false);
          return;
        }
        if (resp.ok) {
          setConfirm(true);
          setIsLoading(false);
          return true;
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      setConfirm(false);
      setIsLoading(false);
      // console.log(error);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  const resetPassword = async () => {
    if (params.token) {
      if (await checkToken(params.token)) {
        if (password === confirmPassword) {
          const resp = await resetPasswordUser({
            token: params.token,
            password,
          });
          // console.log("🚀 ~ file: ResetPassword.tsx ~ line 80 ~ resetPassword ~ resp", resp)
          if (resp) {
            if (resp.aborted) {
              onOpenAbortAlert({});
              return;
            }
            if (resp.ok) {
              // onOpenToast(
              //   "success",
              //   "Confirmación",
              //   "La contraseña ha sido restablecida"
              // );
              updateForm({ password: "", confirmPassword: "", token: "" });
              onOpenCustomAlert({
                message: "Cambio de contraseña exitoso.",
                boldMessage: "Cerrar página",
                isFuncBoldMessage: true,
                funcBoldMessage: () => window.close(),
                severity: "success",
              });
            } else {
              onOpenToast(
                "error",
                "Error",
                "Ha ocurrido un error, vuelva a intentarlo mas tarde."
              );
            }
          } else {
            onOpenToast("error", "Error", "Inténtalo de nuevo mas tarde.");
          }
          // onOpenToast(
          //   "success",
          //   "Confirmación",
          //   "La contraseña ha sido cambiada."
          // );
        } else {
          onOpenToast("error", "Error", "Las contraseñas no coinciden.");
        }
      } else {
        onOpenToast("error", "Error", "Inténtalo de nuevo mas tarde.");
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <div>
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="container">
            <div className="row col-md-5 mx-auto">
              <div className="card border border-0">
                <div className="card-header border-0">
                  <div className="col-md-5 mx-auto">
                    <img alt="logo.png" src={logo} style={{ width: "100%" }} />
                  </div>
                  <Typography
                    variant="h5"
                    color="initial"
                    sx={{ textAlign: "center" }}
                  >
                    Recuperar contraseña
                  </Typography>
                </div>
                <div className="card-body">
                  <div>
                    <TextField
                      type="password"
                      label="Contraseña"
                      name="password"
                      value={password}
                      onChange={onChange}
                      sx={{ mb: 4, width: "100%" }}
                    />
                  </div>
                  <div>
                    <TextField
                      type="password"
                      label="Confirmar contraseña"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={onChange}
                      sx={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div className="card-footer text-center border-0">
                  <div>
                    <Button variant="contained" onClick={() => resetPassword()}>
                      Confirmar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
