import {
  Typography,
  Button,
  Divider,
  createTheme,
  Box,
  IconButton,
  Paper,
  Switch,
  ThemeProvider,
  TextField,
  Tooltip,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BodyLayout from "../../components/shared/BodyLayout";
import Rating from "@mui/material/Rating";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ArticleIcon from "@mui/icons-material/Article";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SortIcon from "@mui/icons-material/ArrowDownward";
import useUsers from "../../hooks/useUsers";
import styled, { keyframes } from "styled-components";
import DataTable from "react-data-table-component";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { ToastContext } from "../../contexts/toast/ToastContext";
import {
  BKG_TABLE_HEADER,
  COLOR_LOADING,
  UNHANDLED_ERROR,
} from "../../helpers/consants";
import { RotateSpinner } from "react-spinners-kit";
import { IResPutUserStatus } from "../../interfaces/IUsers";
import ModalWatchImage from "../../components/modals/ModalWatchImage";
import Zoom from "@mui/material/Zoom";
import CommentIcon from "@mui/icons-material/Comment";
import { useNavigate } from "react-router-dom";
import useCars from "../../hooks/useCars";
import {  IResPutStatusVehicle } from "../../interfaces/ICars";

const label = { inputProps: { "aria-label": "Switch demo" } };

interface usersRender {
  id: string;
  name: string;
  email: string;
  phoneNumber: string;
  plaque: string;
  rate: any;
  reviews: any;
  company: string;
  docs: any;
  state: any;
  statusUser: string;
  vehicle: any;
  idVehicle: number | null;
  idUser: number | null;
}

const themeBtnDft = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--color-dft-btn)",
          color: "ButtonText",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
          },
        },
      },
    },
  },
});

const listButtons = [
  { id: 1, name: "Todo" },
  { id: 2, name: "Clientes" },
  { id: 3, name: "Empresas" },
  { id: 4, name: "Guías" },
  { id: 5, name: "Conductores" },
  { id: 6, name: "Delivery" },
];

const columns = [
  {
    name: "No",
    selector: (row: usersRender) => row.id,
    sortable: true,
    width: "7%",
  },
  {
    name: "Nombre",
    selector: (row: usersRender) => row.name,
    sortable: true,
    width: "15%",
  },
  {
    name: "Email",
    selector: (row: usersRender) => row.email,
    sortable: true,
    width: "20%",
  },
  {
    name: "Teléfono",
    selector: (row: usersRender) => row.phoneNumber,
    sortable: true,
    width: "10%",
  },
  {
    name: "Placa",
    selector: (row: usersRender) => row.plaque,
    sortable: true,
    width: "10%",
  },
  {
    name: "Calificación",
    selector: (row: usersRender) => row.rate,
    sortable: true,
    width: "15%",
  },
  {
    name: "Reseñas",
    selector: (row: usersRender) => row.reviews,
    sortable: true,
    width: "10%",
  },
  {
    name: "Empresa",
    selector: (row: usersRender) => row.company,
    sortable: true,
    width: "10%",
  },
  {
    name: "Documentos",
    selector: (row: usersRender) => row.docs,
    sortable: true,
    width: "15%",
  },
  {
    name: "Estado",
    selector: (row: usersRender) => row.state,
    sortable: true,
    width: "10%",
  },
  {
    name: "Vehículo",
    selector: (row: usersRender) => row.vehicle,
    sortable: true,
    width: "10%",
  },
];

const paginationComponentOptions = {
  rowsPerPageText: "Mostrar",
  rangeSeparatorText: "de",
  selectAllRowsItem: false,
  selectAllRowsItemText: "Todos",
};

const FilterComponent = ({ filterText, onFilter, onClear }: any) => (
  <>
    <TextField
      // placeholder="Filtrar por nombre"
      value={filterText}
      onChange={onFilter}
      label="Filtar por nombre"
      size="small"
    />
    <Button variant="contained" onClick={onClear}>
      X
    </Button>
  </>
);

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  margin: 16px;
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);
  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const Users = () => {
  const navigate = useNavigate();

  const [usersToRender, setUsersToRender] = useState<usersRender[]>([]);
  const [filteredItems, setFilteredItems] = useState<usersRender[]>([]);
  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] =
    React.useState(false);

  // console.table(newData);

  useEffect(() => {
    setFilteredItems(
      usersToRender.filter(
        (item: any) =>
          item.name &&
          item.name.toLowerCase().includes(filterText.toLowerCase())
      )
    );
  }, [filterText, usersToRender]);

  // const filteredItems = usersToRender.filter(
  //   (item: any) =>
  //     item.name && item.name.toLowerCase().includes(filterText.toLowerCase())
  // );

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <FilterComponent
        onFilter={(e: any) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const { getUsersAdmin, putUserStatus } = useUsers();
  const { putStatusCarByUser } = useCars();
  const {} = useContext(ToastContext);
  const { onOpenAbortAlert } = useContext(AlertContext);

  const { onOpenToast } = useContext(ToastContext);

  const [modalImage, setModalImage] = useState({ visible: false, url: "" });
  const [activeBtn, setActiveBtn] = useState({ id: 1, active: true });
  const [initTo, setInitTo] = useState(0);
  const [endTo, setEndTo] = useState(10);
  const [currentPage, setCurrentPage] = useState<number[]>([1]);
  const [totalRows, setTotalRows] = useState(0);
  const [isPending, setIsPending] = useState(false);
  const [typeUser, setTypeUser] = useState<
    "all" | "clients" | "companies" | "guides" | "drivers" | "delivery"
  >("all");
  const [updateStatusProm, setUpdateStatusProm] = useState<
    Promise<IResPutUserStatus | null>[]
  >([]);

  const [updateStatusCarProm, setUpdateStatusCarProm] = useState<
    Promise<IResPutStatusVehicle | null>[]
  >([]);

  const handleBtnWatchReviews = (id: number, name: string) => {
    navigate("/admin/reviews", { state: { id, name, typeReview: "driver" } });
  };

  const getUsers = async (
    initRange: number = initTo,
    endRange: number = endTo,
    listUsers?: any
  ) => {
    setIsPending(true);
    const resp = await getUsersAdmin({
      from: initRange,
      to: endRange,
      typeReq: typeUser,
    });

    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        setIsPending(false);
        return;
      }
      setTotalRows(resp.count || 0);
      // console.log(resp.rows)
      const aux: usersRender[] = resp.rows.map((el, index) => {
        return {
          id: (index + 1).toString(),
          // id: el.id.toString(),
          name: el.fullName,
          email: el.email,
          phoneNumber: el.phoneNumber,
          plaque:
            el.vehicles && el.vehicles.length > 0 ? el.vehicles[0].plate : "-",
          rate: (
            <Rating
              name="disabled"
              value={el.stars}
              disabled
              sx={{ color: "var(--primary-color)" }}
            />
          ),
          company: el.company,
          reviews: (
            <div>
              <Tooltip
                TransitionComponent={Zoom}
                disableFocusListener
                disableTouchListener
                placement="top"
                arrow
                title="Ver reviews"
              >
                <IconButton
                  onClick={() => handleBtnWatchReviews(el.id, el.fullName)}
                >
                  <CommentIcon sx={{ width: 20, height: 20 }} />
                </IconButton>
              </Tooltip>
            </div>
          ),
          docs: (
            <div className="d-flex gap-1 justify-content-center">
              {el.roleId === 2 || el.roleId === 3 ? (
                <div className="">-</div>
              ) : (
                <>
                  <Tooltip
                    TransitionComponent={Zoom}
                    disableFocusListener
                    disableTouchListener
                    placement="top"
                    arrow
                    title="Vehículo"
                  >
                    <IconButton
                      onClick={() =>
                        watchDocImg(
                          el.vehicles.length > 0 ? el.vehicles[0].carPhoto : ""
                        )
                      }
                    >
                      <DirectionsCarIcon sx={{ width: 20, height: 20 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    TransitionComponent={Zoom}
                    disableFocusListener
                    disableTouchListener
                    placement="top"
                    arrow
                    title="Licencia"
                  >
                    <IconButton onClick={() => watchDocImg(el.license || "")}>
                      <ArticleIcon sx={{ width: 20, height: 20 }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    TransitionComponent={Zoom}
                    disableFocusListener
                    disableTouchListener
                    placement="top"
                    arrow
                    title="Registro del vehículo"
                  >
                    <IconButton
                      onClick={() =>
                        watchDocImg(
                          el.vehicles.length > 0
                            ? el.vehicles[0].vehicleRegistration
                            : ""
                        )
                      }
                    >
                      <ListAltIcon sx={{ width: 20, height: 20 }} />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </div>
          ),
          statusUser: el.status || "",
          state: (
            <div className="d-flex gap-1 justify-content-center">
              <Switch
                {...label}
                checked={el.status === "accepted" ? true : false}
                onClick={() => handleClickChangeStatus(el.id, el.status)}
              />
            </div>
          ),
          vehicle: (
            <div className="d-flex gap-1 justify-content-center">
              {el.roleId === 4 ? (
                el.vehicles && el.vehicles.length > 0 ? (
                  <Switch
                    {...label}
                    checked={
                      el.vehicles[0].status === "accepted" ? true : false
                    }
                    onClick={() =>
                      handleChangeStatusCar(
                        el.vehicles[0].id,
                        el.vehicles[0].status,
                        // el.id
                      )
                    }
                  />
                ) : (
                  <Typography variant="body2">Sin vehículo</Typography>
                )
              ) : (
                <Typography>-</Typography>
              )}
            </div>
          ),
          idVehicle:
            el.vehicles && el.vehicles.length > 0 ? el.vehicles[0].id : null,
          idUser: el.id,
        };
      });
      // setUsersToRender([...usersToRender, ...aux]);
      // console.log(aux);
      setUsersToRender(aux);
    }
    // else {
    //   onOpenToast("error", "", UNHANDLED_ERROR);
    // }
    setIsPending(false);
  };

  const fetchMore = (page: number) => {
    if (!currentPage.includes(page)) {
      setInitTo(initTo + endTo);
      getUsers(initTo + endTo, endTo);
    } else if (page < currentPage[currentPage.length - 1]) {
      setInitTo(initTo - endTo);
      getUsers(initTo - endTo, endTo);
      setCurrentPage(currentPage.filter((el) => el !== page));
    } else {
      setInitTo(initTo + endTo);
      getUsers(initTo + endTo, endTo);

      // setCurrentPage(currentPage.splice(-1, 1));
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {}, [currentPage]);

  useEffect(() => {}, [usersToRender]);

  useEffect(() => {
    setInitTo(0);
    setUsersToRender([]);
  }, [typeUser]);

  useEffect(() => {
    getUsers();
  }, [initTo, typeUser]);

  const handleClickChangeStatus = async (
    id: number,
    status: string,
    posProm?: number
  ) => {
    if (typeof posProm === "number") {
      const newPromises = updateStatusProm.filter(
        (value, idx) => idx !== posProm
      );
      setUpdateStatusProm([
        ...newPromises,
        (updateStatusProm[posProm] = putUserStatus({
          idUser: id,
          status: status === "accepted" ? "rejected" : "accepted",
        })),
      ]);
    } else {
      setUpdateStatusProm([
        ...updateStatusProm,
        putUserStatus({
          idUser: id,
          status: status === "accepted" ? "rejected" : "accepted",
        }),
      ]);
    }

    // const resp = await putUserStatus({
    //   idUser: id,
    //   status: status === "accepted" ? "rejected" : "accepted",
    // });
    // if (resp) {
    //   if (resp.aborted) {
    //     onOpenAbortAlert({});
    //   } else if (resp.ok) {
    //   }
    // }
  };

  const handleChangeStatusCar = async (id: number, status: string, posProm?: number) => {
    if (typeof (posProm) === 'number') {
      const newPromises = updateStatusCarProm.filter((value, idx) => idx !== posProm)
      setUpdateStatusCarProm([
        ...newPromises,
        updateStatusCarProm[posProm] = putStatusCarByUser({
          idVehicle: id,
          status: status === "accepted" ? "pending" : "accepted",
      }),
      ])
    } else {
      setUpdateStatusCarProm([
        ...updateStatusCarProm,
        putStatusCarByUser({
          idVehicle: id,
          // idUserAssigned,
          status: status === "accepted" ? "pending" : "accepted",
      }),
    ]);
  }
  };

  const handleClickBtn = (id: number) => {
    setActiveBtn({ id, active: true });
    switch (id) {
      case 1:
        setTypeUser("all");
        break;
      case 2:
        setTypeUser("clients");
        break;
      case 3:
        setTypeUser("companies");
        break;
      case 4:
        setTypeUser("guides");
        break;
      case 5:
        setTypeUser("drivers");
        break;
      case 6:
        setTypeUser("delivery");
        break;

      default:
        setTypeUser("all");
        break;
    }
  };

  const handleClickChangeRowsPerPage = (count: number) => {
    setInitTo(0);
    setUsersToRender([]);
    setEndTo(count);
    getUsers(0, count);
  };

  const watchDocImg = (uri: string) => {
    // console.log("url ==>", uri);
    setModalImage({ visible: true, url: uri });
    //setModalImage({ visible: true, url: 'https://th.bing.com/th/id/R.5bae5eeb21d856424f6902a8dcd27f41?rik=lLWx7Cn9kvoq2Q&pid=ImgRaw&r=0' });
  };

  const renderButton = (el: { id: number; name: string }) => {
    return (
      <ThemeProvider theme={themeBtnDft} key={el.id}>
        <Button
          variant="contained"
          key={el.id}
          onClick={() => handleClickBtn(el.id)}
          sx={{
            background:
              activeBtn.id === el.id && activeBtn.active
                ? "var(--primary-color)"
                : "var(--color-dft-btn)",
            color:
              activeBtn.id === el.id && activeBtn.active
                ? "HighlightText"
                : "ButtonText",
          }}
        >
          {el.name}
        </Button>
      </ThemeProvider>
    );
  };

  useEffect(() => {
    if (updateStatusProm.length > 0) {
      Promise.all(updateStatusProm).then((resp) => {
        resp.forEach((res, index) => {
          if (res) {
            if (res.aborted) {
              onOpenAbortAlert({});
              return;
            }
            // console.log(res?.data || null);
            const newRows: usersRender[] = filteredItems.map((el, idx) => {
              if (res.data) {
                if (el.idUser === res.data.id) {
                  return {
                    ...el,
                    state: (
                      <Switch
                        {...label}
                        checked={res.data!.status === "accepted" ? true : false}
                        onChange={(evt, checked) => {
                          handleClickChangeStatus(
                            res.data!.id,
                            checked ? "rejected" : "accepted",
                            index
                          );
                        }}
                      />
                    ),
                  };
                }
              }
              return el;
            });
            setFilteredItems(newRows);
          } else {
            onOpenToast("error", "", UNHANDLED_ERROR);
            return;
          }
          const aux = updateStatusProm
            .filter((value, pos) => {
              if (pos !== index) {
                return value;
              } else {
                return undefined;
              }
            })
            .filter((el) => el !== undefined);
          setUpdateStatusProm(aux);
        });
      });
      // setUpdateStatusProm([]);
    }
  }, [updateStatusProm]);

  useEffect(() => {
    if (updateStatusCarProm.length > 0) {
      Promise.all(updateStatusCarProm).then((resp) => {
        resp.forEach((res, index) => {
          if (res) {
            if (res.aborted) {
              onOpenAbortAlert({});
              return;
            }
            // console.log(res?.data || null);
            const newRows: usersRender[] = filteredItems.map((el, idx) => {
              if (res.data) {
                // console.log('el.idVehicle', el.idVehicle)
                if (el.idVehicle === res.data.id) {
                  return {
                    ...el,
                    vehicle: (
                      <Switch
                        {...label}
                        checked={res.data!.status === "accepted" ? true : false}
                        onChange={(evt, checked) => {
                          handleChangeStatusCar(
                            res.data!.id,
                            checked ? "pending" : "accepted",
                            // res.data!.idUserAssigned,
                            index
                          );
                        }}
                      />
                    ),
                  };
                }
              }
              return el;
            });
            setFilteredItems(newRows);
          } else {
            onOpenToast("error", "", UNHANDLED_ERROR);
            return;
          }
          const aux = updateStatusCarProm
            .filter((value, pos) => {
              if (pos !== index) {
                return value;
              } else {
                return undefined;
              }
            })
            .filter((el) => el !== undefined);
          setUpdateStatusCarProm(aux);
        });
      });
      // setUpdateStatusProm([]);
    }
  }, [updateStatusCarProm]);
  
  return (
    <BodyLayout>
      <div className="d-flex flex-wrap gap-4">
        <div className="d-flex align-items-center flex-wrap ">
          <Typography variant="h5" className="flex-grow-1 fw-bold">
            Usuarios
          </Typography>
        </div>
        <div className="d-flex flex-grow-1 justify-content-evenly flex-wrap gap-3">
          {listButtons.map((el) => renderButton(el))}
        </div>
      </div>
      <Divider sx={{ margin: "2rem", borderColor: "var(--primary-color)" }} />
      <Box sx={{ width: "100%", maxHeight: "75vh" }}>
        <Paper sx={{ width: "80%", mb: 2 }} className="mx-auto">
          <DataTable
            customStyles={{
              headCells: {
                draggingStyle: {
                  cursor: "move",
                },
                style: {
                  backgroundColor: BKG_TABLE_HEADER,
                },
              },
              cells: {
                style: {
                  borderBottom: "1.5px solid #dedede",
                },
              },
              rows: {
                style: { border: "none !important" },
              },
            }}
            columns={columns}
            data={filteredItems}
            pagination
            paginationServer
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[5, 10, 25]}
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={(
              currentRowsPerPage: any,
              currentPage: any
            ) => {
              handleClickChangeRowsPerPage(currentRowsPerPage);
            }}
            onChangePage={(page: any, totalRows: any) => {
              fetchMore(page);
              if (!currentPage.includes(page)) {
                setCurrentPage([...currentPage, page]);
              }
            }}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            persistTableHead
            sortIcon={<SortIcon />}
            progressPending={isPending}
            progressComponent={
              <div className="w-100 d-flex justify-content-center mt-5 mb-4 w-100 ">
                <RotateSpinner size={70} color={COLOR_LOADING} loading={true} />
              </div>
            }
            responsive
            noDataComponent={
              <div className="w-100 d-flex justify-content-center mt-5 mb-4 w-100 ">
                <Typography variant="h4">No hay registros..</Typography>
              </div>
            }
          />
        </Paper>
      </Box>
      <ModalWatchImage
        openModal={modalImage.visible}
        setFunc={() => setModalImage({ visible: false, url: "" })}
        url={modalImage.url}
        msg="Los documentos aún no han sido agregados por el conductor"
      />
    </BodyLayout>
  );
};

export default Users;
