import axios from "axios";
import { GOOGLE_API_KEY } from "../helpers/consants";

const geocodeApi = axios.create({
  baseURL: "https://maps.googleapis.com/maps/api/geocode/json",
  params: {
    key: GOOGLE_API_KEY,
  },
});

export default geocodeApi;
