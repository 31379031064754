import { helpHttp } from "../helpers/helpHttp";
import { IRespDefault } from "../interfaces/IGlobal";
import { IResDataTariff, IResGetTariff } from "../interfaces/ITariff";

const ABORTED = "ERR_CANCELED";

export default function useTariff() {
  const getTariff = async (): Promise<IResGetTariff | null> => {
    try {
      const resp = await helpHttp().get(`parameters/getParameters`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED) {
        return { aborted: true };
      } else {
        return null;
      }
    }
  };

  const postPutTariff = async (
    delivery: IResDataTariff,
    transport: IResDataTariff,
    tour: IResDataTariff
  ): Promise<IRespDefault | null> => {
    try {
      const resp = await helpHttp().post(`parameters/postPutParametersAdmin`, {
        data: {
          params: [
            { ...delivery, iva: delivery.iva.toString().replace("%", "") },
            { ...transport, iva: transport.iva.toString().replace("%", "") },
            { ...tour, iva: tour.iva.toString().replace("%", "") },
          ],
        },
      });
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED) return { aborted: true };
      return null;
    }
  };

  return { getTariff, postPutTariff };
}
