import React, { createContext, useState } from "react";
import { ConfirmDialog } from "primereact/confirmdialog";

type TDialogContextProps = {
  onOpenDialog: (
    funcAccept: any,
    message: string,
    title?: string,
    icon?: string,
    funcReject?: any
  ) => void;
};

export const ConfirmDialogContext = createContext({} as TDialogContextProps);

const ConfirmDialogProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState({
    open: false,
    message: "",
    title: "Confirmación",
    icon: "pi pi-exclamation-triangle",
    funcAccept: () => {},
    funcReject: () => {},
  });

  const onOpenDialog = (
    funcAccept: any,
    message: string,
    title?: string,
    icon?: string,
    funcReject?: any
  ) => {
    setIsOpen({
      ...isOpen,
      open: true,
      message,
      title: title ? title : isOpen.title,
      funcAccept,
      icon: icon ? icon : isOpen.icon,
      funcReject: funcReject ? funcReject : isOpen.funcReject,
    });
  };

  const onCloseDialog = () => {
    setIsOpen({ ...isOpen, open: false });
  };

  return (
    <ConfirmDialogContext.Provider value={{ onOpenDialog }}>
      <ConfirmDialog
        visible={isOpen.open}
        onHide={() => onCloseDialog()}
        message={isOpen.message}
        header={isOpen.title}
        icon={isOpen.icon}
        accept={isOpen.funcAccept}
        reject={isOpen.funcReject}
      />
      {children}
    </ConfirmDialogContext.Provider>
  );
};

export default ConfirmDialogProvider;
