import { Map } from "mapbox-gl";
import { createContext } from "react";
import { IPlaceSelected } from "./MapProvider";

interface MapContextProps {
  isMapReady: boolean;
  map?: Map;
  placeSelected: IPlaceSelected;
  isMyLocationClicked: { location: number[]; isClicked: boolean };
  
  // methods
  setMap: (map: Map) => void;
  setPlaceSelected: ({
    latLng,
    namePlace,
    addressPlace,
  }: IPlaceSelected) => void;
  setIsMyLocationClicked: (location?: number[], isClicked?: boolean) => void;
}

export const MapContext = createContext<MapContextProps>({} as MapContextProps);
