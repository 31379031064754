import { useState } from "react";

export const useValidateInput = () => {
  const [isValid, setIsValid] = useState(false);

  const validateNumber = (e: any) => {
    let code = e.key;
    if (code === "Backspace") {
      setIsValid(false);
    } else if (parseInt(code) >= 0 && parseInt(code) <= 9) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return {
    validateNumber,
    isValid,
  };
};
