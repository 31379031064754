import React, { useContext, useEffect, useState } from "react";
import BodyLayout from "../../components/shared/BodyLayout";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { ToastContext } from "../../contexts/toast/ToastContext";
import { UNHANDLED_ERROR } from "../../helpers/consants";
import useTerms from "../../hooks/useTerms";
import { IGetDataTerms } from "../../interfaces/ITerms";
import ReactQuill from "react-quill";
import { ThemeProvider, createTheme, Button } from "@mui/material";

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          transform: "scale(1)",
          transition: "transform 0.05s",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
            // transform: "scale(1.03)",
            transition: "transform 0.05s",
          },
        },
      },
    },
  },
});

const initTerms: IGetDataTerms = {
  id: 0,
  descriptionTerms: "",
};

const Terms = () => {
  //? CONTEXTS
  const { onOpenAbortAlert } = useContext(AlertContext);
  const { onOpenToast } = useContext(ToastContext);

  //? CUSTOM HOOKS
  const { getTerms, postPutTerms } = useTerms();

  //? STATES
  const [termsAndConditions, setTermsAndConditions] =
    useState<IGetDataTerms>(initTerms);

  const getData = async () => {
    const resp = await getTerms();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.data && resp.data.length > 0) {
        const terms = resp.data[0];
        setTermsAndConditions(terms);
      }
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
  };

  const handleChange = (content: string) => {
    setTermsAndConditions({ ...termsAndConditions, descriptionTerms: content });
  };

  const handleSaveTerms = async () => {
    const resp = await postPutTerms(termsAndConditions);
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.ok) {
        onOpenToast(
          "success",
          "Guardado",
          "Términos y Condiciones actualizados"
        );
      }
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <BodyLayout>
      <div>
        <ReactQuill
          value={termsAndConditions.descriptionTerms}
          onChange={(content) => handleChange(content)}
        />
      </div>
      <div className="container d-flex justify-content-end my-5 ">
        <ThemeProvider theme={themeBtnDftActive}>
          <Button onClick={handleSaveTerms}>Gurardar</Button>
        </ThemeProvider>
      </div>
    </BodyLayout>
  );
};

export default Terms;
