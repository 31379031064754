import { ABORTED_REQUEST } from "../helpers/consants";
import { helpHttp } from "../helpers/helpHttp";
import {
  IResGetReviewsByTour,
  IResGetReviewsByUser,
} from "../interfaces/IReviews";

export default function useReviews() {
  const getGetReviewsByUser = async (
    id: number
  ): Promise<IResGetReviewsByUser | null> => {
    try {
      const resp = await helpHttp().get(`review/getReviewsByIUser/${id}`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };

  const getGetReviewsByTour = async (
    id: number
  ): Promise<IResGetReviewsByTour | null> => {
    try {
      const resp = await helpHttp().get(
        `review/getReviewPackageByIdPackage/${id}`
      );
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };

  return {
    getGetReviewsByUser,
    getGetReviewsByTour,
  };
}
