import axios from "axios";
import { baseURL } from "../api/ecuadorianApi";

interface IUploadFile {
  data: any;
  nameFolder?: string;
}

interface IResUpload {
  url: string;
}

export async function uploadFile({
  data,
  nameFolder = "equatorian",
}: IUploadFile): Promise<IResUpload | null> {
  try {
    const res = await axios(baseURL.concat(`uploads/${nameFolder}`), {
      method: "POST",
      data,
    });
    // console.log("res ==>", res);
    return res.data;
  } catch (error: any) {
    // console.log("error ==>", error);
    return null;
  }
}
