import { useState } from "react";

export const useForm = <T extends Object>(initState: T) => {
  const [state, setState] = useState(initState);

  const onChange = (evt: any) => {
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });
  };

  const updateForm = (newData: T) => {
    setState(newData);
  };

  const resetForm = () => {
    setState(initState);
  };

  return {
    ...state,
    form: state,
    onChange,
    updateForm,
    resetForm,
  };
};
