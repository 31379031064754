import { Map, Marker } from "mapbox-gl";
import { IPlaceSelected, MapState } from "./MapProvider";

type MapAction =
  | { type: "setMap"; payload: Map }
  | { type: "setMarkers"; payload: Marker[] }
  | { type: "setPlaceSelected"; payload: IPlaceSelected }
  | { type: "setMarkerPlaceSelected"; payload: Marker | null }
  | {
      type: "setIsMyLocationClicked";
      payload: { location: number[]; isClicked: boolean };
    }
  | { type: "setMarkersVoid"; payload: void[] };

export const mapReducer = (state: MapState, action: MapAction): MapState => {
  switch (action.type) {
    case "setMap":
      return {
        ...state,
        isMapReady: true,
        map: action.payload,
      };

    case "setMarkers":
      return {
        ...state,
        markers: action.payload,
      };

    case "setPlaceSelected":
      return {
        ...state,
        placeSelected: action.payload,
      };

    case "setMarkerPlaceSelected":
      return {
        ...state,
        markerPlaceSelected: action.payload,
      };

    case "setIsMyLocationClicked":
      return {
        ...state,
        isMyLocationClicked: action.payload,
      };

    case "setMarkersVoid":
      return {
        ...state,
        markersVoid: action.payload,
      };

    default:
      return state;
  }
};
