import axios from "axios";

export const domainProd = 'https://equatorian.herokuapp.com'
export const domainLocal = 'http://localhost:8080'

//develop
// export const baseURL = `${domainLocal}/api/`;

// Production
export const baseURL = `${domainProd}/api/`;

const ecuadorianApi = axios.create({ baseURL });

export default ecuadorianApi;
