import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  createTheme,
  ThemeProvider,
  Button,
} from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface Props {
  url: string;
  open?: boolean;
  onClose?: any;
  onAccept?: any;
}

const themeBtnDft = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--color-dft-btn)",
          color: "ButtonText",
          ":hover": {
            background: "var(--color-dft-hover)",
          },
        },
      },
    },
  },
});

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          transform: "scale(1)",
          transition: "transform 0.05s",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
            transition: "transform 0.05s",
          },
        },
      },
    },
  },
});

const ModalWebPage = ({ url, open, onAccept, onClose }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  // console.log(url);

  useEffect(() => {
    if (open) {
      setIsOpen(true);
    }
  }, [open]);

  const handleClose = async () => {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  };
  
  const handleAccept = async () => {
    if (onAccept) {
      onAccept();
    }
    handleClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ zIndex: "4000 !important" }}
      fullWidth
      maxWidth="lg"
    >
      {/* <DialogTitle id="alert-dialog-title">
        {"Seleccionar Ubicación"}
      </DialogTitle> */}
      <DialogContent>
        <div style={{ width: "100%", height: "65vh" }} className="">
          <iframe
            src={url}
            title="ViewPackage"
            className="border-0"
            style={{ width: "100%", height: "100%" }}
            seamless={ true}
            loading="eager"
            contentEditable={false}
            
          ></iframe>
        </div>
      </DialogContent>
      <DialogActions>
        {/* <ThemeProvider theme={themeBtnDft}>
          <Button onClick={handleClose}>Cancelar</Button>
        </ThemeProvider> */}
        <ThemeProvider theme={themeBtnDftActive}>
          <Button onClick={handleAccept} autoFocus>Aceptar</Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  );
};

export default ModalWebPage;

const bottomButtons: CSSProperties = {
  zIndex: 1000,
  position: "absolute",
  right: 10,
  bottom: 0,
  margin: "auto",
  backgroundColor: "#f6f6f6",
  padding: 2,
  boxShadow: "",
};

const topElements: CSSProperties = {
  zIndex: 1000,
  position: "absolute",
  top: 10,
  left: 10,
  margin: "auto",
  backgroundColor: "#f6f6f6",
  // padding: 2,
  // boxShadow: "",
};
