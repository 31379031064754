import { ThemeProvider } from "@emotion/react";
import { Typography, Button, Divider } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
import { SocketProvider } from "../../contexts/sockets/SocketContext";
import BodyLayout from "./BodyLayout";
import NavBar from "./NavBar";
import SideBar from "./SideBar";

const MainLayout = ({ children }: any) => {
  return (
    <SocketProvider>
      <div>
        <NavBar />
        <SideBar />
        {children}
        {/* <div className="main-section">
        <div className="pivot-cont"></div>
        <div className="main-section-cont">{children}</div>
      </div> */}
        <Outlet />
      </div>
    </SocketProvider>
  );
};

export default MainLayout;
