import React from "react";
import { ABORTED_REQUEST } from "../helpers/consants";
import { helpHttp } from "../helpers/helpHttp";
import { IPostPutVehicle, IResDeleteVehicle, IResGetVehicles, IResPostPutVehicle, IResPutStatusVehicle } from "../interfaces/ICars";

export type TStatusCar = "rejected" | "accepted" | "pending";

export default function useCars() {
	const getAllVehicles = async (): Promise<IResGetVehicles | null> => {
		try {
			const resp = await helpHttp().get(`vehicle/getVehicles`);
			return resp;
		} catch (error: any) {
			// console.log(error);
			if (error.code === ABORTED_REQUEST) return { aborted: true };
			else return null;
		}
	};
	
	const postPutVehicle = async (data: IPostPutVehicle): Promise<IResPostPutVehicle | null> => {
		try {
			const resp = await helpHttp().post(`vehicle/postPutVehicle`, {data})
			return resp;
		} catch (error:any) {
			// console.log(error);
			if (error.code === ABORTED_REQUEST) return { aborted: true }
			else return null;
		}
	}

	interface IStatusCar {
		id: number;
		status: TStatusCar;
	}

	const putStatusCar = async (data: IStatusCar): Promise<IResPostPutVehicle | null> => {
		try {
			const resp = await helpHttp().post(`vehicle/postPutVehicle`, {data})
			return resp;
		} catch (error:any) {
			// console.log(error);
			if(error.code === ABORTED_REQUEST) return {aborted: true}
			else return null
		}
	}

	interface IStatusUserCar {
		id: number;
		idUserAssigned: number;
		status: TStatusCar;
	}
	const putStatusUserCar = async (data: IStatusUserCar): Promise<IResPostPutVehicle | null> => {
		try {
			const resp = await helpHttp().post(`vehicle/postPutVehicle`, {data})
			return resp;
		} catch (error:any) {
			// console.log(error);
			if(error.code === ABORTED_REQUEST) return {aborted: true}
			else return null
		}
	}

	interface IDataStatusCar {
		idVehicle: number;
		status: TStatusCar;
	}
	const putStatusCarByUser = async (data: IDataStatusCar): Promise<IResPutStatusVehicle | null> => {
		try {
			const resp = await helpHttp().put(`vehicle/putVehicleStatus`, {data})
			return resp;
		} catch (error:any) {
			// console.log(error);
			if(error.code === ABORTED_REQUEST) return {aborted: true}
			else return null
		}
	}

	const deleteVehicleById = async (id: number): Promise<IResDeleteVehicle | null> => {
		try {
			const resp = await helpHttp().del(`vehicle/deleteVehicle/${id}`);
			return resp;
		} catch (error:any) {
			// console.log(error);
			if(error.code === ABORTED_REQUEST) return {aborted: true}
			else return null
		}
	}

  return {
    getAllVehicles,
		postPutVehicle,
		putStatusCar,
		deleteVehicleById,
		putStatusUserCar,
		putStatusCarByUser
  };
}


