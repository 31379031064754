import { ABORTED_REQUEST } from "../helpers/consants";
import { helpHttp } from "../helpers/helpHttp";
import { IResDeleteAllNotifications, IResDelNotificationByID, IResGetNotifications, IResPutNotification } from "../interfaces/INotifications";

export const useNotifications = () => {

  const getNotifications = async ():Promise<IResGetNotifications | null> => {
    try {
      const resp = await helpHttp().get(`notification/getNotifications`);
      // return resp.data.data ? resp : resp.data;
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };
  
  const putNotificationToViewed = async (id:number): Promise<IResPutNotification | null> => {
    try {
      const resp = await helpHttp().put(`notification/putViewedNotification/${id}`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };
  
  const deleteAllNotifications = async (): Promise<IResDeleteAllNotifications | null> => {
    try {
      const resp = await helpHttp().del(`notification/deleteAllNotification`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };
  
  const deleteNotificationById = async (id:number): Promise<IResDelNotificationByID | null> => {
    try {
      const resp = await helpHttp().del(`notification/deleteNotification/${id}`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED_REQUEST) return { aborted: true };
      else return null;
    }
  };

  return {
    getNotifications,
    putNotificationToViewed,
    deleteAllNotifications,
    deleteNotificationById
  };
};
