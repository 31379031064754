import React from "react";
import Fade from "@mui/material/Fade";

const BodyLayout = ({ children }: any) => {
  return (
    <div className="main-section">
      <div className="pivot-cont "></div>
      <Fade in={true}>
        <div className="main-section-cont">{children}</div>
      </Fade>
    </div>
  );
};

export default BodyLayout;
