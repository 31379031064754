import React, { createContext, useState } from "react";
import { ConfirmPopup } from "primereact/confirmpopup";

type TDialogContextProps = {
  onOpenPopUp: (
    funcAccept: any,
    message: string,
    target: string,
    icon?: string,
    funcReject?: any
  ) => void;
};

export const ConfirmPopUpContext = createContext({} as TDialogContextProps);

const ConfirmPopUpProvider = ({ children }: any) => {
  const [isOpen, setIsOpen] = useState({
    open: false,
    message: "",
    target: "",
    icon: "pi pi-exclamation-triangle",
    funcAccept: () => {},
    funcReject: () => {},
  });

  const onOpenPopUp = (
    funcAccept: any,
    message: string,
    target: string,
    icon?: string,
    funcReject?: any
  ) => {
    setIsOpen({
      ...isOpen,
      open: true,
      message,
      target,
      funcAccept,
      icon: icon ? icon : isOpen.icon,
      funcReject: funcReject ? funcReject : isOpen.funcReject,
    });
  };

  const onCloseDialog = () => {
    setIsOpen({ ...isOpen, open: false });
  };

  return (
    <ConfirmPopUpContext.Provider value={{ onOpenPopUp }}>
      <ConfirmPopup
        target={document.getElementById(isOpen.target)!}
        visible={isOpen.open}
        onHide={() => onCloseDialog()}
        message={isOpen.message}
        icon={isOpen.icon}
        accept={isOpen.funcAccept}
        reject={isOpen.funcReject}
      />
      {children}
    </ConfirmPopUpContext.Provider>
  );
};

export default ConfirmPopUpProvider;
