import {
  Typography,
  Button,
  Divider,
  createTheme,
  alpha,
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  ThemeProvider,
  TextField,
  Grow,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BodyLayout from "../../components/shared/BodyLayout";
import { visuallyHidden } from "@mui/utils";
import FilterListIcon from "@mui/icons-material/FilterList";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useTheme } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import DeleteIcon from "@mui/icons-material/Delete";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm } from "../../hooks/useForm";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { ToastContext } from "../../contexts/toast/ToastContext";
import useProvinces, { TStatus } from "../../hooks/useProvinces";
import { TransitionGroup } from "react-transition-group";
import {
  BKG_TABLE_HEADER,
  COLOR_LOADING,
  UNHANDLED_ERROR,
} from "../../helpers/consants";
import { ConfirmPopUpContext } from "../../contexts/confirmPopUp/ConfirmPopUpContext";
import useRegions from "../../hooks/useRegions";
import {
  IPostPutProvince,
  IResPostPutProvince,
} from "../../interfaces/IProvinces";
import { RotateSpinner } from "react-spinners-kit";
import useReviews from "../../hooks/useReviews";

const themeLblHeadTbl = createTheme({
  components: {
    // Name of the component
    MuiTableSortLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // textAlign: 'center',
        },
        icon: {
          // display: "none",
          // ":hover": {
          //   display: "none",
          // },
        },
      },
    },
  },
});

interface Data {
  no: number;
  name: string;
  review: string;
  rate: number;
}

interface IState {
  id: number | undefined;
  name: boolean;
  typeReview: "tour" | "driver";
}

const Reviews = () => {
  const location = useLocation();
  const state = location.state as IState;
  const { id, name, typeReview } = state;

  const { onOpenAbortAlert } = useContext(AlertContext);
  const { onOpenToast } = useContext(ToastContext);
  // const { onOpenPopUp } = useContext(ConfirmPopUpContext);

  const { getGetReviewsByUser, getGetReviewsByTour } = useReviews();

  const [isFetching, setIsFetching] = useState(false);
  const [reviewsList, setReviewsList] = useState<Data[]>([]);

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("no");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const getDataDriver = async () => {
    setIsFetching(true);
    const resp = await getGetReviewsByUser(id!);
    setIsFetching(false);
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.data) {
        const rows: Data[] = resp.data.map((el, index) => {
          return {
            no: index,
            name: el.sender.fullname,
            rate: el.starNumber,
            review: el.comment,
          };
        });
        setReviewsList(rows);
      }
    } else {
      onOpenToast("warn", "", "No se han encontrado reseñas");
    }
  };

  const geDataTour = async () => {
    setIsFetching(true);
    const resp = await getGetReviewsByTour(id!);
    setIsFetching(false);
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.data && resp.data.rows) {
        const rows: Data[] = resp.data.rows.map((el, index) => {
          return {
            no: el.id,
            name: el.sender.fullName,
            rate: el.starNumber,
            review: el.comment,
          };
        });
        setReviewsList(rows);
      }
    } else {
      onOpenToast("warn", "", "No se han encontrado reseñas");
    }
  };

  useEffect(() => {
    if (typeReview === "driver") {
      getDataDriver();
    } else {
      geDataTour();
    }
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = reviewsList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (
      event: React.MouseEvent<HTMLButtonElement>,
      newPage: number
    ) => void;
  }

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (
      event: React.MouseEvent<HTMLButtonElement>
    ) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - reviewsList.length) : 0;

  return (
    <BodyLayout>
      <div className="d-flex flex-wrap gap-4 flex-column">
        <div className="d-flex align-items-center ">
          <Typography variant="h5" className="flex-grow-1 fw-bold">
            Comentarios de: {name}
          </Typography>
        </div>
      </div>
      <Divider sx={{ margin: "2rem", borderColor: "var(--primary-color)" }} />
      <Box sx={{ width: "100%", maxHeight: "75vh" }}>
        <Paper sx={{ width: "80%", mb: 2 }} className="mx-auto">
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer sx={{ maxHeight: "65vh", maxWidth: "100%" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size="medium"
              stickyHeader
              aria-label="sticky table"
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={reviewsList.length}
              />
              <TableBody>
                <TransitionGroup component={null}>
                  {stableSort(reviewsList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <Grow key={index}>
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.name)}
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="left">{row.review}</TableCell>
                            <TableCell align="center">
                              <div className="d-flex gap-1 justify-content-center">
                                <Rating
                                  name="disabled"
                                  value={row.rate}
                                  disabled
                                  sx={{ color: "var(--primary-color)" }}
                                />
                              </div>
                            </TableCell>
                          </TableRow>
                        </Grow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 60 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TransitionGroup>
              </TableBody>
            </Table>
            {isFetching && (
              <div className="w-100 d-flex justify-content-center mt-5 mb-2">
                <RotateSpinner
                  size={70}
                  color={COLOR_LOADING}
                  loading={isFetching}
                />
              </div>
            )}
          </TableContainer>
          <div className="d-flex justify-content-center w-100 p-4">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "Todo", value: -1 }]}
              colSpan={3}
              count={reviewsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Mostrar"
              SelectProps={{
                inputProps: {
                  "aria-label": "rows per page",
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              className=""
            />
          </div>
        </Paper>
      </Box>
    </BodyLayout>
  );
};

export default Reviews;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "no",
    numeric: true,
    disablePadding: false,
    label: "No",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Nombre",
  },
  {
    id: "review",
    numeric: false,
    disablePadding: false,
    label: "Review",
  },
  {
    id: "rate",
    numeric: false,
    disablePadding: false,
    label: "Calificación",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow
        sx={{
          "& th": {
            backgroundColor: BKG_TABLE_HEADER,
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={
              headCell.id === "rate" || headCell.id === "no"
                ? "center"
                : "justify"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <ThemeProvider theme={themeLblHeadTbl}>
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === "actions" ? null : (
                  <>
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </>
                )}
              </TableSortLabel>
            </ThemeProvider>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
