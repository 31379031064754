import React, { useContext, useEffect, useState } from "react";
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../helpers/consants";
import { PlacesContext } from "../../contexts";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Collapse,
  createTheme,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import AutoCompleteMap from "../common/AutoCompleteMap";
import { Feature } from "../../interfaces/IMap";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { MapContext } from "../../contexts/map/MapContext";
import { IGeocodeGMaps } from "../../interfaces/IGeocode";
import geocodeApi from "../../api/geocodeApi";

const themeTxtFilled = createTheme({
  components: {
    // Name of the component
    MuiFilledInput: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          // background: "#f6f6f6",
          color: "black",
          ":hover": {
            background: "#f6f6f6",
            color: "HighlightText",
            transition: "transform 0.05s",
          },
        },
        input: {
          // background: "#f6f6f6",
          background: "#fff",
          "::placeholder": {
            color: "#555",
          },
          color: "black",
        },
      },
    },
  },
});

const themeListItemIcon = createTheme({
  components: {
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          width: "max-content",
          minWidth: "0px",
          justifyContent: "center",
          marginRight: ".5rem",
        },
      },
    },
  },
});

interface Props {
  location?: {lat:number, lng: number}
  getLocation?: boolean;
}

const librariesProp:("places" | "drawing" | "geometry" | "localContext" | "visualization")[] = ["places"];

interface ILocationDesc {lat: number, lng: number}
const getLocationDescription = async (currentLocation: ILocationDesc) => {
  const resp = await geocodeApi.get<IGeocodeGMaps>("", {
    params: {
      latlng: `${currentLocation.lat},${currentLocation.lng}`,
    },
  });
  const addressResp = resp.data.results.filter(
    (el) =>
      el.geometry.location_type === "GEOMETRIC_CENTER" &&
      !el.plus_code &&
      el.types.includes("route")
  );
  return {
    placeSelected: {
      addressPlace: addressResp[0].formatted_address.includes("Vía sin nombre")
        ? `${currentLocation.lat},${currentLocation.lng}`
        : addressResp[0].formatted_address,
      latLng: [currentLocation.lat, currentLocation.lng],
      namePlace: resp.data.plus_code.compound_code
        .split(" ")
        .filter((value, index) => index > 0)
        .join(" "),
    },
  
    locationDescription: {
      name: resp.data.plus_code.compound_code
        .split(" ")
        .filter((value, index) => index > 0)
        .join(" "),
      description: addressResp[0].formatted_address.includes("Vía sin nombre")
        ? `${currentLocation.lat},${currentLocation.lng}`
        : addressResp[0].formatted_address,
    }
  }
};

export const Map = ({location, getLocation }:Props) => {
  const { userLocation } = useContext(PlacesContext);
  const { setPlaceSelected } = useContext(MapContext);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: GOOGLE_API_KEY,
    libraries: librariesProp,
  });

  const [currentLocation, setCurrentLocation] = useState<{lat: number, lng: number}>(
    userLocation
      ? { lat: userLocation[1], lng: userLocation[0] }
      : { lat: 44, lng: -80 }
  );
  const [locationDescription, setLocationDescription] = useState({
    name: "",
    description: "",
  });

  const [isGetLocation, setIsGetLocation] = useState(false);

  const handleClickMap = async (evt: google.maps.MapMouseEvent) => {
    setIsGetLocation(false)
    const newLocation = evt.latLng?.toJSON();
    if (newLocation) setCurrentLocation(newLocation);
    const resp = await getLocationDescription(newLocation!)
    setPlaceSelected(resp.placeSelected);
    setLocationDescription(resp.locationDescription)
  };

  // const getLocationDescription = async () => {
  //   const resp = await geocodeApi.get<IGeocodeGMaps>("", {
  //     params: {
  //       latlng: `${currentLocation.lat},${currentLocation.lng}`,
  //     },
  //   });
  //   const addressResp = resp.data.results.filter(
  //     (el) =>
  //       el.geometry.location_type === "GEOMETRIC_CENTER" &&
  //       !el.plus_code &&
  //       el.types.includes("route")
  //   );
  //   setPlaceSelected({
  //     addressPlace: addressResp[0].formatted_address.includes("Vía sin nombre")
  //       ? `${currentLocation.lat},${currentLocation.lng}`
  //       : addressResp[0].formatted_address,
  //     latLng: [currentLocation.lat, currentLocation.lng],
  //     namePlace: resp.data.plus_code.compound_code
  //       .split(" ")
  //       .filter((value, index) => index > 0)
  //       .join(" "),
  //   });
  //   setLocationDescription({
  //     name: resp.data.plus_code.compound_code
  //       .split(" ")
  //       .filter((value, index) => index > 0)
  //       .join(" "),
  //     description: addressResp[0].formatted_address.includes("Vía sin nombre")
  //       ? `${currentLocation.lat},${currentLocation.lng}`
  //       : addressResp[0].formatted_address,
  //   });
  // };

  // useEffect(() => {
  //   getLocationDescription();
  // }, [currentLocation]);

  useEffect(() => {
    if (location && isGetLocation) {
      setCurrentLocation(location)
    }
  }, [location])

  useEffect(() => {
    if (getLocation) {
      setIsGetLocation(true);
    }
  }, [getLocation])
  
  

  return !isLoaded ? (
    <div className="text-center">
      <div className="spinner-grow m-auto" role="status">
        <span className="visually-hidden">Localizando...</span>
      </div>
      <Typography>Localizando...</Typography>
    </div>
  ) : (
    <>
      <div className="places-container">
        <PlacesAutocomplete setSelected={setCurrentLocation} />
      </div>

      <GoogleMap
        id="marker-example"
        mapContainerClassName="map-container"
        zoom={13}
        center={currentLocation}
        onClick={(evt) => handleClickMap(evt)}
          options={{ mapTypeControl: false, streetViewControl: false }}
          
      >
        <Marker
          position={currentLocation}
          title={locationDescription.name}
          // animation={window.google.maps.Animation.BOUNCE}
        />
      </GoogleMap>
    </>
  );
};

interface PropsAutoComplete {
  setSelected: any;
}

const PlacesAutocomplete = ({ setSelected, }: PropsAutoComplete) => {

  const { userLocation } = useContext(PlacesContext);
  const { setPlaceSelected } = useContext(MapContext);

  const {
    // ready,
    value,
    setValue,
    suggestions: { status, data, loading },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      // location: new google.maps.LatLng(userLocation ? userLocation[0] : 44, userLocation ? userLocation[1] : 0.0),
      componentRestrictions: {country:'ec'}
    },
  });

  const handleSelect = async (address: string) => {
    setValue(address, false);
    clearSuggestions();

    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);

    setSelected({ lat, lng });

    const resp = await getLocationDescription({ lat, lng })
    setPlaceSelected(resp.placeSelected)
  };

  return (
    <div>
      <ThemeProvider theme={themeTxtFilled}>
        <TextField
          // label="Buscar lugar"
          placeholder="Buscar lugar"
          hiddenLabel
          variant="filled"
          value={value}
          name="searchMap"
          autoComplete="off"
          onChange={(e) => setValue(e.target.value)}
          size="small"
          // className="rounded-pill"
          sx={{ width: "13rem" }}
        />
      </ThemeProvider>

      <Collapse
        in={value.trim().length > 0 && data.length > 0 ? true : false}
        timeout="auto"
      >
        <List
          sx={{
            backgroundColor: "#f6f6f6",
            width: "13rem",
            maxHeight: "13rem",
            overflow: "auto",
          }}
        >
          {data.map(({ place_id, description }) => (
            <ListItem disablePadding key={place_id}>
              <ListItemButton onClick={() => handleSelect(description)}>
                <ThemeProvider theme={themeListItemIcon}>
                  <ListItemIcon className="">
                    <LocationOnIcon />
                  </ListItemIcon>
                </ThemeProvider>
                <div className="d-flex flex-column">
                  <Typography className="fw-bold" variant="body2">
                    {description}
                  </Typography>
                  {/* <Typography variant="caption">{place.place_name}</Typography> */}
                </div>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};
