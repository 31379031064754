import React from "react";
import BodyLayout from "../../components/shared/BodyLayout";

const Coordinators = () => {
  return (
    <BodyLayout>
      <div>Coordinators</div>
    </BodyLayout>
  );
};

export default Coordinators;
