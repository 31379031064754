import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useRef, useState } from "react";
import logo from "../../assets/logo.png";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PersonIcon from "@mui/icons-material/Person";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import SettingsIcon from "@mui/icons-material/Settings";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useNavigate } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

export interface ISubItems {
  name: string;
  icon?: JSX.Element | null;
  redirectTo: string;
  id: number;
}
export interface IListItemsMenu {
  name: string;
  icon: JSX.Element | null;
  redirectTo: string;
  id: number;
  subItems: ISubItems[];
}

const items: IListItemsMenu[] = [
  {
    name: "Solicitudes",
    icon: <PersonAddIcon />,
    redirectTo: "/admin/requests",
    id: 1,
    subItems: [],
  },
  {
    name: "Tours",
    icon: <CheckCircleIcon />,
    redirectTo: "/admin/savedTours",
    id: 2,
    subItems: [],
  },
  {
    name: "Usuarios",
    icon: <PersonIcon />,
    redirectTo: "/admin/users",
    id: 3,
    subItems: [],
  },
  {
    name: "Términos y C.",
    icon: <PlaylistAddCheckIcon />,
    redirectTo: "/admin/terms",
    id: 4,
    subItems: [],
  },
  {
    name: "Configuración",
    icon: <SettingsIcon />,
    redirectTo: "/admin/settings",
    id: 5,
    subItems: [
      {
        name: "Provincias",
        icon: null,
        redirectTo: "/admin/provinces",
        id: 6,
      },
      {
        name: "Tarifas",
        icon: null,
        redirectTo: "/admin/tariff",
        id: 7,
      },
      // {
      //   name: "Coordinadores",
      //   icon: null,
      //   redirectTo: "/admin/coordinators",
      //   id: 8,
      // },
      // {
      //   name: "Reseñas",
      //   icon: null,
      //   redirectTo: "/admin/reviews",
      //   id: 9,
      // },
      {
        name: "Autos",
        icon: null,
        redirectTo: "/admin/cars",
        id: 10,
      },
      {
        name: "Parámetros Bancarios",
        icon: null,
        redirectTo: "/admin/dataBankParams",
        id: 11,
      },
    ],
  },
];

const SideBar = () => {
  const navigate = useNavigate();

  const [activeItem, setActiveItem] = useState({ id: 1, active: true });
  const [isOpenSettings, setIsOpenSettings] = useState(false);

  const handleClickItem = (
    id: number,
    redirectTo: string,
    subItems: ISubItems[]
  ) => {
    if (subItems.length > 0) {
      setIsOpenSettings(!isOpenSettings);
    }
    setActiveItem({ id, active: true });
    if (id !== 5) navigate(redirectTo);
  };

  const renderItem = (item: IListItemsMenu, index: number) => {
    return (
      <div key={index}>
        <ListItem disablePadding>
          <ListItemButton
            onClick={() =>
              handleClickItem(item.id, item.redirectTo, item.subItems)
            }
          >
            <ListItemIcon>
              {activeItem.id === item.id && activeItem.active ? (
                <ChevronRightIcon sx={{ color: "var(--primary-color)" }} />
              ) : (
                item.icon
              )}
            </ListItemIcon>
            <ListItemText primary={item.name} />
            {item.subItems.length > 0 ? (
              <>{isOpenSettings ? <ExpandLess /> : <ExpandMore />}</>
            ) : null}
          </ListItemButton>
        </ListItem>
        <Collapse in={isOpenSettings} timeout="auto" unmountOnExit>
          {item.subItems.map((el, pos) => (
            <List component="div" disablePadding key={pos}>
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => handleClickItem(el.id, el.redirectTo, [])}
              >
                <ListItemIcon>
                  {activeItem.id === el.id && activeItem.active ? (
                    <ChevronRightIcon sx={{ color: "var(--primary-color)" }} />
                  ) : null}
                </ListItemIcon>
                <ListItemText primary={el.name} />
              </ListItemButton>
            </List>
          ))}
        </Collapse>
        <Divider />
      </div>
    );
  };

  return (
    <div className="sidebar">
      {/* <div style={{ width: "100%", height: "30%", textAlign: "center" }}>
        <img
          src={logo}
          alt="logo"
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
        />
      </div> */}

      <List
        // sx={{ overflow: "auto", maxHeight: "70%" }}
        className="list-menu w-100"
      >
        {items.map((el, index) => renderItem(el, index))}
      </List>
    </div>
  );
};

export default SideBar;
