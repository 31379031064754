import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AuthContext } from "../contexts/auth/AuthContext";
import ResetPassword from "../pages/public/ResetPassword";
import VerifiedUser from "../pages/public/VerifiedUser";
import ViewPackage from "../pages/admin/ViewPackage";
import ResetPasswordAdmin from "../pages/public/ResetPasswordAdmin";

const PublicAccess = () => {
  return (
    <div>
      <Routes>
        <Route path="confirm/:token" element={<VerifiedUser />} />
        <Route path="password/reset/:lng/:token" element={<ResetPassword />} />
        <Route path="admin/reset/pass" element={<ResetPasswordAdmin />} />
        <Route path="hello" element={<div>hola</div>} />
        <Route path="viewPackage/:id" element={<ViewPackage />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's no match public access URL!</p>
            </main>
          }
        />
      </Routes>
    </div>
  );
};

export default PublicAccess;
