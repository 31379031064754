import { ThemeProvider } from "@emotion/react";
import {
  Typography,
  Button,
  Divider,
  TextField,
  createTheme,
} from "@mui/material";
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import BodyLayout from "../../components/shared/BodyLayout";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { ToastContext } from "../../contexts/toast/ToastContext";
import { useForm } from "../../hooks/useForm";
import useTariff from "../../hooks/useTariff";
import { IResDataTariff } from "../../interfaces/ITariff";

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          transform: "scale(1)",
          transition: "transform 0.05s",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
            // transform: "scale(1.03)",
            transition: "transform 0.05s",
          },
        },
      },
    },
  },
});

const initTariff: IResDataTariff = {
  id: 0,
  idUser: 0,
  idTypeService: 0,
  costKm: 0,
  iva: 0,
  costExtraKm: 0,
  baseKm: 0,
  contact: "",
};

const Tariff = () => {
  const { onOpenAbortAlert } = useContext(AlertContext);
  const { onOpenToast } = useContext(ToastContext);

  const { getTariff, postPutTariff } = useTariff();

  const {
    form: formDelivery,
    onChange: onChangeDelivery,
    updateForm: updateFormDelivery,
  } = useForm<IResDataTariff>(initTariff);

  const {
    form: formTransport,
    onChange: onChangeTransport,
    updateForm: updateFormTransport,
  } = useForm(initTariff);

  const {
    form: formTour,
    onChange: onChangeTour,
    updateForm: updateFormTour,
  } = useForm(initTariff);

  const getData = async () => {
    const resp = await getTariff();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.data) {
        if (resp.data.length > 0) {
          const delivery = resp.data.find((el) => el.idTypeService === 1);
          const transport = resp.data.find((el) => el.idTypeService === 2);
          const tour = resp.data.find((el) => el.idTypeService === 3);
          updateFormDelivery(delivery ? delivery : initTariff);
          updateFormTransport(transport ? transport : initTariff);
          updateFormTour(tour ? tour : initTariff);
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const saveData = async () => {
    const validateTransport = () => {
      return new Promise((resolve, reject) => {
        const values = Object.values(formTransport);
        for (const value of values) {
          if (parseInt(value) <= 0) {
            reject("No puede guardar datos iguales a cero para el Transporte.");
          } else if (value.toString().trim().length === 0) {
            reject("No puede guardar campos vacíos en el Transporte.");
          }
        }
        resolve(formTransport);
      });
    };

    const validateDelivery = () => {
      return new Promise((resolve, reject) => {
        const values = Object.values(formDelivery);
        for (const value of values) {
          if (parseInt(value) <= 0) {
            reject("No puede guardar datos iguales a cero para el Delivery.");
          } else if (value.toString().trim().length === 0) {
            reject("No puede guardar campos vacíos en el Delivery.");
          }
        }
        resolve(formDelivery);
      });
    };

    const validateTour = () => {
      return new Promise((resolve, reject) => {
        if (parseInt(formTour.iva.toString()) <= 0) {
          reject("No puede guardar datos iguales a cero para los Tours.");
        } else if (formTour.iva.toString().trim().length === 0) {
          reject("No puede guardar campos vacíos en los Tours.");
       }else{
        resolve(formTour.iva)
       }
      });
    };

    validateTransport()
      .then(() => validateDelivery())
      .then(() => validateDelivery())
      .then(() => validateTour())
      .then(async () => {
        const resp = await postPutTariff(formDelivery, formTransport, formTour);
        if (resp) {
          if (resp.aborted) {
            onOpenAbortAlert({});
            return;
          }
          onOpenToast("success", "Guardado", "");
        } else {
          onOpenToast(
            "error",
            "Error",
            "Ha ocurrido un error, vuelva a intentarlo mas tarde :("
          );
        }
      })
      .catch((err: string) => {
        onOpenToast("warn", "", err);
      });
  };

  return (
    <BodyLayout>
      <div className="d-flex flex-wrap gap-4">
        <div className="d-flex align-items-center flex-grow-1 flex-wrap">
          <Typography variant="h5" className="flex-grow-1 fw-bold">
            Tarifas
          </Typography>
        </div>
      </div>
      <Divider sx={{ margin: "2rem", borderColor: "var(--primary-color)" }} />
      <div className="flex-grow-1 d-flex align-items-center gap-5 flex-column w-75 mx-auto">
        <div className="w-100 mb-1">
          <Typography variant="h5" className="mb-2">
            Transporte
          </Typography>
          <div className="d-flex gap-4 flex-wrap w-100">
            <div className="flex-grow-1 d-flex gap-3 flex-column ">
              <div>
                <Typography>Km Base de Transporte</Typography>
                <TextField
                  hiddenLabel
                  value={formTransport.baseKm.toString()}
                  name="baseKm"
                  variant="filled"
                  fullWidth
                  onChange={onChangeTransport}
                  placeholder="Precio por kilómetros"
                />
              </div>
              <div>
                <Typography>Costo de Transporte por Km</Typography>
                <TextField
                  hiddenLabel
                  value={formTransport.costKm.toString()}
                  name="costKm"
                  variant="filled"
                  fullWidth
                  onChange={onChangeTransport}
                  placeholder="Precio por kilómetros"
                />
              </div>
            </div>
            <div className="flex-grow-1 d-flex gap-3 flex-column ">
              <div>
                <Typography>Costo de Transporte por Km Extra</Typography>
                <TextField
                  hiddenLabel
                  value={formTransport.costExtraKm.toString()}
                  name="costExtraKm"
                  variant="filled"
                  fullWidth
                  onChange={onChangeTransport}
                  placeholder="Precio por kilómetros"
                />
              </div>
              <div>
                <Typography>Impuesto</Typography>
                <TextField
                  hiddenLabel
                  value={formTransport.iva.toString()}
                  name="iva"
                  variant="filled"
                  fullWidth
                  onChange={onChangeTransport}
                  placeholder="12%"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-100 mb-1">
          <Typography variant="h5" className="mb-2">
            Delivery
          </Typography>
          <div className="d-flex gap-4 flex-wrap w-100">
            <div className="flex-grow-1 d-flex gap-3 flex-column ">
              <div>
                <Typography>Km Base del Delivery</Typography>
                <TextField
                  hiddenLabel
                  value={formDelivery.baseKm.toString()}
                  name="baseKm"
                  variant="filled"
                  fullWidth
                  onChange={onChangeDelivery}
                  placeholder="Precio por kilómetros"
                />
              </div>
              <div>
                <Typography>Costo del Delivery por Km</Typography>
                <TextField
                  hiddenLabel
                  value={formDelivery.costKm.toString()}
                  name="costKm"
                  variant="filled"
                  fullWidth
                  onChange={onChangeDelivery}
                  placeholder="Precio por kilómetros"
                />
              </div>
            </div>
            <div className="flex-grow-1 d-flex gap-3 flex-column ">
              <div>
                <Typography>Costo del Delivery por Km Extra</Typography>
                <TextField
                  hiddenLabel
                  value={formDelivery.costExtraKm.toString()}
                  name="costExtraKm"
                  variant="filled"
                  fullWidth
                  onChange={onChangeDelivery}
                  placeholder="Precio por kilómetros"
                />
              </div>
              <div>
                <Typography>Impuesto</Typography>
                <TextField
                  hiddenLabel
                  value={formDelivery.iva.toString()}
                  name="iva"
                  variant="filled"
                  fullWidth
                  onChange={onChangeDelivery}
                  placeholder="12%"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="w-100">
          <Typography variant="h5" className="mb-2">
            Tours
          </Typography>
          <div className="d-flex gap-4 flex-wrap w-100">
            <div className="flex-grow-1 d-flex gap-3 flex-row ">
              <div className="flex-grow-1" style={{ maxWidth: "50%" }}>
                <Typography>Impuesto</Typography>
                <TextField
                  hiddenLabel
                  value={
                    formTour.iva.toString() === "0"
                      ? ""
                      : formTour.iva.toString()
                  }
                  name="iva"
                  variant="filled"
                  fullWidth
                  onChange={onChangeTour}
                  placeholder="12%"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end w-100">
          <ThemeProvider theme={themeBtnDftActive}>
            <Button variant="contained" onClick={saveData}>
              <Typography>Guardar</Typography>
            </Button>
          </ThemeProvider>
        </div>
      </div>
    </BodyLayout>
  );
};

export default Tariff;
