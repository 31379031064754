import { Typography, Button, Divider, createTheme, Box, IconButton, TableCell, TableHead, TableRow, TableSortLabel, Paper, Switch, Table, TableBody, TableContainer, TablePagination, ThemeProvider, Grow } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import BodyLayout from '../../components/shared/BodyLayout';
import { visuallyHidden } from '@mui/utils';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useTheme } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import DeleteIcon from '@mui/icons-material/Delete';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
import usePackages from '../../hooks/usePackages';
import { AlertContext } from '../../contexts/alert/AlertContext';
import { IResPostPutPackage } from '../../interfaces/IPackages';
import { ToastContext } from '../../contexts/toast/ToastContext';
import { TransitionGroup } from 'react-transition-group';
import ModalWebPage from '../../components/modals/ModalWebPage';
import { BKG_TABLE_HEADER, COLOR_LOADING, UNHANDLED_ERROR } from '../../helpers/consants';
import { RotateSpinner } from 'react-spinners-kit';
import { ConfirmPopUpContext } from '../../contexts/confirmPopUp/ConfirmPopUpContext';
import CommentIcon from '@mui/icons-material/Comment';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const URL_TO_MODAL_PAGE = 'https://administrator.equator-ian.com/account/viewPackage/';

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: '0.7rem 1.5rem',
          background: 'var(--primary-color)',
          color: 'HighlightText',
          transform: 'scale(1)',
          transition: 'transform 0.05s',
          ':hover': {
            background: 'var(--primary-color)',
            color: 'HighlightText',
            // transform: "scale(1.03)",
            transition: 'transform 0.05s',
          },
        },
      },
    },
  },
});

const themeLblHeadTbl = createTheme({
  components: {
    // Name of the component
    MuiTableSortLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // textAlign: 'center',
        },
        icon: {
          // display: "none",
          // ":hover": {
          //   display: "none",
          // },
        },
      },
    },
  },
});

interface Data {
  no: number;
  name: string;
  rate: number;
  actions: any;
  status: string;
}

const NAVIGATE_TO_NEW_PACKAGE = '/admin/savedTours/newPackage';

const SavedTours = () => {
  const [isOpenModalPage, setIsOpenModalPage] = useState(false);
  const [urlToModalPage, setUrlToModalPage] = useState('');

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('no');
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const { onOpenAbortAlert } = useContext(AlertContext);
  const { onOpenToast } = useContext(ToastContext);
  const { onOpenPopUp } = useContext(ConfirmPopUpContext);

  const [isFetching, setIsFetching] = useState(false);
  const [packageList, setPackageList] = useState<Data[]>([]);
  const { getAllPackages, putStatusPackage, deletePackage } = usePackages();
  const [updateStatusProm, setUpdateStatusProm] = useState<Promise<IResPostPutPackage | null>[]>([]);

  const getData = async () => {
    setIsFetching(true);
    const resp = await getAllPackages();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }

      setPackageList(
        resp.data?.rows
          .map((el) => {
            return {
              no: el.id,
              name: el.name,
              rate: el.average,
              actions: 0,
              status: el.status,
            };
          })
          .filter((el) => el !== undefined) || []
      );
    } else {
      onOpenToast('error', 'Error', UNHANDLED_ERROR);
    }
    setIsFetching(false);
  };

  useEffect(() => {
    getData();
  }, []);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = packageList.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
  }

  function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label="first page">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="next page">
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1} aria-label="last page">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - packageList.length) : 0;

  const navigate = useNavigate();

  const handleClickBtnNewPackage = () => {
    navigate(NAVIGATE_TO_NEW_PACKAGE, {
      state: { row: undefined, watch: false },
    });
  };

  const handleChangeStatus = async (idRow: number, status: string) => {
    setUpdateStatusProm([
      ...updateStatusProm,
      putStatusPackage({
        idPackage: idRow,
        status: status === 'accepted' ? 'rejected' : 'accepted',
      }),
    ]);
    // const resp = await putStatusPackage({
    //   idPackage: idRow,
    //   status: status === "accepted" ? "rejected" : "accepted",
    // });
    // if (resp) {
    //   if (resp.aborted) {
    //     onOpenAbortAlert({});
    //     return;
    //   }
    //   getData();
    // }
  };

  const handlePressDeleteRow = async (idRow: number) => {
    onOpenPopUp(
      async () => {
        const resp = await deletePackage(idRow);
        if (resp) {
          if (resp.aborted) {
            onOpenAbortAlert({});
            return;
          }
          const filteredPackages = packageList.filter((el) => el.no !== idRow);
          setPackageList(filteredPackages);
        } else {
          onOpenToast('error', '', UNHANDLED_ERROR);
        }
      },
      '¿Está seguro que desea proceder?',
      `btn-delete-${idRow}`
    );
  };

  const handleUpdateRow = (index: number) => {
    navigate(NAVIGATE_TO_NEW_PACKAGE, { state: { row: index, watch: false } });
  };

  const handelWatchRow = (url: string) => {
    // navigate(NAVIGATE_TO_NEW_PACKAGE, { state: { row: index, watch: true } });
    setIsOpenModalPage(true);
    setUrlToModalPage(url);
  };

  const handleBtnWatchReviews = (id: number, name: string) => {
    navigate('/admin/reviews', { state: { id, name, typeReview: 'tour' } });
  };

  useEffect(() => {
    if (updateStatusProm.length > 0) {
      Promise.all(updateStatusProm).then((resp) => {
        resp.forEach((res, index) => {
          if (res) {
            if (res.aborted) {
              onOpenAbortAlert({});
              return;
            }
            // console.log(res?.data || null);
            const newRows: Data[] = packageList.map((el) => {
              if (res.data) {
                if (el.no === res.data.id) {
                  return {
                    ...el,
                    status: res.data.status,
                  };
                }
              }
              return el;
            });
            setPackageList(newRows);
          } else {
            onOpenToast('error', '', UNHANDLED_ERROR);
            return;
          }
          const aux = updateStatusProm.filter((value, pos) => pos !== index);
          setUpdateStatusProm(aux);
        });
      });
    }
  }, [updateStatusProm]);

  return (
    <BodyLayout>
      <div className="d-flex flex-wrap gap-4">
        <div className="d-flex align-items-center flex-grow-1 flex-wrap">
          <Typography variant="h5" className="flex-grow-1 fw-bold">
            Tours Guardados
          </Typography>
        </div>
        <div className="">
          <ThemeProvider theme={themeBtnDftActive}>
            <Button variant="contained" onClick={handleClickBtnNewPackage}>
              Añadir paquete
            </Button>
          </ThemeProvider>
        </div>
      </div>
      <Divider sx={{ margin: '2rem', borderColor: 'var(--primary-color)' }} />
      <Box sx={{ width: '100%', maxHeight: '75vh' }}>
        <Paper sx={{ width: '80%', mb: 2 }} className="mx-auto">
          {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
          <TableContainer sx={{ maxHeight: '65vh', maxWidth: '100%' }}>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium" stickyHeader aria-label="sticky table">
              <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={packageList.length} />
              <TableBody className="" sx={{ width: '100%' }}>
                <TransitionGroup className="pivote" component={null}>
                  {stableSort(packageList, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      // const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <Grow key={index} in={false}>
                          <TableRow
                            hover
                            // onClick={(event) => handleClick(event, row.name)}
                            tabIndex={-1}
                            key={index}
                          >
                            <TableCell align="center">{index + 1}</TableCell>
                            <TableCell align="left">{row.name}</TableCell>
                            <TableCell align="center">{row.rate > 0 ? <Rating name="disabled" value={row.rate} disabled sx={{ color: 'var(--primary-color)' }} /> : 'S/C'}</TableCell>
                            <TableCell align="center">
                              <div className="d-flex gap-1 justify-content-center">
                                <IconButton onClick={() => handlePressDeleteRow(row.no)} id={`btn-delete-${row.no}`}>
                                  <DeleteIcon sx={{ width: 20, height: 20 }} />
                                </IconButton>
                                <IconButton onClick={() => handleUpdateRow(row.no)}>
                                  <BorderColorIcon sx={{ width: 20, height: 20 }} />
                                </IconButton>
                                <IconButton onClick={() => handelWatchRow(`${URL_TO_MODAL_PAGE}${row.no}`)}>
                                  <RemoveRedEyeIcon sx={{ width: 20, height: 20 }} />
                                </IconButton>
                                <IconButton onClick={() => handleBtnWatchReviews(row.no, row.name)}>
                                  <CommentIcon sx={{ width: 20, height: 20 }} />
                                </IconButton>
                                <Switch {...label} onClick={() => handleChangeStatus(row.no, row.status)} checked={row.status === 'accepted' ? true : false} />
                              </div>
                            </TableCell>
                          </TableRow>
                        </Grow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 60 * emptyRows,
                      }}
                      className="border border-danger"
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TransitionGroup>
              </TableBody>
            </Table>
            {isFetching && (
              <div className="w-100 d-flex justify-content-center mt-5 mb-2">
                <RotateSpinner size={70} color={COLOR_LOADING} loading={isFetching} />
              </div>
            )}
          </TableContainer>
          <div className="d-flex justify-content-center w-100 p-4">
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Todo', value: -1 }]}
              colSpan={3}
              count={packageList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="Mostrar"
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              className=""
            />
          </div>
        </Paper>
      </Box>
      <ModalWebPage url={urlToModalPage} open={isOpenModalPage} onClose={() => setIsOpenModalPage(false)} />
    </BodyLayout>
  );
};

export default SavedTours;

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: any }, b: { [key in Key]: any }) => number {
  return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'no',
    numeric: true,
    disablePadding: false,
    label: 'No',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Nombre',
  },
  {
    id: 'rate',
    numeric: false,
    disablePadding: false,
    label: 'Calificación',
  },
  {
    id: 'actions',
    numeric: false,
    disablePadding: false,
    label: 'Acciones',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {  order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow
        sx={{
          '& th': {
            backgroundColor: BKG_TABLE_HEADER,
          },
        }}
      >
        {headCells.map((headCell) => (
          <TableCell key={headCell.id} align={headCell.id === 'actions' || headCell.id === 'no' || headCell.id === 'rate' ? 'center' : 'justify'} padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false}>
            <ThemeProvider theme={themeLblHeadTbl}>
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)}>
                {headCell.label}
                {orderBy === 'actions' ? null : (
                  <>
                    {orderBy === headCell.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </>
                )}
              </TableSortLabel>
            </ThemeProvider>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
