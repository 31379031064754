import {
  Button,
  createTheme,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  ThemeProvider,
  Typography,
  Box,
  Avatar,
  Link,
  CircularProgress,
  Fade,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  OutlinedInput,
  Zoom,
} from "@mui/material";
import React, {
  useEffect,
  useState,
  useContext,
  ChangeEvent,
  ChangeEventHandler,
} from "react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MainLayout from "../../components/shared/MainLayout";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import ReorderIcon from "@mui/icons-material/Reorder";
import AddRoadIcon from "@mui/icons-material/AddRoad";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import BodyLayout from "../../components/shared/BodyLayout";
import useRequests from "../../hooks/useRequests";
import { IDataRequestRow, IResDataRequests } from "../../interfaces/IRequests";
import moment from "moment";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { ToastContext } from "../../contexts/toast/ToastContext";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import useReports from "../../hooks/useReports";
import { AlertContext } from "../../contexts/alert/AlertContext";
import { COLOR_LOADING, UNHANDLED_ERROR } from "../../helpers/consants";
import SaveFile from "../../helpers/saveFile";
import noDataFound from "../../assets/no-data-found.svg";
import { RotateSpinner } from "react-spinners-kit";
import useUsers from "../../hooks/useUsers";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import emptyData from "../../assets/empty-data.png";
import OneSignal from "react-onesignal";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

const listButtons = [
  { id: 1, name: "Todo" },
  { id: 2, name: "Delivery" },
  { id: 3, name: "Transporte" },
  { id: 4, name: "Tour" },
];

const listPaymentMethods = [
  { id: 1, value: "cash", label: "Efectivo" },
  { id: 2, value: "transfer", label: "Transferencia" },
  { id: 3, value: "creditcard", label: "Tarjeta de Crédito" },
  { id: 4, value: "deferred", label: "Diferido" },
];

const listStates = [
  { id: 1, value: "pending", label: "Pendiente" },
  { id: 2, value: "revision", label: "Revisión" },
  { id: 3, value: "accepted", label: "Aceptado" },
  { id: 4, value: "cancel", label: "Cancelado" },
  { id: 5, value: "onway", label: "En camino" },
  { id: 6, value: "completed", label: "Completado" },
];

const themeBtnDft = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--color-dft-btn)",
          color: "ButtonText",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
          },
        },
      },
    },
  },
});

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          transform: "scale(1)",
          transition: "transform 0.2s",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
            transform: "scale(1.03)",
            transition: "transform 0.2s",
          },
        },
      },
    },
  },
});

const themeBtnActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          // transform: "scale(1)",
          // transition: "transform 0.2s",
          // ":hover": {
          //   background: "var(--primary-color)",
          //   color: "HighlightText",
          //   transform: "scale(1.03)",
          //   transition: "transform 0.2s",
          // },
        },
      },
    },
  },
});

const themeBtnSmallActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          background: "var(--primary-color)",
          color: "HighlightText",
        },
      },
    },
  },
});

const themeAvatarItem = createTheme({
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          border: "4px solid var(--color-border1)",
        },
      },
    },
  },
});

const getState = (status: string) => {
  switch (status) {
    case "cancel":
      return "Cancelado";
    case "pending":
      return "Pendiente";
    case "revision":
      return "Revisión";
    case "accepted":
      return "Aceptado";
    case "onway":
      return "En curso";
    case "completed":
      return "Completado";
    case "arrived":
      return "Esperando pago";

    default:
      return status;
  }
};

export type TRequests = "all" | "delivery" | "transport" | "package";

const listMonths = [
  // { id: 0, value: "anything", label: "Ninguno" },
  { id: 1, value: "january", label: "Enero" },
  { id: 2, value: "february", label: "Febrero" },
  { id: 3, value: "march", label: "Marzo" },
  { id: 4, value: "april", label: "Abril" },
  { id: 5, value: "may", label: "Mayo" },
  { id: 6, value: "june", label: "Junio" },
  { id: 7, value: "july", label: "Julio" },
  { id: 8, value: "august", label: "Agosto" },
  { id: 9, value: "september", label: "Septiembre" },
  { id: 10, value: "october", label: "Octubre" },
  { id: 11, value: "november", label: "Noviembre" },
  { id: 12, value: "december", label: "Diciembre" },
];

interface IYear {
  id: number;
  value: number;
  label: string;
}

const getYearsList = (): IYear[] => {
  let listYears: IYear[] = [];
  for (let initYear = 2022; initYear <= new Date().getFullYear(); initYear++) {
    listYears.push({
      id: initYear,
      value: initYear,
      label: initYear.toString(),
    });
  }
  return listYears;
};

const listYears: IYear[] = getYearsList();

export interface IDriversCmb {
  id: number;
  value: string | number;
  label: string;
}

const Requests = () => {
  const { onOpenAbortAlert } = useContext(AlertContext);
  const { onOpenToast } = useContext(ToastContext);

  const { getReport } = useReports();
  const { getRequests, putRequest, putRequestTransport } = useRequests();
  const { handleDownload } = SaveFile();
  const { getDriversGuide, getDriversTransport } = useUsers();

  const [activeBtn, setActiveBtn] = useState({ id: 1, active: true });
  const [wayPay, setWayPay] = React.useState({ id: 0, name: "" });
  const [state, setState] = React.useState({ value: "0", name: "" });

  const [typeReq, setTypeReq] = useState<TRequests>("all");
  const [initFrom, setInitFrom] = useState(0);
  const endTo = 25;
  const [reqList, setReqList] = useState<IDataRequestRow[]>([]);
  const [reqListPivot, setReqListPivot] = useState<IDataRequestRow[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const [selectedMonth, setSelectedMonth] = useState(() => {
    const month = new Date().getMonth() + 1;
    return month.toString();
  });
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [openModal, setOpenModal] = useState(false);
  const [urlComprobante, setUrlComprobante] = useState("");

  const [driversList, setDriversList] = useState<IDriversCmb[]>([]);

  const getDriversGuideList = async () => {
    const resp = await getDriversGuide();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.data) {
        const rows: IDriversCmb[] = resp.data.map((el) => {
          return {
            id: el.id,
            value: el.id,
            label: el.fullname,
          };
        });
        setDriversList(rows);
      }
    }
  };

  const getDriversAvailable = async (): Promise<IDriversCmb[]> => {
    const resp = await getDriversGuide();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return [];
      }
      if (resp.data) {
        const rows: IDriversCmb[] = resp.data.map((el) => {
          return {
            id: el.id,
            value: el.id,
            label: el.fullname,
          };
        });
        //setDriversList(rows);
        return rows;
      }
    }
    return [];
  };

  const getDriversTransportAvailable = async (): Promise<IDriversCmb[]> => {
    const resp = await getDriversTransport();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return [];
      }
      if (resp.data) {
        const rows: IDriversCmb[] = resp.data.map((el) => {
          return {
            id: el.id,
            value: el.id,
            label: el.fullname,
          };
        });
        //setDriversList(rows);
        return rows;
      }
    }
    return [];
  };

  const getRequestsList = async () => {
    setIsFetching(reqList.length === 0 ? true : false);
    setIsLoading(reqList.length > 0 ? true : false);
    let driversGuide: IDriversCmb[] = await getDriversAvailable();
    let driversTransport: IDriversCmb[] = await getDriversTransportAvailable();
    const resp = await getRequests(
      {
        from: initFrom,
        to: endTo,
        typeReq: typeReq,
      },
      {
        idPayment: wayPay.id,
        month: selectedMonth,
        status: state.value,
        year: selectedYear,
      }
    );
    setIsFetching(false);
    setIsLoading(false);
    if (!resp) {
      // console.log("invalid resp");
      // clean();
      onOpenToast("info", "", "Lista completa");
      return;
    }
    if (resp?.rows) {
      if (resp.rows.length > 0) {
        const newRows: IDataRequestRow[] = resp.rows.map((el) => {
          return {
            ...el,
            driversGuide,
            selectedDriverGuide: el.userInChargeId
              ? el.userInChargeId.toString()
              : "0",
            disabled: el.status !== "pending" ? true : false,

            driversTransport,
            selectedDriverTransport: el.userInChargeId
              ? el.userInChargeId.toString()
              : "0",
            disabledTransport: el.status !== "pending" ? true : false,
          };
        });
        if (reqList.length === 0) {
          setReqList(newRows);
          setReqListPivot(newRows);
        } else {
          setReqList([...reqList, ...newRows]);
          setReqListPivot([...reqList, ...newRows]);
        }
        setInitFrom(initFrom + endTo);
        return;
      }
    }
  };

  const fetchMoreData = async () => {
    await getRequestsList();
  };

  const clean = () => {
    setReqList([]);
    setReqListPivot([]);
    setInitFrom(0);
  };

  useEffect(() => {
    getRequestsList();
    getDriversGuideList();
  }, [typeReq]);

  useEffect(() => {
    clean();
    switch (activeBtn.id) {
      case 1:
        setTypeReq("all");
        break;
      case 2:
        setTypeReq("delivery");
        break;
      case 3:
        setTypeReq("transport");
        break;
      case 4:
        setTypeReq("package");
        break;

      default:
        // console.log("TIPO DE SOLICITUD INVALIDO");
        break;
    }
  }, [activeBtn.id]);

  useEffect(() => {
    clean();
    getRequestsList();
  }, [wayPay.id, state.value, selectedMonth, selectedYear]);

  const handleChangeWayPay = (event: SelectChangeEvent) => {
    clean();
    setWayPay({ id: parseInt(event.target.value.toString()), name: "" });
  };

  const handleChangeState = (event: SelectChangeEvent) => {
    clean();
    setState({ value: event.target.value, name: event.target.value });
  };

  const handleChangeMonth = (event: SelectChangeEvent) => {
    clean();
    setSelectedMonth(event.target.value);
  };

  const handleChangeYear = (event: SelectChangeEvent) => {
    clean();
    setSelectedYear(event.target.value);
  };

  const handleChangeDriverPerRow = (
    event: SelectChangeEvent,
    idReq: number
  ) => {
    const newRows = reqList.map((el) => {
      if (el.id === idReq) {
        return {
          ...el,
          selectedDriver: event.target.value,
        };
      } else {
        return el;
      }
    });
    setReqList(newRows);
  };

  const handleChangePrice = (
    evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idReq: number
  ) => {
    const newRows = reqList.map((el) => {
      if (el.id === idReq) {
        return {
          ...el,
          priceExtra: evt.target.value,
        };
      } else {
        return el;
      }
    });
    setReqList(newRows);
  };

  const handleClickAddPriceExtra = (idReq: number) => {
    const newRows = reqList.map((el) => {
      if (el.id === idReq) {
        return {
          ...el,
          showTxtPriceExtra: true,
        };
      } else {
        return el;
      }
    });
    setReqList(newRows);
  };

  const handleClickBtn = (id: number) => {
    setActiveBtn({ id, active: true });
  };

  const handleClickAcceptReq = async (request: IDataRequestRow) => {
    if (request.status === "accepted") {
      onOpenToast("info", "", "La orden ya ha sido aceptada");
      return;
    } else if (request.status === "cancel") {
      onOpenToast(
        "warn",
        "",
        "No se puede aceptar una solicitud previamente cancelada"
      );
      return;
    }
    // if (request.priceExtra.length > 0) {
    if (
      request.selectedDriver &&
      request.selectedDriver.trim().length > 0 &&
      parseInt(request.selectedDriver) > 0
    ) {
      const resp = await putRequest({
        accepted: true,
        price: parseFloat(request.priceExtra) || 0,
        requestId: request.id,
        userInChargeId: parseInt(request.selectedDriver),
      });
      if (resp) {
        if (resp.aborted) {
          onOpenAbortAlert({});
          return;
        }
        if (resp.ok) {
          onOpenToast("success", "", "Solicitud aceptada");
          const newRows: IDataRequestRow[] = reqList.map((el) => {
            if (el.id === request.id) {
              return {
                ...el,
                status: "accepted",
                total: el.total + parseFloat(request.priceExtra || "0"),
              };
            } else {
              return el;
            }
          });
          setReqList(newRows);
        }
      } else {
        onOpenToast("error", "Error", UNHANDLED_ERROR);
      }
    } else {
      onOpenToast("warn", "", "Debe seleccionar un conductor guía");
    }
    // } else {
    //   onOpenToast('warn', '', 'Debe agregar el precio extra')
    // }
  };

  const handleClickAcceptReqTransport = async (request: IDataRequestRow) => {
    if (request.status === "accepted") {
      onOpenToast("info", "", "La orden ya ha sido aceptada");
      return;
    } else if (request.status === "cancel") {
      onOpenToast(
        "warn",
        "",
        "No se puede aceptar una solicitud previamente cancelada"
      );
      return;
    }
    // if (request.priceExtra.length > 0) {
    if (
      request.selectedDriver &&
      request.selectedDriver.trim().length > 0 &&
      parseInt(request.selectedDriver) > 0
    ) {
      const resp = await putRequestTransport({
        accepted: true,
        price: parseFloat(request.priceExtra) || 0,
        requestId: request.id,
        userInChargeId: parseInt(request.selectedDriver),
      });
      if (resp) {
        if (resp.aborted) {
          onOpenAbortAlert({});
          return;
        }
        if (resp.ok) {
          onOpenToast("success", "", "Solicitud aceptada");
          const newRows: IDataRequestRow[] = reqList.map((el) => {
            if (el.id === request.id) {
              return {
                ...el,
                status: "accepted",
                total: el.total + parseFloat(request.priceExtra || "0"),
              };
            } else {
              return el;
            }
          });
          setReqList(newRows);
        }
      } else {
        onOpenToast("error", "Error", UNHANDLED_ERROR);
      }
    } else {
      onOpenToast("warn", "", "Debe seleccionar un conductor de transporte");
    }
    // } else {
    //   onOpenToast('warn', '', 'Debe agregar el precio extra')
    // }
  };

  const handleClickRejectReq = async (request: IDataRequestRow) => {
    if (request.status === "cancel") {
      onOpenToast("info", "", "La orden ya ha sido cancelada");
      return;
    }
    const resp = await putRequest({
      accepted: false,
      price: parseFloat(request.priceExtra) || 0,
      requestId: request.id,
      userInChargeId: parseInt(request.selectedDriver) || 0,
    });
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.ok) {
        onOpenToast("success", "", "Solicitud cancelada");
        const newRows: IDataRequestRow[] = reqList.map((el) => {
          if (el.id === request.id) {
            return {
              ...el,
              status: "cancel",
              total: el.total + parseFloat(request.priceExtra || "0"),
            };
          } else {
            return el;
          }
        });
        setReqList(newRows);
      }
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
  };

  const handleClickRejectReqTransport = async (request: IDataRequestRow) => {
    if (request.status === "cancel") {
      onOpenToast("info", "", "La orden ya ha sido cancelada");
      return;
    }
    const resp = await putRequestTransport({
      accepted: false,
      price: parseFloat(request.priceExtra) || 0,
      requestId: request.id,
      userInChargeId: parseInt(request.selectedDriver) || 0,
    });
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.ok) {
        onOpenToast("success", "", "Solicitud cancelada");
        const newRows: IDataRequestRow[] = reqList.map((el) => {
          if (el.id === request.id) {
            return {
              ...el,
              status: "cancel",
              total: el.total + parseFloat(request.priceExtra || "0"),
            };
          } else {
            return el;
          }
        });
        setReqList(newRows);
      }
    } else {
      onOpenToast("error", "Error", UNHANDLED_ERROR);
    }
  };

  const handleClickDownloadReport = async () => {
    // setShowCalendar(!showCalendar);
    const resp = await getReport(
      {
        idPayment: wayPay.id,
        month: parseInt(selectedMonth) || 0,
        year: parseInt(selectedYear) || 0,
      },
      typeReq
    );
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({});
        return;
      }
      if (resp.data) {
        const res = await handleDownload(resp.data);
        if (res.error) {
          onOpenToast(
            "error",
            "Error",
            "Ocurrió un error al recuperar el archivo, inténtelo de nuevo más tarde"
          );
        }
      } else {
        // console.log("no hay datos");
      }
    } else {
      onOpenToast("error", "", UNHANDLED_ERROR);
    }
  };

  const handleClickSendNotification = async () => {
    // console.log("hola");
    OneSignal.sendTag("Titulo", "Hola mundo").then(() => {
      // console.log("notificacion enviada");
    });
  };

  // const handleDate = async (date: Date | Date[] | undefined) => {
  //   console.log("date==> ", date);
  //   if (wayPay.id > 0 && date instanceof Date) {
  //     const resp = await getReport(
  //       {
  //         idPayment: wayPay.id,
  //         month: date.getMonth() + 1,
  //         year: date.getFullYear(),
  //       },
  //       typeReq
  //     );
  //     if (resp) {
  //       if (resp.aborted) {
  //         onOpenAbortAlert({});
  //         return;
  //       }
  //       if (resp.data) {
  //         const res = await handleDownload(resp.data);
  //         if (res.error) {
  //           onOpenToast(
  //             "error",
  //             "Error",
  //             "Ocurrió un error al recuperar el archivo, inténtelo de nuevo más tarde"
  //           );
  //         }
  //       } else {
  //         console.log("no hay datos");
  //       }
  //     } else {
  //       onOpenToast("error", "", UNHANDLED_ERROR);
  //     }
  //   } else {
  //     onOpenToast("warn", "", "Debe seleccionar el método de pago.");
  //   }

  //   setShowCalendar(false);
  // };

  const renderButton = (el: { id: number; name: string }) => {
    return (
      <ThemeProvider theme={themeBtnDft} key={el.id}>
        <Button
          variant="contained"
          key={el.id}
          onClick={() => handleClickBtn(el.id)}
          sx={{
            background:
              activeBtn.id === el.id && activeBtn.active
                ? "var(--primary-color)"
                : "var(--color-dft-btn)",
            color:
              activeBtn.id === el.id && activeBtn.active
                ? "HighlightText"
                : "ButtonText",
          }}
        >
          {el.name}
        </Button>
      </ThemeProvider>
    );
  };

  const renderReq = (el: IDataRequestRow, index: number) => {
    return (
      <div
        className={`request-item d-flex justify-content-evenly ${
          el.typeRequest &&
          el.typeRequest === "personalized" &&
          el.idTypeService === 2
            ? "request-item-custom"
            : ""
        }`}
        key={index}
      >
        {/* <Typography variant= 'h5' color='red' sx={{position: 'absolute'}}>{ el.id}</Typography> */}
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            width: "25%",
            //flexGrow: 1,
            // flexShrink: 5,
          }}
          className=""
        >
          <Box
            sx={{
              display: "flex",
              gap: "0.5rem",
              flexDirection: "column",
              width: "100%",
              justifySelf: "flex-start",
            }}
            className="flex-grow-1"
          >
            <div
              style={{ display: "flex", gap: "0.5rem" }}
              className=" justify-content-start"
            >
              <IconButton onClick={() => {}} sx={{ p: 0 }}>
                <ThemeProvider theme={themeAvatarItem}>
                  <Avatar
                    alt="profile img"
                    src={el.client?.profileImg}
                    sx={{ width: 45, height: 45 }}
                  />
                </ThemeProvider>
              </IconButton>
              <div>
                <Typography>{el.client.fullName}</Typography>
                <Typography>
                  {el.idTypeService === 1
                    ? "Solicitud de delivery"
                    : el.idTypeService === 2
                    ? el.details.length > 1
                      ? "Extensión de viaje"
                      : "Solicitud de Viaje Agendado"
                    : el.idTypeService === 3
                    ? "Tour"
                    : "Default"}
                </Typography>
              </div>
            </div>
            <div
              className="d-flex justify-content-evenly flex-wrap gap-3"
              // style={{ width: "120%" }}
            >
              <div className="d-flex">
                <AddRoadIcon />
                <Typography>{el.km?.toFixed(2)}km</Typography>
              </div>
              {el.time && el.time > 0 && (
                <div className="d-flex">
                  <AccessTimeIcon />
                  <Typography>{el.time} min</Typography>
                </div>
              )}
              <div className="d-flex">
                <LocalAtmIcon />
                <Typography>{el.total?.toFixed(2)}</Typography>
              </div>
            </div>
          </Box>
        </div>

        {el.idTypeService === 1 ||
        (el.idTypeService === 2 && el.typeRequest !== "personalized") ? (
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              flexDirection: "column",
              width: "32%",
              // flexShrink: 1,
              //flexGrow: 1,
            }}
            className=" justify-content-start"
          >
            <Box sx={{ display: "flex" }}>
              <LocationOnIcon />
              <Typography>
                {el.details.length > 0 && el.details[0].addressOrigin}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <LocationOnIcon />
              <Typography>
                {el.details.length > 0 && el.details[0].addressDestiny}
              </Typography>
            </Box>
            <Box sx={{ display: "flex" }}>
              <DirectionsCarIcon />
              <Typography>{el.userInCharge?.fullName}</Typography>
            </Box>
            {el.deliveryOrder && el.deliveryOrder.length > 0 && (
              <Box sx={{ display: "flex" }}>
                <ReorderIcon />
                <Typography>{el.deliveryOrder}</Typography>
              </Box>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              flexDirection: "column",
              width: "32%",
              // flexShrink: 1,
              // minWidth: "40%",
              //flexGrow: 1,
            }}
            className=" justify-content-start"
          >
            <Typography className="mb-3">
              {el.package ? el.package.name + "" : "-"}
            </Typography>
            {el.showTxtPriceExtra ? (
              <TextField
                hiddenLabel
                // defaultValue={nameBank}
                disabled={el.status === "pending" ? false : true}
                value={el.priceExtra || ""}
                name="priceExtra"
                variant="filled"
                fullWidth
                onChange={(e) => handleChangePrice(e, el.id)}
                placeholder="Añadir precio extra"
                sx={{ background: "white" }}
                className="mb-3"
              />
            ) : (
              <Typography
                // href="#"
                variant="body2"
                onClick={() => handleClickAddPriceExtra(el.id)}
                sx={{ color: "#1976d2", cursor: "pointer" }}
              >
                Agregrar precio extra
              </Typography>
            )}
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={el.selectedDriver || ""}
              onChange={(e) => handleChangeDriverPerRow(e, el.id)}
              label="Conductor guía"
              variant="filled"
              sx={{ background: "white" }}
              disabled={el.status === "pending" ? false : true}
            >
              <MenuItem value="0">
                <em>Ninguno</em>
              </MenuItem>
              {el.idTypeService === 2 && el.typeRequest === "personalized"
                ? el.driversTransport.map((el) => (
                    <MenuItem
                      value={el.value.toString()}
                      key={el.id.toString()}
                    >
                      {el.label}
                    </MenuItem>
                  ))
                : el.driversGuide.map((el) => (
                    <MenuItem
                      value={el.value.toString()}
                      key={el.id.toString()}
                    >
                      {el.label}
                    </MenuItem>
                  ))}
            </Select>
          </div>
        )}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "25%",
            //flexGrow: 1,
            // minWidth: '20%',
          }}
          className=""
        >
          <Box className="w-100">
            <Typography variant="body1">
              <i>
                {el.client.language.charAt(0).toUpperCase() +
                  el.client.language.slice(1) || "Idioma"}
              </i>
            </Typography>
            {el.idTypeService === 1 ? (
              <>
                {el.client?.roleId === 3 ? (
                  <Typography>Crédito Empresarial</Typography>
                ) : null}
              </>
            ) : (
              <>
                {el.idPayment === 2 ? (
                  <Link
                    href="#"
                    underline="always"
                    onClick={() => {
                      setUrlComprobante(el.voucher);
                      setOpenModal(true);
                    }}
                  >
                    Ver Comprobante
                  </Link>
                ) : el.idPayment === 1 ? (
                  <Typography>Efectivo</Typography>
                ) : el.idPayment === 3 ? (
                  <Typography>Tarjeta de Crédito</Typography>
                ) : (
                  <Typography>-</Typography>
                )}
              </>
            )}

            <Typography>
              {el.date} {moment(el.hour, "h:mm:ss").format("LT")}
            </Typography>
            <Typography
              sx={{
                color: el.status === "cancel" ? "error.main" : "text.primary",
              }}
            >
              {getState(el.status)}
            </Typography>
            {el.status === "cancel" && (
              <CustomWidthTooltip
                TransitionComponent={Zoom}
                disableFocusListener
                disableTouchListener
                placement="top"
                arrow
                title={el.observation || "No tiene observación"}
              >
                <Typography sx={{ cursor: "pointer", textDecoration: "underline", color: "#95C0FF" }}>
                  Motivo de Cancelación
                </Typography>
              </CustomWidthTooltip>
            )}
          </Box>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            //flexGrow: 1
            // width: "10%",
          }}
          className="gap-3"
        >
          {el.idTypeService !== 1 && el.idTypeService !== 2 ? (
            <>
              <ThemeProvider theme={themeBtnSmallActive}>
                <Button
                  variant="contained"
                  size="small"
                  className="w-100"
                  onClick={() => {
                    handleClickAcceptReq(el);
                  }}
                  disabled={
                    el.idTypeService === 1 || el.idTypeService === 2
                      ? true
                      : el.status === "rejected" || el.status === "cancel"
                      ? true
                      : el.status === "arrived" || el.status === "completed"
                      ? true
                      : el.status === "accepted"
                      ? true
                      : false
                  }
                >
                  Aceptar
                </Button>
              </ThemeProvider>
              <Button
                disabled={
                  el.idTypeService === 1 || el.idTypeService === 2
                    ? true
                    : el.status === "rejected" || el.status === "cancel"
                    ? true
                    : el.status === "accepted"
                    ? false
                    : el.status === "arrived" || el.status === "completed"
                    ? true
                    : false
                }
                variant="contained"
                size="small"
                className="w-100"
                onClick={() => {
                  handleClickRejectReq(el);
                }}
              >
                Rechazar
              </Button>
            </>
          ) : null}

          {/* BOTONES DE TRANSPORTE */}
          {el.idTypeService === 2 && el.typeRequest === "personalized" ? (
            <>
              <ThemeProvider theme={themeBtnSmallActive}>
                <Button
                  variant="contained"
                  size="small"
                  className="w-100"
                  onClick={() => {
                    handleClickAcceptReqTransport(el);
                  }}
                  disabled={
                    el.idTypeService !== 2 ||
                    (el.idTypeService !== 2 &&
                      el.typeRequest !== "personalized")
                      ? true
                      : el.status === "rejected" || el.status === "cancel"
                      ? true
                      : el.status === "arrived" || el.status === "completed"
                      ? true
                      : el.status === "accepted"
                      ? true
                      : false
                  }
                >
                  Aceptar
                </Button>
              </ThemeProvider>
              <Button
                disabled={
                  el.idTypeService !== 2 ||
                  (el.idTypeService !== 2 && el.typeRequest !== "personalized")
                    ? true
                    : el.status === "rejected" || el.status === "cancel"
                    ? true
                    : el.status === "accepted"
                    ? false
                    : el.status === "arrived" || el.status === "completed"
                    ? true
                    : false
                }
                variant="contained"
                size="small"
                className="w-100"
                onClick={() => {
                  handleClickRejectReqTransport(el);
                }}
              >
                Rechazar
              </Button>
            </>
          ) : null}
          {/* <Button
            variant="contained"
            size="small"
            className="w-100"
            onClick={handleClickSendNotification}
          >
            Enviar notificacion
          </Button> */}
          {/* <Tooltip title="Rechazar solicitud" arrow placement="top">
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              disabled={
                el.idTypeService === 1 || el.idTypeService === 2 ? true : false
              }
              onClick={() => {
                handleClickRejectReq(el);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Aceptar solicitud" arrow placement="top">
            <IconButton
              aria-label="check"
              color="inherit"
              size="small"
              disabled={
                el.idTypeService === 1 || el.idTypeService === 2 ? true : false
              }
              onClick={() => {
                handleClickAcceptReq(el);
              }}
            >
              <CheckCircleIcon fontSize="inherit" />
            </IconButton>
          </Tooltip> */}
          {/* <Typography className="fw-bolder">{el.id}</Typography> */}
        </div>
      </div>
    );
  };

  return (
    <BodyLayout>
      <div style={{ display: "flex" }} className="flex-wrap gap-4">
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="flex-grow-1 justify-content-evenly flex-wrap"
        >
          <Typography
            variant="h5"
            className="flex-grow-1 fw-bold"
            sx={{ textAlign: "center" }}
          >
            Solicitudes
          </Typography>
          <div className="d-flex flex-grow-1 justify-content-evenly">
            {listButtons.map((el) => renderButton(el))}
          </div>
        </div>
        <div className="flex-grow-1 flex-shrink-1 d-flex justify-content-center">
          <ThemeProvider theme={themeBtnDftActive}>
            <Button
              variant="contained"
              endIcon={<FileDownloadIcon />}
              onClick={handleClickDownloadReport}
            >
              Descargar reporte
            </Button>
          </ThemeProvider>
          {/* <Fade in={showCalendar} timeout={500}>
            <div
              className=""
              style={{
                position: "absolute",
                transform: "translateY(65px) translateX(-50px)",
                zIndex: 500,
              }}
            >
              <Calendar
                value={currentDate}
                inline
                selectionMode=""
                // disabledDates={getAllDaysInMonth(currentDate.getFullYear(), currentDate.getMonth())}
                // onChange={(e) => handleDate(e.value)}
                onSelect={(e) => handleDate(e.value)}
              ></Calendar>
            </div>
          </Fade> */}
        </div>
      </div>
      <Divider sx={{ margin: "2rem", borderColor: "var(--primary-color)" }} />
      <div>
        {/* WAY PAY METHOD FILTER */}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Tipo de pago
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={wayPay.id.toString()}
            onChange={(e) => handleChangeWayPay(e)}
            label="Tipo de pago"
          >
            <MenuItem value="0">
              <em>Ninguno</em>
            </MenuItem>
            {listPaymentMethods.map((el) => (
              <MenuItem value={el.id.toString()} key={el.id.toString()}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* STATE FILTER */}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">Estado</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={state.value}
            onChange={(e) => handleChangeState(e)}
            label="Estado"
          >
            <MenuItem value="0">
              <em>Ninguno</em>
            </MenuItem>
            {listStates.map((el, index) => (
              <MenuItem value={el.value} key={index}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* MONTHS FILTER */}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">Mes</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={selectedMonth}
            onChange={(e) => handleChangeMonth(e)}
            label="Mes"
          >
            {listMonths.map((el) => (
              <MenuItem key={el.id} value={el.id.toString()}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {/* YEAR FILTER */}
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">Año</InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={selectedYear}
            onChange={(e) => handleChangeYear(e)}
            label="Año"
          >
            {listYears.map((el) => (
              <MenuItem key={el.id} value={el.value.toString()}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div className="req-cont">
        {isFetching ? (
          <div className="w-100 d-flex justify-content-center mt-5 mb-5">
            <RotateSpinner size={70} color={COLOR_LOADING} loading={true} />
          </div>
        ) : reqList.length === 0 ? (
          <div className="container text-center">
            <div className="w-50 mx-auto">
              <img
                src={emptyData}
                className="img-fluid"
                alt="data-not-fund..."
                // style={{width: '100%'}}
              />

              <Typography className="pb-5 px-5 fw-bolder">
                No hay datos disponibles para esta búsqueda
              </Typography>
            </div>
          </div>
        ) : (
          reqList.map((el: IDataRequestRow, index) => renderReq(el, index))
        )}
      </div>

      {isFetching ? null : reqList.length === 0 && !isFetching ? null : (
        <div className="w-100 d-flex justify-content-center mt-5">
          <Button
            onClick={fetchMoreData}
            endIcon={
              isLoading ? <CircularProgress size={25} /> : <ArrowDownwardIcon />
            }
          >
            Cargar más
          </Button>
        </div>
      )}
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Comprobante"}</DialogTitle>
        <DialogContent>
          <img
            src={urlComprobante}
            className="img-fluid"
            alt="img-comprobante"
          />
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <ThemeProvider theme={themeBtnActive}>
            <Button onClick={() => setOpenModal(false)} autoFocus>
              Cerrar
            </Button>
          </ThemeProvider>
        </DialogActions>
      </Dialog>
    </BodyLayout>
  );
};

export default Requests;
