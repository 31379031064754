import { Map, Marker, Popup } from "mapbox-gl";
import { useContext, useEffect, useReducer, useState } from "react";
import geocodeApi from "../../api/geocodeApi";
import { IGeocodeGMaps } from "../../interfaces/IGeocode";
import { PlacesContext } from "../places/PlacesContext";
import { MapContext } from "./MapContext";
import { mapReducer } from "./mapReducer";

export interface IPlaceSelected {
  latLng: number[];
  namePlace: string;
  addressPlace: string;
}

export interface MapState {
  isMapReady: boolean;
  map?: Map;
  markers: Marker[];
  markersVoid: void[];
  markerPlaceSelected: Marker | null;
  isMyLocationClicked: { location: number[]; isClicked: boolean };

  // [lat, lng]
  placeSelected: IPlaceSelected;
}

const INITIAL_STATE: MapState = {
  isMapReady: false,
  map: undefined,
  markers: [],
  placeSelected: {
    latLng: [],
    addressPlace: "",
    namePlace: "",
  },
  markerPlaceSelected: null,
  isMyLocationClicked: { location: [], isClicked: false },
  markersVoid: [],
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export const MapProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(mapReducer, INITIAL_STATE);

  const { places, userLocation } = useContext(PlacesContext);
  const [isMarkerClicked, setIsMarkerClicked] = useState(false);

  useEffect(() => {
    state.markers.forEach((marker) => marker.remove());
    const newMarkers: Marker[] = [];
    const newMarkersVoid:void[] = []
    for (const place of places) {
      const [lng, lat] = place.center;
      const popup = new Popup({ focusAfterOpen: false }).setHTML(`
      <p class='fs-6 fw-bold mb-0 mt-2'>${place.text_es}</p>
      <p class='mb-1 mt-1'>${place.place_name_es}</p>
      `);
      const newMarker = new Marker({ color: "#08abff" })
        .setPopup(popup)
        .setLngLat([lng, lat])
        .addTo(state.map!);

      const newMarkersFunc = new Marker({ color: "#08abff" })
        .setPopup(popup)
        .setLngLat([lng, lat])
        .addTo(state.map!)
        .getElement()
        .addEventListener("click", (e) => {
          setPlaceSelected({
            addressPlace: "",
            latLng: [lng, lat],
            namePlace: "",
          });
          dispatch({
            type: "setIsMyLocationClicked",
            payload: { location: [lng, lat], isClicked: true },
          });
          setTimeout(() => {
            dispatch({
              type: "setIsMyLocationClicked",
              payload: { location: [], isClicked: false },
            });
          }, 1000);
        });

      newMarkers.push(newMarker);
      newMarkersVoid.push(newMarkersFunc)
    }

    dispatch({ type: "setMarkers", payload: newMarkers });
    dispatch({ type: "setMarkersVoid", payload: newMarkersVoid });
  }, [places]);

  useEffect(() => {
    setIsMarkerClicked(false);

    // if (state.markerPlaceSelected ) {
    //   state.markerPlaceSelected.remove();
    // }
    const [lat, lng] = state.placeSelected.latLng;
    const popup = new Popup({ focusAfterOpen: false }).setHTML(`
    <p class='fs-6 fw-bold mb-0 mt-2'>${state.placeSelected.namePlace}</p>
    <p>${
      state.placeSelected.addressPlace.includes("Vía sin nombre")
        ? state.placeSelected.latLng.join(",")
        : state.placeSelected.addressPlace
    }</p>
    `);

    if (state.placeSelected.latLng.length > 0 && !isMarkerClicked) {
      const newMarker = new Marker({ color: "rgb(234,67,53)" })
        .setPopup(popup)
        .setLngLat(
          state.isMyLocationClicked.isClicked
            ? [
                state.isMyLocationClicked.location[0],
                state.isMyLocationClicked.location[1],
              ]
            : [lng, lat]
        )
        .addTo(state.map!);

      newMarker.getElement().addEventListener("click", (e) => {
        setIsMarkerClicked(true);
      });

      dispatch({ type: "setMarkerPlaceSelected", payload: newMarker });
    }

    if (state.markerPlaceSelected && !isMarkerClicked) {
      state.markerPlaceSelected.remove();
    }
  }, [state.placeSelected]);

  const setMap = (map: Map) => {
    const myLocation = new Popup({ focusAfterOpen: false }).setHTML(
      `<p class='fs-6 fw-bold mb-0 mt-2'>Aqui estoy</p>`
    );

    const newMarker = new Marker({ color: "#301023" })
      .setLngLat(map.getCenter())
      .addTo(map)
      .setPopup(myLocation);
    newMarker.getElement().addEventListener("click", (e) => {
      const [lng, lat] = userLocation!;
      setPlaceSelected({
        addressPlace: "",
        latLng: [lat, lng],
        namePlace: "",
      });
      dispatch({
        type: "setIsMyLocationClicked",
        payload: { location: [lng, lat], isClicked: true },
      });
      setTimeout(() => {
        dispatch({
          type: "setIsMyLocationClicked",
          payload: { location: [], isClicked: false },
        });
      }, 1000);
    });
    dispatch({ type: "setMap", payload: map });
  };

  const setPlaceSelected = ({
    latLng,
    namePlace,
    addressPlace,
  }: IPlaceSelected) => {
    dispatch({
      type: "setPlaceSelected",
      payload: { latLng, namePlace, addressPlace },
    });
  };

  const setIsMyLocationClicked = (location?: number[], isClicked?: boolean) => {
    if (location && isClicked) {
      dispatch({
        type: "setIsMyLocationClicked",
        payload: { location, isClicked },
      });
    }
  };
  return (
    <MapContext.Provider
      value={{ ...state, setMap, setPlaceSelected, setIsMyLocationClicked }}
    >
      {children}
    </MapContext.Provider>
  );
};
