import { helpHttp } from "../helpers/helpHttp";
import {
  IDelDataPackage,
  IResDelPackage,
  IResGetAllPackages,
  IResGetPackageByID,
  IResPostPutPackage,
  ISavePackage,
} from "../interfaces/IPackages";

export default function usePackages() {
  const ABORTED = "ERR_CANCELED";

  const getAllPackages = async (): Promise<IResGetAllPackages | null> => {
    try {
      const resp = await helpHttp().get("package/getAllPackages");
      return resp;
    } catch (error: any) {
      // console.log("error ==>", error);
      if (error.code === "ERR_CANCELED") {
        return { aborted: true, data: null, msg: "", ok: false };
      } else {
        return null;
      }
    }
  };

  interface IPostPutStatus {
    idPackage: number;
    status: "accepted" | "rejected";
  }
  const putStatusPackage = async ({
    idPackage,
    status,
  }: IPostPutStatus): Promise<IResPostPutPackage | null> => {
    try {
      const resp = await helpHttp().post("package/postPutPackage", {
        data: { idPackage, status },
      });
      return resp;
    } catch (error: any) {
      // console.log("error ==>", error);
      if (error.code === ABORTED) {
        return { aborted: true };
      } else {
        return null;
      }
    }
  };

  const postPutPackage = async (
    form: ISavePackage
  ): Promise<IResPostPutPackage | null> => {
    try {
      const resp = await helpHttp().post("package/postPutPackage", {
        data: form,
      });
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED) {
        return { aborted: true };
      } else {
        return null;
      }
    }
  };

  const deletePackage = async (id: number): Promise<IResDelPackage | null> => {
    try {
      const resp = await helpHttp().del(`package/deletePackage/${id}`);
      return resp;
    } catch (error: any) {
      // console.log("error ==>", error);
      if (error.code === ABORTED) {
        return { aborted: true };
      } else {
        return null;
      }
    }
  };

  const getPackageById = async (
    id: number
  ): Promise<IResGetPackageByID | null> => {
    try {
      const resp = await helpHttp().get(`package/getPackagesByIdPackage/${id}`);
      return resp;
    } catch (error: any) {
      // console.log(error);
      if (error.code === ABORTED) return { aborted: true };
      return null;
    }
  };

  return {
    getAllPackages,
    putStatusPackage,
    deletePackage,
    postPutPackage,
    getPackageById,
  };
}
