import { ABORTED_REQUEST } from "../helpers/consants";
import { helpHttp, IRejectRequest } from "../helpers/helpHttp";
import { IResGetConfirmAccount, IResPostResetPass, IResPostSendEmailResetPass } from "../interfaces/IAuth";

export const useAuth = () => {
  const signIn = async () => {
    const data = (await helpHttp().get("auth/loginAdmin")).data;
    // console.log("🚀 ~ file: useAuth.tsx ~ line 8 ~ signIn ~ data", data);
  };

  const confirmAccount = async (
    token: string
  ): Promise<IResGetConfirmAccount | null> => {
    try {
      const data = await helpHttp().get(`auth/confirmAccount/${token}`);
      return data;
    } catch (error: any) {
      if (error.code === ABORTED_REQUEST) {
        return { aborted: true, ok: false, msg: "", data: null };
      } else {
        return null;
      }
    }
  };

  interface IResetPass {
    token: string;
    password: string;
  }
  const resetPassword = async ({
    token,
    password,
  }: IResetPass): Promise<IResPostResetPass | null> => {
    try {
      const data = await helpHttp().post(`auth/resetPassword/`, {
        data: { token, password },
      });
      return data;
    } catch (error: any) {
      if (error.code === ABORTED_REQUEST) {
        return { aborted: true, ok: false, msg: "", data: null };
      } else {
        return null;
      }
    }
  };
  
  interface ISendEmailResetPass {
    email: string;
    language?: 'es' | 'en';
  }
  const sendEmailResetPass = async ({
    email,
    language = 'en',
  }: ISendEmailResetPass): Promise<IResPostSendEmailResetPass | IRejectRequest> => {
    try {
      const data = await helpHttp().post(`auth/sendEmailResetPassword`, {
        data: { email, language },
      });
      return data;

    } catch (error: any) {
      if (error.code === ABORTED_REQUEST) {
        return { aborted: true, };
      } else {
        return {messages: error.messages, msg: error.messages && error.messages.length > 0 ? error.messages[0] : 'Unknown error'};
      }
    }
  };

  return {
    signIn,
    confirmAccount,
    resetPassword,
    sendEmailResetPass
  };
};
