import { AccountCircle } from "@mui/icons-material";
import {
  Typography,
  Divider,
  TextField,
  MenuItem,
  Select,
  SelectChangeEvent,
  Box,
  Chip,
  OutlinedInput,
  Theme,
  useTheme,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  IconButton,
  Button,
  createTheme,
  ThemeProvider,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grow,
} from "@mui/material";
import React, {
  ChangeEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import BodyLayout from "../../components/shared/BodyLayout";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "../../hooks/useForm";
import { uploadFile } from "../../helpers/UploadFile";
import { AlertContext } from "../../contexts/alert/AlertContext";
import useRegions from "../../hooks/useRegions";
import useProvinces from "../../hooks/useProvinces";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import MapModal from "../../components/modals/MapModal";
import { ToastContext } from "../../contexts/toast/ToastContext";
import { TransitionGroup } from "react-transition-group";
import Collapse from "@mui/material/Collapse";
import { DetailPackage, ISavePackage, Route } from "../../interfaces/IPackages";
import usePackages from "../../hooks/usePackages";
import { useLocation } from "react-router-dom";
import { UNHANDLED_ERROR } from "../../helpers/consants";
import SaveIcon from "@mui/icons-material/Save";
import { AnyStyledComponent } from "styled-components";

const themeBtnPlus = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          background: "var(--bkg-plus-color)",
          color: "ButtonText",
          ":hover": {
            background: "var(--bkg-plus-color-hover)",
          },
        },
      },
    },
  },
});

const themeBtnDft = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--color-dft-btn)",
          color: "ButtonText",
          ":hover": {
            background: "var(--color-dft-hover)",
          },
        },
      },
    },
  },
});

const themeBtnDftActive = createTheme({
  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          // fontSize: "1rem",
          padding: "0.7rem 1.5rem",
          background: "var(--primary-color)",
          color: "HighlightText",
          transform: "scale(1)",
          transition: "transform 0.05s",
          ":hover": {
            background: "var(--primary-color)",
            color: "HighlightText",
            transition: "transform 0.05s",
          },
        },
      },
    },
  },
});

interface IRegions {
  id: number;
  value: string | number;
  label: string;
}

interface IProvinces {
  id: number;
  value: string | number;
  label: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const initForm: ISavePackage = {
  idPackage: 0,
  name: "",
  nameEN: "",
  time: 0,
  description: "",
  descriptionEN: "",
  documentUrl: "",
  documentUrlEN: "",
  regionId: 0,
  status: "accepted",
  detailProvices: [],
  routes: [],
  photos: [],
  detailPackage: [],
};

const initDetailPackage: DetailPackage = {
  maxPassenger: 0,
  minPassenger: 0,
  price: 0,
};

interface IState {
  row: number | undefined;
  watch: boolean;
}

interface IFormErrors {
  formTime: { error: boolean };
  nameEN: { error: boolean };
  formName: { error: boolean };
  regionId: { error: boolean };
  description: { error: boolean };
  descriptionEN: { error: boolean };
  detailProvinces: { error: boolean };
}

const initFormErrors: IFormErrors = {
  formTime: { error: false },
  nameEN: { error: false },
  formName: { error: false },
  regionId: { error: false },
  description: { error: false },
  descriptionEN: { error: false },
  detailProvinces: { error: false },
};

const ERR_MIN_EQUAL_ZERO =
  "El No. mínimo de pasajeros debe ser mayor que cero o mayor que el máximo anterior.";
const ERR_MAX_EQUAL_ZERO =
  "El No. máximo de pasajeros debe ser mayor que el mínimo.";
const ERR_PRICE_EQUAL_ZERO =
  "El precio en el detalle del paquete debe ser mayor que cero.";
const ERR_EMPTY_DATA = "Existen campos vacíos en el detalle del paquete.";
const ERR_BY_PASSENGERS = "Corriga los errores en la sección precio por el número de pasajeros";

const NewPackage = () => {
  const theme = useTheme();
  const location = useLocation();
  const state = location.state as IState;
  const { row, watch } = state;

  const { onOpenAbortAlert } = useContext(AlertContext);
  const { onOpenToast } = useContext(ToastContext);

  const { getAllRegions } = useRegions();
  const { getProvincesByRegion } = useProvinces();
  const { postPutPackage, getPackageById } = usePackages();

  const [provinces, setProvinces] = useState<string[]>([]);
  const [regionsList, setRegionsList] = useState<IRegions[]>([]);
  const [provincesList, setProvincesList] = useState<IProvinces[]>([]);
  const [regionSelected, setRegionSelected] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isLoadingImages, setIsLoadingImages] = useState(false);
  const [errorsOnFormDetail, setErrorsOnFormDetail] = useState(false);
  const [errorTypeFormDetail, setErrorTypeFormDetail] = useState('.')
  const [checkedForm, setCheckedForm] = useState<IFormErrors>(initFormErrors);
  const [routeToEdit, setRouteToEdit] = useState<{route: Route, pos: number} | null | undefined>(null);

  const { form, onChange, updateForm } = useForm(initForm);
  const [formDetail, setFormDetail] = useState<DetailPackage[]>([
    { maxPassenger: 0, minPassenger: 0, price: 0 },
  ]);

  const handleChangeSelectChip = (event: any, keyForm: string) => {
    const {
      target: { value },
    } = event;
    setCheckedForm({ ...checkedForm, [keyForm]: { error: false } });
    if (value !== "0") {
      setProvinces(
        // On autofill we get a stringified value.
        typeof value === "string" ? value.split(",") : value
      );
      let aux = [];
      for (const province of provincesList) {
        for (const val of value) {
          if (province.value === val) {
            aux.push({ idProvince: province.id });
          }
        }
      }
      updateForm({ ...form, detailProvices: aux });
    }
  };

  const handleChange = (event: SelectChangeEvent, keyForm: string) => {
    console.log("event ==>", event);
    setCheckedForm({ ...checkedForm, [keyForm]: { error: false } });
    setRegionSelected(event.target.value as string);
    updateForm({ ...form, [event.target.name]: parseInt(event.target.value) });
  };

  const handleFileUpload = async (evt: any) => {
    console.log("evt ==>", evt.nativeEvent);
    const body = document.getElementById("body");
    if (body) body.style.cursor = "wait";
    const files = evt.nativeEvent.target.files || [];
    if (files.length > 0) {
      const file = files[0];
      const f = new FormData();
      f.append("file", file);
      const resp = await uploadFile({ data: f });
      if (body) body.style.cursor = "default";
      console.log("🚀 resp ==>", resp);
      if (resp) {
        updateForm({ ...form, [evt.target.name]: resp?.url || "" });
      }
    }
  };

  const handleImgUpload = async (evt: ChangeEvent<HTMLInputElement>) => {
    console.log("evt ==>", evt);
    const body = document.getElementById("body");
    if (body) body.style.cursor = "wait";
    setIsLoadingImages(true);
    const files = evt.target.files || [];
    let newPhotos = form.photos;
    for (const file of files as never[]) {
      const f = new FormData();
      f.append("file", file);
      const resp = await uploadFile({ data: f });
      if (resp) {
        newPhotos.push({ imgUrl: resp.url });
        updateForm({ ...form, photos: newPhotos });
      }
    }
    if (body) body.style.cursor = "default";
    setIsLoadingImages(false);
  };

  const handleClickSave = async () => {


    const validateName = () => {
      return new Promise((resolve, reject) => {
        if (form.name.trim().length > 0) {
          resolve(form.name);
        } else {
          setCheckedForm({ ...checkedForm, formName: { error: true } });
          reject("Debe ingresar un nombre");
        }
      });
    };
    const validateNameEn = () => {
      return new Promise((resolve, reject) => {
        if (form.nameEN.trim().length > 0) {
          resolve(form.nameEN);
        } else {
          setCheckedForm({ ...checkedForm, nameEN: { error: true } });
          reject("Debe la traducción del nombre");
        }
      });
    };
    const validateDescription = () => {
      return new Promise((resolve, reject) => {
        if (form.description.trim().length > 0) {
          resolve(form.description);
        } else {
          setCheckedForm({ ...checkedForm, description: { error: true } });
          reject("Debe ingresar una descripción");
        }
      });
    };
    const validateDescriptionEN = () => {
      return new Promise((resolve, reject) => {
        if (form.descriptionEN.trim().length > 0) {
          resolve(form.descriptionEN);
        } else {
          setCheckedForm({ ...checkedForm, descriptionEN: { error: true } });
          reject("Debe ingresar la traducción de la descripción");
        }
      });
    };
    const validateDocumentUrl = () => {
      return new Promise((resolve, reject) => {
        if (form.documentUrl.trim().length > 0) resolve(form.documentUrl);
        else reject("Debe subir un documento");
      });
    };
    const validateDocumentUrlEN = () => {
      return new Promise((resolve, reject) => {
        if (form.documentUrlEN.trim().length > 0) resolve(form.documentUrlEN);
        else reject("Debe subir la traducción del documento");
      });
    };
    const validateTime = () => {
      return new Promise((resolve, reject) => {
        if (
          parseInt(form.time.toString()) > 0 &&
          form.time.toString().trim().length > 0
        )
          resolve(form.time);
        else {
          setCheckedForm({ ...checkedForm, formTime: { error: true } });
          reject("Debe ingresar la duración del Tour");
        }
      });
    };
    const validateRegionId = () => {
      return new Promise((resolve, reject) => {
        if (
          parseInt(form.regionId.toString()) > 0 &&
          form.regionId.toString().trim().length > 0
        ) {
          resolve(form.regionId);
        } else {
          setCheckedForm({ ...checkedForm, regionId: { error: true } });
          reject("Debe seleccionar una Región");
        }
      });
    };

    const validateDetailProvinces = () => {
      return new Promise((resolve, reject) => {
        if (form.detailProvices.length > 0) {
          resolve(form.detailProvices);
        } else {
          setCheckedForm({ ...checkedForm, detailProvinces: { error: true } });
          reject("Debe seleccionar al menos una ciudad de referencia");
        }
      });
    };

    const validateRoutes = () => {
      return new Promise((resolve, reject) => {
        if (form.routes.length > 0) resolve(form.routes);
        else reject("Debe ingresar al menos un punto en la ruta");
      });
    };

    const validatePhotos = () => {
      return new Promise((resolve, reject) => {
        if (form.photos.length > 0) resolve(form.photos);
        else reject("Debe subir al menos una imagen");
      });
    };

    const validateDetailPackage = () => {
      return new Promise((resolve, reject) => {
        const data = form.detailPackage.map((value, index) => {
          return new Promise((res, rej) => {
            const values: string[] = Object.values(value);
            const aux: any = values.map((el, index) => {
              return new Promise((resolver, rechazar) => {
                if (el.toString().trim().length > 0 ) {
                  if (el.toString().trim() === "0" && index === 2) {
                    setErrorTypeFormDetail(ERR_MIN_EQUAL_ZERO);
                    rechazar(ERR_MIN_EQUAL_ZERO);
                  } else if (el.toString().trim() === "0" && index === 1) {
                    setErrorTypeFormDetail(ERR_MAX_EQUAL_ZERO);
                    rechazar(ERR_MAX_EQUAL_ZERO);
                  } else if (el.toString().trim() === "0" && index === 3) {
                    setErrorTypeFormDetail(ERR_PRICE_EQUAL_ZERO);
                    rechazar(ERR_PRICE_EQUAL_ZERO);
                  } else {
                    resolver(el);
                  }
                  // if (parseInt(el.toString().trim()) > 0) {
                  //   resolver(el)
                  // } else {
                  //   rechazar("Datos inválidos en el detalle del paquete.");
                  // }
                } else {
                  setErrorTypeFormDetail(ERR_EMPTY_DATA);
                  rechazar(ERR_EMPTY_DATA);
                }
              });
            });
            Promise.all(aux)
              .then((resp) => {
                res(true);
              })
              .catch((err) => rej(err));
          });
        });
        Promise.all(data)
          .then((resp) => {
            resolve(true);
          })
          .catch((err) => reject(err));
      });
    };

    const validateErrorsOnFormDetail = () => {
      return new Promise((resolve, reject) => {
        if (!errorsOnFormDetail) resolve(false);
        else
          reject(ERR_BY_PASSENGERS);
      });
    };

    const validateErrorsOnForm = () => {
      return new Promise((resolve, reject) => {
        if (
          !checkedForm.formTime.error &&
          !checkedForm.nameEN.error &&
          !checkedForm.formName.error &&
          !checkedForm.regionId.error &&
          !checkedForm.description.error &&
          !checkedForm.descriptionEN.error &&
          !checkedForm.detailProvinces.error
        ) {
          resolve(true);
        } else {
          reject("Verifique que no tenga errores en el formulario");
        }
      });
    };

    validateName()
      .then(() => validateNameEn())
      .then(() => validateDescription())
      .then(() => validateDescriptionEN())
      // .then(() => validateDocumentUrl())
      // .then(() => validateDocumentUrlEN())
      .then(() => validateTime())
      .then(() => validateRegionId())
      .then(() => validateDetailProvinces())
      .then(() => validateRoutes())
      .then(() => validatePhotos())
      .then(() => validateDetailPackage())
      .then(() => validateErrorsOnFormDetail())
      .then(() => validateErrorsOnForm())
      .then(async () => {
        console.log(form.detailPackage)
        const resp = await postPutPackage(form);
        if (resp) {
          if (resp.aborted) {
            onOpenAbortAlert({});
            return;
          }
          onOpenToast("success", "Guardado", "");
          // updateForm(initForm);
          // setFormDetail([{ maxPassenger: 0, minPassenger: 0, price: 0 }]);
          clean();
        } else {
          onOpenToast("error", "Error", UNHANDLED_ERROR);
        }
      })
      .catch((err: string) => {
        console.log(err);
        const section = document.querySelector("#new-package");
        if (
          err !== ERR_MIN_EQUAL_ZERO &&
          err !== ERR_MAX_EQUAL_ZERO &&
          err !== ERR_PRICE_EQUAL_ZERO &&
          err !== ERR_BY_PASSENGERS &&
          err !== ERR_EMPTY_DATA
        ) {
          section!.scrollIntoView();
        }
        onOpenToast("warn", "", err);
      });

    // let aux = form.photos;
    // aux.push({
    //   imgUrl:
    //     "https://equatorian.nousproyect.com/uploads/equatorian/d1e5feeb-198a-4aa7-8bf5-e7d6a9101c79.jpg",
    // });
    // updateForm({ ...form, photos: aux });
  };

  const clean = () => {
    // updateForm(initForm);
    updateForm({ ...initForm, photos: [] });
    setFormDetail([{ maxPassenger: 0, minPassenger: 0, price: 0 }]);
    setProvinces([]);
    setRegionSelected("");
  };

  const handleClickNewRoute = () => {
    setOpenModal(true);
    setRouteToEdit(null);
  };

  const deleteRoutePoint = (index: number) => {
    const filteredRoutes = form.routes.filter((value, pos) => pos !== index);
    updateForm({ ...form, routes: filteredRoutes });
  };

  const updateRoutePoint = (route: Route, index:number) => {
    setRouteToEdit({route, pos: index});
    setOpenModal(true);
  }

  const deleteImg = (index: number) => {
    const filteredImg = form.photos.filter((value, pos) => pos !== index);
    updateForm({ ...form, photos: filteredImg });
  };

  const [indexDetailRow, setIndexDetailRow] = useState(-1);

  const handleClickNewDetail = () => {
    const lastRowIndex = formDetail.length - 1;
    const lastRow = formDetail.find((el, index) => index === lastRowIndex);
    let startedRow = false;
    let entryErrorMaxLessThanMin = false;
    let entryErrorMinLessThanPrevMax = false;
    if (lastRow) {
      if (
        lastRow.maxPassenger <= 0 ||
        lastRow.minPassenger <= 0 ||
        lastRow.price <= 0
      ) {
        startedRow = true;
      }
      if (
        parseInt(lastRow.maxPassenger.toString()) <
        parseInt(lastRow.minPassenger.toString())
      ) {
        entryErrorMaxLessThanMin = true;
      }

      if (formDetail.length > 1) {
        const prevRow = formDetail[lastRowIndex - 1];
        if (
          parseInt(lastRow.minPassenger.toString()) <=
          parseInt(prevRow.maxPassenger.toString())
        ) {
          entryErrorMinLessThanPrevMax = true;
        }
      }
    }

    if (!startedRow) {
      if (!entryErrorMinLessThanPrevMax) {
        if (!entryErrorMaxLessThanMin) {
          setFormDetail([
            ...formDetail,
            { maxPassenger: 0, minPassenger: 0, price: 0 },
          ]);
        } else {
          onOpenToast(
            "warn",
            "",
            "El No. máximo no puede ser menor al mínimo de pasajeros"
          );
        }
      } else {
        onOpenToast(
          "warn",
          "",
          "El No. mínimo no puede ser menor o igual al máximo de pasajeros anterior"
        );
      }
    } else {
      if (startedRow) {
        onOpenToast(
          "info",
          "",
          "Debe completar todos los campos de la fila para el precio por el número de pasajeros"
        );
      }
    }
  };

  const handleClickDeleteDetailById = (pos: number) => {
    if (pos > 0) {
      const aux = formDetail.filter((el, index) => index !== pos);
      setFormDetail(aux);
      validateDetailPackage(aux);
    }
  };

  const formOnChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    let rowToUpdate = formDetail.find((value, pos) => index === pos);
    rowToUpdate = { ...rowToUpdate!, [e.target.name]: e.target.value! };
    const aux = formDetail.map((el, pos) => {
      if (pos === index) {
        return {
          ...el,
          ...rowToUpdate,
        };
      } else {
        return el;
      }
    });

    setFormDetail(aux);
    // const lastRowIndex = formDetail.length - 1;
    // const lastRow = formDetail.find((el, index) => index === lastRowIndex);
    let startedRow = false;
    let entryErrorMaxLessThanMin = false;
    let entryErrorMinLessThanPrevMax = false;
    // console.log('row to update => ', rowToUpdate)
    // console.log('index => ', index)
    // console.log('\n')
    if (rowToUpdate) {
      // console.log('entra 1');
      if (
        parseInt(rowToUpdate.maxPassenger.toString()) <= 0 ||
        parseInt(rowToUpdate.minPassenger.toString()) <= 0 ||
        parseInt(rowToUpdate.price.toString()) <= 0
      ) {
        // console.log('entra 2')
        startedRow = true;
      } 
      if (
        parseInt(rowToUpdate.maxPassenger.toString()) <
        parseInt(rowToUpdate.minPassenger.toString())
      ) {
        // console.log('entra 3')
        entryErrorMaxLessThanMin = true;
      }

      if (index >= 1) {
        // console.log('entra 4')
        const prevRow = formDetail[index - 1];
        if (
          parseInt(rowToUpdate.minPassenger.toString()) <=
          parseInt(prevRow.maxPassenger.toString())
        ) {
          // console.log('entra 5');
          entryErrorMinLessThanPrevMax = true;
        }
      }
    }

// console.log('################################################')
// console.log(startedRow)
// console.log(entryErrorMaxLessThanMin)
// console.log(entryErrorMinLessThanPrevMax)
// console.log('################################################')
// console.log('\n')


    if (
      !startedRow &&
      !entryErrorMaxLessThanMin &&
      !entryErrorMinLessThanPrevMax
    ) {
      setErrorsOnFormDetail(false);
    } else {
      setErrorsOnFormDetail(true);
    }
  };

  const handleChangeForm = (evt: any, keyForm: string) => {
    setCheckedForm({ ...checkedForm, [keyForm]: { error: false } });
    onChange(evt);
  };

  const getAllRegionsList = async () => {
    const resp = await getAllRegions();
    if (resp) {
      if (resp.aborted) {
        onOpenAbortAlert({
          message: "Tiempo de espera agotado al recuperar las Regiones.",
        });
        return;
      }
      const aux: IRegions[] =
        resp.data
          ?.map((el) => {
            return {
              id: el.id,
              value: el.id,
              label: el.nameRegion,
            };
          })
          .filter((el) => el !== undefined) || [];
      setRegionsList(aux);
    }
  };

  const getAllProvincesList = async () => {
    const respProvinces = await getProvincesByRegion(
      parseInt(regionSelected.toString())
    );
    if (respProvinces) {
      if (respProvinces.aborted) {
        onOpenAbortAlert({
          message: "Tiempo de espera agotado al recuperar las Provincias.",
        });
        return;
      }
      if (respProvinces.data) {
        const auxProvinces: IProvinces[] =
          respProvinces.data.rows
            .map((el) => {
              return {
                id: el.id,
                value: el.nameProvince,
                label: el.nameProvince,
              };
            })
            .filter((el) => el !== undefined) || [];
        setProvincesList(auxProvinces);
      } else {
        // console.log('vaciar las provincias')
        setProvincesList([]);
      }
    } else {
      // console.log('provincias nulas')
      setProvincesList([]);
    }
  };

  const validateDetailPackage = (detailPackage: DetailPackage[]) => {
    const lastRowIndex = detailPackage.length - 1;
    const lastRow = detailPackage.find((el, index) => index === lastRowIndex);
    let startedRow = false;
    let entryErrorMaxLessThanMin = false;
    let entryErrorMinLessThanPrevMax = false;
    if (lastRow) {
      if (
        lastRow.maxPassenger <= 0 ||
        lastRow.minPassenger <= 0 ||
        lastRow.price <= 0
      ) {
        startedRow = true;
      }
      if (
        parseInt(lastRow.maxPassenger.toString()) <
        parseInt(lastRow.minPassenger.toString())
      ) {
        entryErrorMaxLessThanMin = true;
      }

      if (detailPackage.length > 1) {
        const prevRow = detailPackage[lastRowIndex - 1];
        if (
          parseInt(lastRow.minPassenger.toString()) <=
          parseInt(prevRow.maxPassenger.toString())
        ) {
          entryErrorMinLessThanPrevMax = true;
        }
      }
    }

    if (!startedRow) {
      if (!entryErrorMinLessThanPrevMax) {
        if (!entryErrorMaxLessThanMin) {
          setErrorsOnFormDetail(false);
        } else {
          setErrorsOnFormDetail(true);
        }
      } else {
        setErrorsOnFormDetail(true);
      }
    } else {
      if (startedRow) {
        setErrorsOnFormDetail(true);
      }
    }
  };

  const getData = async () => {
    await getAllRegionsList();

    if (row) {
      const resp = await getPackageById(row);
      // console.log("🚀 ~ file: NewPackage.tsx ~ line 824 ~ getData ~ resp", resp)
      if (resp) {
        if (resp.aborted) {
          onOpenAbortAlert({});
          return;
        }
        if (resp.data) {
          updateForm({
            ...resp.data.travelPackage,
            idPackage: resp.data.travelPackage.id!,
            detailPackage: [],
            detailProvices: resp.data.detailProvinces.map((el) => {
              return { idProvince: el.id };
            }),
            routes: resp.data.routes,
            photos: resp.data.photos,
          });
          setRegionSelected(resp.data.travelPackage.regionId.toString());
          setFormDetail(resp.data.detailPackage);
          setProvinces(
            resp.data.detailProvinces.map((el) => el.province.nameProvince)
          );

          // setProvinces(resp.data.detailProvinces.forEach(el => el.))
          validateDetailPackage(resp.data.detailPackage);
        } else {
          onOpenToast("warn", "", "No hay datos para mostrar.");
        }
        return;
      } else {
        onOpenToast("error", "", UNHANDLED_ERROR);
        return;
      }
    }
    // await getAllProvincesList();
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    updateForm({ ...form, detailPackage: formDetail });
  }, [formDetail]);

  useEffect(() => {
    const getData = async () => {
      if (parseInt(regionSelected.toString()) > 0) {
        await getAllProvincesList();
      }
    };
    getData();
  }, [regionSelected]);

  return (
    <BodyLayout>
      <div className="d-flex flex-wrap gap-4 ">
        <div className="d-flex align-items-center flex-grow-1 flex-wrap">
          <form className="form-control border-0" id="new-package">
            <Typography variant="h5" className="flex-grow-1 fw-bold">
              Nuevo paquete
            </Typography>
          </form>
        </div>
      </div>
      <Divider sx={{ margin: "2rem", borderColor: "var(--primary-color)" }} />
      <form className="form-control border-0">
        <div className="d-flex flex-column">
          <Typography variant="h6" className="mb-3">
            Información del paquete
          </Typography>
          <div className="d-flex gap-4 flex-grow-1">
            <div className="flex-grow-1">
              <Typography>Nombre del paquete</Typography>
              <TextField
                hiddenLabel
                // defaultValue={form.name}
                error={checkedForm.formName.error ? true : false}
                value={form.name}
                name="name"
                variant="filled"
                placeholder="Escribe un nombre"
                fullWidth
                onChange={(e) => handleChangeForm(e, "formName")}
              />
            </div>
            <div className="flex-grow-1">
              <Typography>Traducción</Typography>
              <TextField
                hiddenLabel
                // defaultValue={form.nameEN}
                error={checkedForm.nameEN.error ? true : false}
                value={form.nameEN}
                name="nameEN"
                variant="filled"
                placeholder="Escribe un nombre"
                fullWidth
                onChange={(e) => handleChangeForm(e, "nameEN")}
              />
            </div>
          </div>
        </div>
        <div className="d-flex gap-4 align-items-center justify-content-center">
          <div className="flex-grow-1 d-flex justify-content-center align-items-center">
            <FormControl sx={{}} className="w-100">
              <InputLabel id="demo-select-small">Región</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={regionSelected}
                label="Región"
                name="regionId"
                onChange={(e) => handleChange(e, "regionId")}
                fullWidth
                error={checkedForm.regionId.error ? true : false}
              >
                {regionsList.length === 0 && (
                  <MenuItem value={0}>
                    <em>Ninguno</em>
                  </MenuItem>
                )}
                {regionsList.map((el, index) => (
                  <MenuItem value={el.value} key={index}>
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex-grow-1" style={{ maxWidth: "49%" }}>
            <Typography>Duración (horas)</Typography>
            <TextField
              hiddenLabel
              // defaultValue={form.time}
              error={checkedForm.formTime.error ? true : false}
              value={form.time}
              name="time"
              variant="filled"
              placeholder="Número de horas de duración"
              fullWidth
              inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              onChange={(e) => handleChangeForm(e, "formTime")}
            />
          </div>
        </div>
        <div className="d-flex gap-4">
          <div
            className="flex-grow-1 d-flex justify-content-center align-items-center"
            style={{ flexBasis: 250 }}
          >
            <FormControl sx={{ width: "100%" }} className="">
              <InputLabel id="demo-multiple-chip-label">Provincias</InputLabel>
              <Select
                // label="Provincias de refenencia"
                label="Provincias"
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                name="detailProvices"
                multiple
                value={provinces}
                onChange={(e) => handleChangeSelectChip(e, "detailProvinces")}
                error={checkedForm.detailProvinces.error ? true : false}
                input={
                  <OutlinedInput id="select-multiple-chip" label="Provincias" />
                }
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {/* {provincesList.length === 0 && 
                <MenuItem value='0'>
                <em>Ninguno</em>
              </MenuItem>
              } */}
                {provincesList.map((el) => (
                  <MenuItem
                    key={el.id}
                    value={el.label}
                    style={getStyles(el.label, provinces, theme)}
                  >
                    {el.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="flex-grow-1 d-flex gap-4  justify-content-center align-items-center">
            <FormControl
              // sx={{ m: 1, minWidth: "20%" }}
              variant="outlined"
              className="flex-grow-1"
            >
              <InputLabel htmlFor="outlined-adornment-password">Pdf</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                // defaultValue={form.documentUrl}
                value={form.documentUrl}
                name="documentUrl"
                onChange={onChange}
                readOnly
                endAdornment={
                  <InputAdornment position="end">
                    <Button variant="text" component="label">
                      <input
                        name="documentUrl"
                        type="file"
                        accept=".pdf"
                        hidden
                        onChange={(e) => handleFileUpload(e)}
                      />
                      <ArrowCircleUpIcon />
                    </Button>
                  </InputAdornment>
                }
                label="Pdf"
              />
            </FormControl>

            <FormControl
              // sx={{ m: 1, width: "25ch" }}
              variant="outlined"
              className="flex-grow-1"
            >
              <InputLabel htmlFor="outlined-adornment-password">
                Pdf traducido
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type="text"
                // defaultValue={form.documentUrlEN}
                value={form.documentUrlEN}
                name="documentUrlEN"
                readOnly
                onChange={onChange}
                endAdornment={
                  <InputAdornment position="end">
                    <Button variant="text" component="label">
                      <input
                        name="documentUrlEN"
                        type="file"
                        accept=".pdf"
                        hidden
                        onChange={(e) => handleFileUpload(e)}
                      />
                      <ArrowCircleUpIcon />
                    </Button>
                  </InputAdornment>
                }
                label="Pdf traducido"
              />
            </FormControl>
          </div>
        </div>
        <div className="d-flex gap-4">
          <div className="flex-grow-1">
            <Typography>Descripción</Typography>
            <TextField
              hiddenLabel
              // defaultValue={form.description}
              error={checkedForm.description.error ? true : false}
              value={form.description}
              name="description"
              variant="filled"
              placeholder="Descripción del Tour"
              fullWidth
              multiline
              minRows={4}
              onChange={(e) => handleChangeForm(e, "description")}
            />
          </div>
          <div className="flex-grow-1 mb-4">
            <Typography>Traducción</Typography>
            <TextField
              hiddenLabel
              // defaultValue={form.descriptionEN}
              error={checkedForm.descriptionEN.error ? true : false}
              value={form.descriptionEN}
              name="descriptionEN"
              variant="filled"
              placeholder="Traducción del Tour"
              fullWidth
              multiline
              minRows={4}
              onChange={(e) => handleChangeForm(e, "descriptionEN")}
            />
          </div>
        </div>
        <div className="my-4">
          <div className="d-flex mb-3">
            <div className="flex-grow-1">
              <Typography variant="h6">Rutas</Typography>
              <div>
                <TransitionGroup>
                  {form.routes.length === 0 ? (
                    <Collapse>{getAccordionRoute()}</Collapse>
                  ) : (
                    form.routes.map((el, index) => (
                      <Collapse key={index}>
                        {getAccordionRoute(el.address, el.description, el.descriptionEn || '-', () => deleteRoutePoint(index), () => updateRoutePoint(el, index)
                        )}
                      </Collapse>
                    ))
                  )}
                </TransitionGroup>
              </div>
            </div>
          </div>
          <div className="mx-auto mb-4" style={{ width: "100%" }}>
            <div className="d-flex align-items-center gap-3">
              <ThemeProvider theme={themeBtnPlus}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    borderRadius: "50%",
                    // padding: "10px 0px",
                    width: "10px",
                    height: "55px",
                  }}
                  onClick={() => handleClickNewRoute()}
                >
                  <AddIcon
                    sx={{ width: 35, height: 35, padding: 0 }}
                    className=""
                  />
                </Button>
              </ThemeProvider>
              <Typography
                className="fw-bold"
                onClick={() => handleClickNewRoute()}
              >
                Añadir nuevo punto
              </Typography>
            </div>
          </div>
        </div>

        {/* package detail */}
        <div className="mb-4">
          <div className="d-flex flex-column">
            <Typography variant="h6" className="mb-2">
              Precio por número de pasajeros
            </Typography>
            <TransitionGroup>
              {formDetail.map((el: DetailPackage, index: number) => {
                // formUpdateForm(el);
                // setFormDetail(el);
                return (
                  <Collapse key={index}>
                    {getDetailPackageRow(
                      formOnChange,
                      el,
                      // saveDetailRow,
                      () => {},
                      () => handleClickDeleteDetailById(index),
                      index,
                      () => setIndexDetailRow(index),
                      index > 0 ? formDetail[index - 1] : undefined
                    )}
                    {/* <Typography key={ index}>Hola { index}</Typography> */}
                  </Collapse>
                );
              })}
            </TransitionGroup>
          </div>
          <div className="mx-auto" style={{ width: "100%" }}>
            <div className="d-flex align-items-center gap-3">
              <ThemeProvider theme={themeBtnPlus}>
                <Button
                  size="small"
                  variant="contained"
                  sx={{
                    borderRadius: "50%",
                    // padding: "10px 0px",
                    width: "10px",
                    height: "55px",
                  }}
                  onClick={() => handleClickNewDetail()}
                >
                  <AddIcon
                    sx={{ width: 35, height: 35, padding: 0 }}
                    className=""
                  />
                </Button>
              </ThemeProvider>
              <Typography
                className="fw-bold"
                onClick={() => handleClickNewDetail()}
              >
                Añadir precio
              </Typography>
            </div>
          </div>
        </div>
        <div className="d-flex gap-3 " style={{ flexDirection: "row" }}>
          <div className="flex-wrap " style={{ flexGrow: 3 }}>
            <div className="">
              <TransitionGroup className="grid-cont">
                {form.photos.map((el, index) => (
                  <Grow key={index} in={true} className="grid-item">
                    <div className="" key={index}>
                      <div className="grid-item-close">
                        <IconButton sx={{}} onClick={() => deleteImg(index)}>
                          <Typography
                            className="fw-bolder"
                            variant="h4"
                            sx={{ lineHeight: 0.5, color: "#fff" }}
                          >
                            ×
                          </Typography>
                        </IconButton>
                      </div>
                      <img src={el.imgUrl} alt={`imagen${index}`} />
                    </div>
                  </Grow>
                ))}
              </TransitionGroup>
            </div>
          </div>
          <div
            className="d-flex align-items-start justify-content-evenly gap-3 "
            style={{ flexGrow: 0 }}
          >
            <ThemeProvider theme={themeBtnDft}>
              <Button
                variant="contained"
                component="label"
                // onClick={(e: any) => handleImgUpload(e)}
              >
                <Typography>Subir Imágenes</Typography>
                <input
                  type="file"
                  hidden
                  multiple
                  accept=".jpeg, .jpg, .png"
                  onChange={(e) => handleImgUpload(e)}
                />
              </Button>
            </ThemeProvider>
            <ThemeProvider theme={themeBtnDftActive}>
              <Button variant="contained" onClick={() => handleClickSave()}>
                <Typography>Guardar Paquete</Typography>
              </Button>
            </ThemeProvider>
          </div>
        </div>
      </form>
      <MapModal
        form={form}
        onChange={onChange}
        updateForm={updateForm}
        open={openModal}
        onClose={() => setOpenModal(false)}
        route={ routeToEdit}
      />
    </BodyLayout>
  );
};

export default NewPackage;

const getAccordionRoute = (
  title: string = "",
  description: string = "",
  descriptionEn: string = "",
  deleteFunc: () => void = () => {},
  editFunc: () => void = () => {}
) => {
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div
          className="d-flex w-100 justify-content-between"
          style={{ paddingRight: "1rem" }}
        >
          <Typography>
            {title.length === 0 ? "Nombre del lugar" : title}
          </Typography>
          <div className='d-flex gap-2'>
          <IconButton edge="end" aria-label="delete" onClick={deleteFunc}>
            <DeleteIcon />
          </IconButton>
          <IconButton edge="end" aria-label="edit" onClick={editFunc}>
            <EditIcon />
          </IconButton>
         </div>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Typography>Descripción</Typography>
        <Typography>
          {description.length === 0
            ? "Descripción de este punto de la ruta"
            : description}
        </Typography>
        <br/>
        <Typography>Traducción</Typography>
        <Typography>
          {descriptionEn.length === 0
            ? "Description of this route point"
            : descriptionEn}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

const getDetailPackageRow = (
  onChange: any,
  form: DetailPackage = initDetailPackage,
  funcSave: () => void = () => {},
  funcDelete: () => void = () => {},
  index: number = -1,
  setIndexRow: () => void = () => {},
  prevRow: DetailPackage | undefined = undefined
): JSX.Element => {
  const handleDelete = () => {
    funcDelete();
  };

  const handleSave = () => {
    funcSave();
  };

  const handleChange = (e: any) => {
    onChange(e, index);
  };

  return (
    <div className="d-flex gap-4 flex-grow-1 mb-3" key={index}>
      <div className="flex-grow-1">
        <TextField
          hiddenLabel
          // defaultValue={form.minPassenger === 0 ? "" : form.minPassenger}
          value={form.minPassenger === 0 ? "" : form.minPassenger}
          name="minPassenger"
          variant="filled"
          placeholder="No. mínimo de pasajeros"
          fullWidth
          onChange={(e) => handleChange(e)}
          // label='El No. mínimo no puede ser mayor que el máximo de pasajeros'
          // onFocus={setIndexRow}
          error={
            prevRow && parseInt(form.minPassenger.toString()) > 0
              ? parseInt(form.minPassenger.toString()) <=
                parseInt(prevRow.maxPassenger.toString())
                ? true
                : false
              : false
          }
        />
      </div>
      <div className="flex-grow-1">
        <TextField
          hiddenLabel
          // defaultValue={form.maxPassenger === 0 ? "" : form.maxPassenger}
          value={form.maxPassenger === 0 ? "" : form.maxPassenger}
          name="maxPassenger"
          variant="filled"
          placeholder="No. máximo de pasajeros"
          fullWidth
          onChange={(e) => handleChange(e)}
          error={
            parseInt(form.maxPassenger.toString()) > 0
              ? parseInt(form.maxPassenger.toString()) <
                parseInt(form.minPassenger.toString())
                ? true
                : false
              : false
          }
        />
      </div>
      <div className="flex-grow-1">
        <TextField
          hiddenLabel
          // defaultValue={form.price === 0 ? "" : form.price}
          value={form.price === 0 ? "" : form.price}
          name="price"
          variant="filled"
          placeholder="Escribe el precio"
          fullWidth
          onChange={(e) => handleChange(e)}
          // onFocus={setIndexRow}
        />
      </div>
      <div className="d-flex gap-3 justify-content-between">
        {/* <IconButton edge="end" color="primary" onClick={handleSave}>
          <SaveIcon />
        </IconButton> */}
        <IconButton edge="end" /*color="error"*/ onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      </div>
    </div>
  );
};
